import React, { useEffect, useState } from "react";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Checkbox,
    Menu,
    MenuItem,
} from "@mui/material";
import CustomDateDropdown from "../../../../../stylized/CustomDateDropdown";
import CustomSearchDropdown from "../../../../../stylized/CustomDropDown/index";
import { makeStyles } from "@material-ui/core/styles";
import { saveAs } from "file-saver";
import { getParsedDateByDateAndTime } from "../../../../../../bae-client/Utils";
import "../../../../UnityCentral/ContentPane/Alerts/style.css";
import "./WorkSpaceFilterTable.css";
import { useUsersQuery } from "../../../../../../services/services/usersAPI";
import { ButtonComponent } from "../../../../../button/button";
import { fetchWorkspaceListData } from "../../../../../../actions/WokspaceActions";
import { fetchFolderListData } from "../../../../../../actions/FolderActions";
import http from "../../../../../../bae-client/HTTP";
import WorkspaceModal from "../workspace-modal";
import {
    setActivePageAction,
    setSnackbarAction,
} from "../../../../../../services/services/app";
import { useDispatch, useSelector } from "react-redux";
import * as Mixpanel from "../../../../../../mix-panel/Mixpanel";
import ShareFile from "../../WorkspaceContainer/AddFolderModal/ShareFile";
import { fetchUserListData } from "../../../LeftPane/Directory/DirectoryActions";
import Move from "../../communicator/chatSection/chatHistory/shareOptions/Move";
import { setShowSettingWs } from "../../../../../../actions/Rippleaction";
import Loader from "../../../../../stylized/Loader";
const WorkSpaceFilterTable = ({
    handleMarkAsReadOrUnRead,
    handleClickResult,
    renderDocumentDisplayModal,
    listRowData,
    handleChangeRowsPerPage,
    handleChangePage,
    setListRowData,
    setShowDropdown,
    rowsPerPage,
    page,
    handleActivityClick,
    setCurrentWorkspaceSearch,
    debouncedSearch,
    tableHeight
}) => {
    const dispatch = useDispatch();
    const [modalTitle, setModalTitle] = useState("");
    const [modalType, setModalType] = useState("");
    const [existingName, setExistingName] = useState("");
    const [existingMatcher, setExistingMatcher] = useState([]);
    const [actionButtonName, setActionButtonName] = useState("Save");
    const [content, setContent] = useState("");
    const useStyles = makeStyles({
        root: {
            borderRadius: "4px !important",
            display: "inline-flex",
            flexDirection: "column",
            position: "relative",
            minWidth: "0px",
            padding: "0px",
            margin: "8px",
            border: "0px",
            verticalAlign: "top",
            width: "159px",
        },

        dateRoot: {
            width: "159px",
        },
    });
    const { data: users } = useUsersQuery();
    const [selectedIds, setSelectedIds] = React.useState([]);
    const classes = useStyles();
    const [selectedRows, setSelectedRows] = useState([]);
    const [dateErr, setDateErr] = React.useState(false);
    const [selectedTimeState, setSelectedTimeState] = React.useState({});
    const [open, setOpen] = useState(false);
    const [shareFile, setShareFile] = useState(false);
    const [move, setMove] = useState(false);
    const directories = useSelector((state) => state.directories);
    const [showLoader, setShowLoader] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);

    const [alertDialogShow, setAlertDialogShow] = useState(false);
    const [currentShowFileName, setCurrentShowFileName] = useState("");
    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const [classNameObjectName, setClassNameObjectName] =
        React.useState("headerSortDown");
    const [classNameCreatedOn, setClassNameCreatedOn] =
        React.useState("headerSortDown");
    const [classNameUpdatedOn, setClassNameUpdatedOn] =
        React.useState("headerSortDown");
    const [classNameIsShared, setClassNameIsShared] =
        React.useState("headerSortDown");
    const [classNameShareBy, setClassNameShareBy] =
        React.useState("headerSortDown");
    const [classNameIsStarred, setClassNameIsStarred] =
        React.useState("headerSortDown");
    const [orderBy, setOrderBy] = React.useState("asc");
    const orderByColumn = React.useRef("");

    const isSelected = (index) => selectedIds.indexOf(index) !== -1;
    const columns = [
        { id: "objectName", label: "Name", minWidth: "129px" },
        { id: "createdOn", label: "Date Created", minWidth: "50px" },
        {
            id: "updatedOn",
            label: "Date Last Modified",
            minWidth: "40px",
        },

        { id: "isShared", label: "Shared", minWidth: "30px" },
        { id: "shareBy", label: "Owner", minWidth: "20px" },
        { id: "isStarred", label: "Starred", minWidth: "20px" },
    ];
    useEffect(() => {
        if (directories && directories.length && shareFile) {
            dispatch(
                fetchUserListData(
                    directories[directories.length - 1].directoryId
                )
            );
        }
    }, [shareFile]);
    const showAlertView = (title, body, messageId, buttonId, isError) => {
        setAlertDialogContent({
            title: title,
            message: body,
            messageId: messageId,
            buttonId: buttonId,
        });
        isError ? setAlertDialogShow(true) : setShowSnackbar(true);
    };

    const addStarFill = (id, star, type) => {
        let url = type === "FOLDER" ? "folders/starred" : "space/starred";
        let data =
            type === "FOLDER"
                ? { folderRegistryId: parseInt(id), isStarred: star }
                : { spaceId: id, isStarred: star };
        setShowLoader(true);
        http.sendRestRequest(url, "PUT", data)
            .then((resp) => {
                setShowLoader(false);
                handleClickResult(page, rowsPerPage);
            })
            .catch((err) => {
                if (type === "SPACE") {
                    showAlertView(
                        "Operation Failed",
                        err?.error || "Something went wrong, please try again!",
                        "alert_folder",
                        "btn_folder_close",
                        true
                    );
                }
                setShowLoader(false);
            });
    };

    const fileTypeList = [
        {
            text: "PDF",
            value: "PDF",
            id: "PDF",
        },
        {
            text: "Word",
            value: "Word",
            id: "Word",
        },
        {
            text: "Excel",
            value: "Excel",
            id: "Excel",
        },
        {
            text: "Text File",
            value: "Text File",
            id: "Text File",
        },
        {
            text: "HTML",
            value: "HTML",
            id: "HTML",
        },
        {
            text: "CSV",
            value: "CSV",
            id: "CSV",
        },
    ];
    const getOwnerName = (data, sharedBy) => {
        if (data && data.length) {
            const sharedByUser = data.find((user) => user.userId === sharedBy);
            if (sharedByUser) {
                return sharedByUser?.firstName + " " + sharedByUser?.lastName;
            }
            return "";
        }
    };
    const clearStates = () => {
        setModalTitle("");
        setModalType("");
        setExistingMatcher([]);
        setExistingName("");
        setShowLoader(false);
        setActionButtonName("Save");
        setContent("");
    };
    const handleDownloadClick = () => {
        selectedRows.forEach((item) => {
            if (!item.clickActionParameters) {
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "Folder cannot be downloaded!",
                        severity: "error",
                    })
                );
                return;
            }
            const hasallowDownload = selectedRows.some(
                (row) =>
                    row.hasOwnProperty("allowDownload") && !row?.allowDownload
            );
            if (hasallowDownload) {
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message:
                            "File cannot be downloaded as Some files are not downloadable",
                        severity: "error",
                    })
                );
                return;
            }
            setShowLoader(true);
            let documentId = item.clickActionParameters.split("/").pop();
            http.sendRestRequest(
                `document/documentDetail?documentId=${documentId}`
            )
                .then((response) => {
                    const baseUrl = window.location.origin;
                    setShowLoader(false);
                    // setCurrentShowFileName(response.documentName);
                    saveAs(
                        baseUrl +
                            "/" +
                            response.documentLocation +
                            "/" +
                            item.objectName,
                        item.objectName
                    );
                    let fileExt = item.objectName.split(".").pop();
                    Mixpanel.callFileManagementEvent("Download", fileExt);
                })
                .catch((error) => {
                    console.log(error);

                    setShowLoader(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message:
                                error?.error ||
                                "Something went wrong. Please try again",
                            severity: "error",
                        })
                    );
                });
        });
    };
    // const executeRequests = async (requests, actionType) => {
    //     setShowLoader(true);

    //     try {
    //         const results = await Promise.allSettled(requests); // Wait for all to complete

    //         // Check if at least one request succeeded
    //         const hasSuccess = results.some(
    //             (result) => result.status === "fulfilled"
    //         );

    //         if (hasSuccess) {
    //             clearStates();
    //             dispatch(
    //                 setSnackbarAction({
    //                     open: true,
    //                     message: `Item(s) ${actionType} successfully`,
    //                 })
    //             );
    //         } else {
    //             dispatch(
    //                 setSnackbarAction({
    //                     open: true,
    //                     message: "All requests failed. Please try again.",
    //                     severity: "error",
    //                 })
    //             );
    //         }
    //     } catch (error) {
    //         dispatch(
    //             setSnackbarAction({
    //                 open: true,
    //                 message: "Something went wrong. Please try again.",
    //                 severity: "error",
    //             })
    //         );
    //     } finally {
    //         handleClickResult(page, rowsPerPage);
    //         setSelectedIds([]);
    //         setSelectedRows([]);
    //         setShowLoader(false);
    //     }
    // };

    // const archiveItems = () => {
    //     const requests = selectedRows.map((item) => {
    //         let url = "";
    //         if (item.objectType === "SPACE") {
    //             let workspaceId = item.clickActionParameters.split("/")[0];
    //             url = `space/archive?workspaceId=${workspaceId}&spaceId=${item.objectId}`;
    //         } else if (item.objectType === "FOLDER") {
    //             url = `folders/archive?folderRegistryId=${item.objectId}`;
    //         } else if (item.objectType === "FILE") {
    //             url = `files/archive?spacePersistenceId=${item.objectId}`;
    //         } else if (item.objectType.toLowerCase() === "email") {
    //             url = `emailFiles/archive?emailFileId=${item.objectId}`;
    //         }
    //         return http.sendRestRequest(url, "PUT");
    //     });

    //     executeRequests(requests, "archived");
    // };

    // const deleteItems = () => {
    //     const requests = selectedRows.map((item) => {
    //         let url = "";
    //         if (item.objectType === "SPACE") {
    //             let workspaceId = item.clickActionParameters.split("/")[0];
    //             url = `space/space?workspaceId=${workspaceId}&spaceId=${item.objectId}`;
    //         } else if (item.objectType === "FOLDER") {
    //             url = `folders/folder?folderRegistryId=${item.objectId}`;
    //         } else if (item.objectType === "FILE") {
    //             url = `files/file?spacePersistenceId=${item.objectId}`;
    //         } else if (item.objectType.toLowerCase() === "email") {
    //             url = `emailFiles/delete?emailFileId=${item.objectId}`;
    //         }
    //         return http.sendRestRequest(url, "DELETE");
    //     });

    //     executeRequests(requests, "deleted");
    // };

    const archiveItems = () => {
        const selectedSpaces = selectedRows.filter(
            (item) => item.objectType === "SPACE"
        );
        const selectedFolders = selectedRows.filter(
            (item) => item.objectType === "FOLDER"
        );
        const selectedFiles = selectedRows.filter(
            (item) => item.objectType === "FILE"
        );
        const selectedEmails = selectedRows.filter(
            (item) => item.objectType.toLowerCase() === "email"
        );
        const requests = [];
        if (selectedSpaces.length) {
            selectedSpaces.forEach((item) => {
                let workspaceId = item.clickActionParameters.split("/")[0];
                const url = `space/archive?workspaceId=${workspaceId}&spaceId=${item.objectId}`;
                requests.push(http.sendRestRequest(url, "PUT"));
            });
        }

        if (selectedFolders.length) {
            selectedFolders.map((item) => {
                const url = "folders/archive";
                requests.push(
                    http.sendRestRequest(
                        `${url}?folderRegistryId=${item.objectId}`,
                        "PUT"
                    )
                );
            });
        }

        if (selectedFiles.length) {
            selectedFiles.map((item) => {
                const url = "files/archive";
                requests.push(
                    http.sendRestRequest(
                        `${url}?spacePersistenceId=${item.objectId}`, // Correct query parameter format
                        "PUT"
                    )
                );
            });
        }

        if (selectedEmails.length) {
            selectedEmails.forEach((item) => {
                const url = "emailFiles/archive";
                requests.push(
                    http.sendRestRequest(
                        `${url}?emailFileId=${item.objectId}`,
                        "PUT"
                    )
                );
            });
        }
        setShowLoader(true);
        Promise.all(requests)
            .then((resp) => {
                // let spaceId = activeSpace.id;
                // fetchFolderData(
                //     parent.id === spaceId ? { id: 0 } : parent,
                //     true
                // );
                clearStates();
                dispatch(
                    selectedRows.length === 1
                        ? setSnackbarAction({
                              open: true,
                              message: `${selectedRows.length} Item archived successfully`,
                          })
                        : setSnackbarAction({
                              open: true,
                              message: `${selectedRows.length} Items archived successfully`,
                          })
                );
                handleClickResult(page, rowsPerPage);
                setSelectedIds([]);
                setSelectedRows([]);
                setShowLoader(false);
            })
            .catch((err) => {
                console.log(err, "ss");
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message:
                            err && err.error
                                ? err.error
                                : "There is something wrong. Please try again",
                        severity: "error",
                    })
                );
                setShowLoader(false);
            });
    };
    const deleteItems = () => {
        const selectedSpaces = selectedRows.filter(
            (item) => item.objectType === "SPACE"
        );
        const deleteFolders = selectedRows.filter(
            (item) => item.objectType === "FOLDER"
        );
        const deleteFiles = selectedRows.filter(
            (item) => item.objectType === "FILE"
        );
        const deleteEmails = selectedRows.filter(
            (item) => item.objectType.toLowerCase() === "email"
        );
        setShowLoader(true);
        const requests = [];
        if (selectedSpaces) {
            selectedSpaces.forEach((item) => {
                let workspaceId = item.clickActionParameters.split("/")[0];
                requests.push(
                    http.sendRestRequest(
                        `space/space?workspaceId=${workspaceId}&spaceId=${item?.objectId}`,
                        "DELETE"
                    )
                );
            });
        }

        if (deleteFolders.length) {
            deleteFolders.map((item) => {
                const url = "folders/folder";
                requests.push(
                    http.sendRestRequest(
                        `${url}?folderRegistryId=${item.objectId}`,
                        "DELETE"
                    )
                );
            });
        }
        if (deleteFiles.length) {
            deleteFiles.map((item) => {
                const url = "files/file";
                requests.push(
                    http.sendRestRequest(
                        `${url}?spacePersistenceId=${item.objectId}`,
                        "DELETE"
                    )
                );
            });
        }
        if (deleteEmails.length) {
            deleteEmails.forEach((item) => {
                const url = "emailFiles/delete";
                requests.push(
                    http.sendRestRequest(
                        `${url}?emailFileId=${item.objectId}`,
                        "DELETE"
                    )
                );
            });
        }
        Promise.all(requests)
            .then((resp) => {
                clearStates();
                dispatch(
                    selectedRows.length === 1
                        ? setSnackbarAction({
                              open: true,
                              message: `${selectedRows.length} Item deleted successfully`,
                          })
                        : setSnackbarAction({
                              open: true,
                              message: `${selectedRows.length} Items deleted successfully`,
                          })
                );
                handleClickResult(page, rowsPerPage);
                setSelectedIds([]);
                setSelectedRows([]);
                setShowLoader(false);
            })
            .catch((err) => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message:
                            err && err.error
                                ? err.error
                                : "There is something wrong. Please try again",
                        severity: "error",
                    })
                );
                setShowLoader(false);
            });
    };
    const renameSpace = (row, updatedValue) => {
        let data = {
            spaceId: row.objectId,
            spaceName: updatedValue.trim(),
        };
        setShowLoader(true);
        let workspaceId = row.clickActionParameters.split("/")[0];

        http.sendRestRequest(
            `space/space?workspaceId=${workspaceId}`,
            "PUT",
            data
        )
            .then(() => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "Space renamed successfully",
                    })
                );
                handleClickResult(page, rowsPerPage);
                setSelectedRows([]);
                setSelectedIds([]);
                setShowLoader(false);
            })
            .catch((error) => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: error.error,
                        severity: "error",
                    })
                );
                setShowLoader(false);
            });
    };
    const renameFolder = (row, updatedValue) => {
        let data = {
            folderRegistryId: row.objectId,
            folderName: updatedValue.trim(),
        };
        setShowLoader(true);
        http.sendRestRequest("folders/folder", "PUT", data)
            .then(() => {
                handleClickResult(page, rowsPerPage);
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: "Folder renamed successfully",
                    })
                );
                setSelectedRows([]);
                setSelectedIds([]);
                setShowLoader(false);
            })
            .catch((error) => {
                clearStates();
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: error.error,
                        severity: "error",
                    })
                );
                setShowLoader(false);
            });
    };
    const handleModalButtonAction = (updatedValue) => {
        // const selectedRow = currentRows.filter((row) =>
        //     selectedRows.includes(row.id)
        // );
        const row = selectedRows[0];
        // if (modalType === "Folder" || modalType === "Item") {
        // if (modalTitle === "Rename Folder") {
        //     renameFolder(row, updatedValue);
        // } else if (modalTitle === "Add New Folder") {
        //     addNewFolder(updatedValue);
        // }
        // else
        if (modalTitle === "Rename Folder") {
            renameFolder(row, updatedValue);
        } else if (modalTitle === "Delete Item") {
            deleteItems();
        } else if (modalTitle === "Archive Item") {
            archiveItems();
        } else if (modalTitle === "Rename Space") {
            renameSpace(row, updatedValue);
        }
        //  else {
        //     if (modalTitle === "Rename Space") {
        //         renameSpace(row, updatedValue);
        //     } else if (modalTitle === "Add New Space") {
        //         addNewSpace(updatedValue);
        //     } else if (modalTitle === "Delete Space") {
        //         deleteSpace();
        //     } else if (modalTitle === "Archive Space") {
        //         archiveSpace();
        //     }
        // }
    };
    const handleDeleteClick = () => {
        // const row = { ...parent };
        // if (row.type === "workspace") {
        //     setModalTitle("Delete Space");
        //     setModalType("Space");
        //     setContent("Are you sure you want to delete selected space?");
        // } else {
        setModalTitle("Delete Item");
        setModalType("Item");
        setContent("Are you sure you want to delete selected item?");
        // }
        setActionButtonName("Delete");
        setOpen(true);
    };
    const handleMove = () => {
        setMove(true);
    };
    const handleShareClick = () => {
        if (selectedRows?.length > 1)
            dispatch(
                setSnackbarAction({
                    open: true,
                    message: "Multiple items cannot be shared at a time",
                    severity: "error",
                })
            );
        else {
            setShareFile(true);
        }
    };
    const handleRenameClick = () => {
        const row = selectedRows[0];
        if (row.objectType === "FOLDER") {
            setModalTitle("Rename Folder");
            setModalType("Folder");
            setShowLoader(true);
            http.sendRestRequest(
                `folders/folderDetails?folderId=${selectedRows[0].objectId}`
            )
                .then((resp) => {
                    const folderIdArray = resp.folderIdPath.split("/");
                    let parentFolderId = 0;
                    if (folderIdArray.length !== 1) {
                        parentFolderId =
                            folderIdArray[folderIdArray.length - 2];
                    }
                    let spaceId =
                        selectedRows[0].clickActionParameters.split("/")[1];
                    http.sendRestRequest(
                        `folders/folderData?spaceId=${spaceId}&folderId=${parentFolderId}`
                    )
                        .then((res) => {
                            setExistingMatcher(
                                res.map((folder) => folder.objectName)
                            );
                            setShowLoader(false);
                        })
                        .catch((err) => {
                            console.log(err);
                            setShowLoader(false);
                        });
                })
                .catch((err) => {
                    setShowLoader(false);
                    console.log(err);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message:
                                err && err.error
                                    ? err.error
                                    : `Folder Is not present in the given location!`,
                            severity: "error",
                        })
                    );
                    return;
                });
            setExistingName(row.objectName);
            setActionButtonName("Rename Folder");
        }
        if (row.objectType === "SPACE") {
            setShowLoader(true);
            setModalTitle("Rename Space");
            setModalType("Space");
            let workspaceId =
                selectedRows[0].clickActionParameters.split("/")[0];
            http.sendRestRequest(
                `workspace/spaceList?workspaceId=${workspaceId}`
            )
                .then((response) => {
                    setExistingMatcher(
                        response.map((space) => space.spaceName)
                    );
                    setShowLoader(false);
                })
                .catch((err) => {
                    console.log(err);
                    setShowLoader(false);
                });
            setExistingName(row.objectName);
            setActionButtonName("Rename Space");
        }
        setOpen(true);
    };
    const handleArchiveClick = () => {
        // if (selectedRows.objectType === "workspace") {
        //     setModalTitle("Archive Space");
        //     setModalType("Space");
        //     setContent("Are you sure you want to archive selected space?");
        // } else {
        setModalTitle("Archive Item");
        setModalType("Item");
        setContent("Are you sure you want to archive selected item?");
        // }
        setActionButtonName("Archive");
        setOpen(true);
    };
    const handleClick = (event, index, row) => {
        event.stopPropagation();
        const selectedIndex = selectedIds.indexOf(row.objectId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedIds, row.objectId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedIds.slice(1));
        } else if (selectedIndex === selectedIds.length - 1) {
            newSelected = newSelected.concat(selectedIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedIds.slice(0, selectedIndex),
                selectedIds.slice(selectedIndex + 1)
            );
        }
        setSelectedIds(newSelected);
        const filteredRows = listRowData.data.filter((row) =>
            newSelected.includes(row.objectId)
        );
        setSelectedRows(filteredRows);
    };
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = listRowData.data
                .filter(
                    (n) =>
                        n.objectType !== "WORKSPACE" &&
                        n.objectType !== "EMAIL_ATTACHMENT" &&
                        n.objectType !== "EMAIL"
                )
                .map((n) => n.objectId);
            setSelectedIds(newSelected);
            const filteredRows = listRowData.data.filter((row) =>
                newSelected.includes(row.objectId)
            );
            setSelectedRows(filteredRows);
            return;
        }
        setSelectedIds([]);
        setSelectedRows([]);
    };
    function sortAscending(a, b) {
        if (
            a[orderByColumn.current]?.toUpperCase() <
            b[orderByColumn.current]?.toUpperCase()
        ) {
            return -1;
        }
        if (
            a[orderByColumn.current]?.toUpperCase() >
            b[orderByColumn.current]?.toUpperCase()
        ) {
            return 1;
        }
        return 0;
    }
    function sortDescending(a, b) {
        if (
            b[orderByColumn.current]?.toUpperCase() <
            a[orderByColumn.current]?.toUpperCase()
        ) {
            return -1;
        }
        if (
            b[orderByColumn.current]?.toUpperCase() >
            a[orderByColumn.current]?.toUpperCase()
        ) {
            return 1;
        }
        return 0;
    }

    const sortData = (orderBy, columnName) => {
        switch (columnName) {
            case "Name":
                orderByColumn.current = "objectName";
                orderBy === "asc"
                    ? setClassNameObjectName("headerSortUp")
                    : setClassNameObjectName("headerSortDown");
                break;
            case "Date Created":
                orderByColumn.current = "createdOn";
                orderBy === "asc"
                    ? setClassNameCreatedOn("headerSortUp")
                    : setClassNameCreatedOn("headerSortDown");
                break;
            case "Date Last Modified":
                orderByColumn.current = "updatedOn";
                orderBy === "asc"
                    ? setClassNameUpdatedOn("headerSortUp")
                    : setClassNameUpdatedOn("headerSortDown");
                break;
        }

        let dataToSort = listRowData.data;
        let sortedData = dataToSort.sort(
            orderBy === "asc" ? sortAscending : sortDescending
        );

        setListRowData({ ...listRowData, data: [...sortedData] });
    };

    const handleSorting = (columnName) => {
        sortData(orderBy, columnName);
        orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc");
    };
    function getLastClickParameter(clickActionParameters) {
        return clickActionParameters
            ? clickActionParameters.split("/").pop()
            : null;
    }
    const getCellContent = (rowId, row) => {
        if (rowId === "isStarred") {
            return (
                <img
                    style={{
                        width: "20px",
                        height: "20px",
                        cursor:
                            row.objectType === "FILE" ||
                            row.objectType === "EMAIL_ATTACHMENT" ||
                            row.objectType === "EMAIL" ||
                            row.objectType === "WORKSPACE"
                                ? "not-allowed"
                                : "pointer",
                    }}
                    src={
                        row.isStarred
                            ? "./images/star.png"
                            : "./images/empstar.png"
                    }
                    alt=""
                    onClick={(event) => {
                        if (
                            row.objectType !== "FILE" &&
                            row.objectType !== "WORKSPACE" &&
                            row.objectType !== "EMAIL_ATTACHMENT" &&
                            row.objectType !== "EMAIL"
                        ) {
                            addStarFill(
                                row.objectId,
                                !row.isStarred,
                                row.objectType
                            );
                        }
                        event.stopPropagation();
                    }}
                />
            );
        } else if (rowId === "updatedOn" || rowId === "createdOn") {
            return (
                <div
                    className="cell_style textEllipsis"
                    style={{ maxWidth: "150px" }}
                >
                    {" "}
                    {getParsedDateByDateAndTime(row[rowId])}
                </div>
            );
        } else if (rowId === "objectName") {
            let imgsrc = "";
            if (row.objectType === "FILE") imgsrc = "./images/shareFile.png";
            else if (row.objectType === "FOLDER")
                imgsrc = "./images/FOLDER.svg";
            else if (row.objectType === "WORKSPACE")
                imgsrc = "./images/Workspace Icon.svg";
            else if (
                row.objectType === "EMAIL_ATTACHMENT" ||
                row.objectType === "EMAIL"
            )
                imgsrc = "./images/paperclip (1).svg";
            else imgsrc = "./images/spaceIcon.svg";
            return (
                <div
                    className="cell_style"
                    style={{
                        maxWidth: "330px",
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                    }}
                >
                    <div>
                        <img src={imgsrc} alt="" style={{ width: "30px" }} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                            className="cell_style textEllipsis"
                            title={row.objectName}
                            style={{
                                maxWidth: "170px",
                                cursor:
                                    row.objectType === "FILE" ||
                                    row.objectType === "EMAIL_ATTACHMENT" ||
                                    row.objectType === "EMAIL"
                                        ? "pointer"
                                        : "default",
                            }}
                            onClick={(event) => {
                                if (
                                    row.objectType === "FILE" ||
                                    row.objectType === "EMAIL_ATTACHMENT" ||
                                    row.objectType === "EMAIL"
                                ) {
                                    renderDocumentDisplayModal(
                                        getLastClickParameter(
                                            row.clickActionParameters
                                        )
                                    );
                                    setShowDropdown(false);
                                } else if (row.objectType === "WORKSPACE") {
                                    dispatch(setShowSettingWs(false));
                                    dispatch(setActivePageAction("Workspace"));
                                    setCurrentWorkspaceSearch({
                                        ...row,
                                        workspaceId: row.objectId,
                                        workspaceName: row.objectName,
                                    });
                                } else {
                                    handleActivityClick(row,true);
                                    dispatch(setShowSettingWs(false));
                                }
                            }}
                        >
                            {row.objectName}
                        </div>
                        <div
                            className="textEllipsis"
                            title={row.objectLocation.split("/").join(" > ")}
                            style={{
                                fontFamily: "Montserrat",
                                fontWeight: "bolder",
                                lineHeight: "12px",
                                maxWidth: "340px",
                                letterSpacing: "-0.02em",
                            }}
                        >
                            {row.objectLocation.split("/").join(" > ")}
                        </div>
                    </div>
                </div>
            );
        } else if (rowId === "isShared") {
            return (
                <div
                    className="cell_style textEllipsis"
                    style={{ maxWidth: "150px" }}
                >
                    {row.isShared === false ? "Private" : "Shared"}
                </div>
            );
        } else if (rowId === "shareBy") {
            return (
                <div
                    className="cell_style textEllipsis"
                    style={{ maxWidth: "150px" }}
                >
                    {getOwnerName(users, row.shareBy)}
                </div>
            );
        }

        return (
            <div
                className="cell_style textEllipsis"
                style={{ maxWidth: "150px" }}
            >
                {row[rowId]}
            </div>
        );
    };
    const checkFolder = () => {
        // if (currentRows.length === 0) {
        //     return false;
        // }
        const selectedRow = selectedRows[0];
        let isFolder =
            selectedRow.objectType === "FOLDER" ||
            selectedRow.objectType === "SPACE"
                ? true
                : false;
        return isFolder;
    };
    return (
        <div style={{ marginLeft: "17px" }}>
            <div
                style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: "20px",
                    lineHeight: "44px",
                    letterSpacing: "-0.022em",
                }}
            >
                Search Results for "{debouncedSearch}"
            </div>
            {selectedRows.length ? (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "15px",
                        margin: "5px 0px",
                    }}
                >
                    {selectedRows.length === 1 && checkFolder() && (
                        // checkType !== "file" &&
                        // !isEmail &&
                        <ButtonComponent
                            type={"secondary"}
                            icon={"./images/Rename.svg"}
                            text={"Rename"}
                            BtnHandler={handleRenameClick}
                            // disable={disableOperations}
                            id="div_table_rename"
                        />
                    )}
                    <ButtonComponent
                        type={"secondary"}
                        icon={"./images/Archive (1).svg"}
                        text={"Archive"}
                        id="div_table_archive"
                        BtnHandler={handleArchiveClick}
                        // disable={disableOperations}
                    />
                    <ButtonComponent
                        type={"secondary"}
                        icon={"./images/trash 2.svg"}
                        text={"Delete"}
                        id="div_table_trash"
                        BtnHandler={handleDeleteClick}
                        // disable={disableOperations}
                    />
                    {selectedRows.length > 0 &&
                        !selectedRows.some((row) =>
                            ["WORKSPACE", "SPACE"].includes(row.objectType)
                        ) && (
                            <>
                                <ButtonComponent
                                    type={"secondary"}
                                    icon={"./images/share 2.svg"}
                                    text={"Share"}
                                    id="div_table_share"
                                    BtnHandler={handleShareClick}
                                    // disable={disableOperations}
                                />
                                <ButtonComponent
                                    type={"secondary"}
                                    icon={
                                        "./images/fileTypeIcon/arrows-move 1.svg"
                                    }
                                    text={"Move"}
                                    id="div_table_move"
                                    BtnHandler={handleMove}
                                    // disable={disableOperations}
                                />
                            </>
                        )}

                    {selectedRows.length > 0 &&
                        selectedRows.every(
                            (row) => row.objectType === "FILE"
                        ) && (
                            <ButtonComponent
                                type={"secondary"}
                                icon={"./images/fileTypeIcon/download 1.svg"}
                                text={"Download"}
                                id="div_table_download"
                                BtnHandler={handleDownloadClick}
                                // disable={disableDownloads}
                            />
                        )}
                </div>
            ) : (
                // <>
                //     <div className="workspace-actions">
                //         {!isSelection && !isSpace && !isFolder ? (
                //             <ButtonComponent
                //                 type={"outlined"}
                //                 icon={"./images/plus.svg"}
                //                 text={"Add Space"}
                //                 id="btn_space_addspace"
                //                 BtnHandler={handleAddSpaceOrFolderClick}
                //                 disable={disableOperations}
                //             />
                //         ) : (
                //             !isSelection &&
                //             (isSpace || isFolder) &&
                //             parent?.type?.toLowerCase() !== "email" && (
                //                 <>
                //                     <ButtonComponent
                //                         type={"secondary"}
                //                         icon={"./images/Add Folder.svg"}
                //                         text={"Add Folder"}
                //                         id="btn_space_addfolder"
                //                         BtnHandler={handleAddSpaceOrFolderClick}
                //                         disable={disableOperations}
                //                     />
                //                     <ButtonComponent
                //                         type={"secondary"}
                //                         icon={"./images/Upload File.svg"}
                //                         text={"Upload File"}
                //                         BtnHandler={() =>
                //                             tableFileUplode.current.click()
                //                         }
                //                         id="btn_space_addfile"
                //                         disable={disableOperations}
                //                     />
                //                     <input
                //                         type="file"
                //                         id="file"
                //                         ref={tableFileUplode}
                //                         hidden={true}
                //                         onChange={onChangeFileUpload}
                //                         onClick={(event) => {
                //                             event.currentTarget.value = null;
                //                         }}
                //                         multiple
                //                     />
                //                 </>
                //             )
                //         )}

                //         {!isSelection &&
                //             (isSpace || isFolder) &&
                //             parent?.type?.toLowerCase() !== "email" && (
                //                 <ButtonComponent
                //                     type={"secondary"}
                //                     icon={"./images/Add Folder.svg"}
                //                     text={"New Note"}
                //                     BtnHandler={handleNewNoteClick}
                //                     disable={disableOperations}
                //                 />
                //             )}

                //         {isSelection &&
                //             parent?.type?.toLowerCase() !== "email" && (
                //                 <>
                //                     {selectedRows.length === 1 &&
                //                         checkFolder() &&
                //                         // checkType !== "file" &&
                //                         !isEmail && (
                //                             <ButtonComponent
                //                                 type={"secondary"}
                //                                 icon={"./images/Rename.svg"}
                //                                 text={"Rename"}
                //                                 BtnHandler={handleRenameClick}
                //                                 disable={disableOperations}
                //                                 id="div_table_rename"
                //                             />
                //                         )}
                //                     <ButtonComponent
                //                         type={"secondary"}
                //                         icon={"./images/Archive (1).svg"}
                //                         text={"Archive"}
                //                         id="div_table_archive"
                //                         BtnHandler={handleArchiveClick}
                //                         disable={disableOperations}
                //                     />
                //                     <ButtonComponent
                //                         type={"secondary"}
                //                         icon={"./images/trash 2.svg"}
                //                         text={"Delete"}
                //                         id="div_table_trash"
                //                         BtnHandler={handleDeleteClick}
                //                         disable={disableOperations}
                //                     />
                //                     {isEmail === true ? (
                //                         <ButtonComponent
                //                             type={"secondary"}
                //                             icon={
                //                                 "./images/fileTypeIcon/arrows-move 1.svg"
                //                             }
                //                             text={"Move"}
                //                             id="div_table_move"
                //                             BtnHandler={handleMove} // Correct: Passing function reference
                //                             disable={disableOperations}
                //                         />
                //                     ) : null}

                //                     {(isSpace || isFolder) && !isEmail && (
                //                         <>
                //                             <ButtonComponent
                //                                 type={"secondary"}
                //                                 icon={"./images/share 2.svg"}
                //                                 text={"Share"}
                //                                 id="div_table_share"
                //                                 BtnHandler={handleShareClick}
                //                                 disable={disableOperations}
                //                             />
                //                             <ButtonComponent
                //                                 type={"secondary"}
                //                                 icon={
                //                                     "./images/fileTypeIcon/arrows-move 1.svg"
                //                                 }
                //                                 text={"Move"}
                //                                 id="div_table_move"
                //                                 BtnHandler={handleMove} // Correct: Passing function reference
                //                                 disable={disableOperations}
                //                             />

                //                             <ButtonComponent
                //                                 type={"secondary"}
                //                                 icon={
                //                                     "./images/fileTypeIcon/download 1.svg"
                //                                 }
                //                                 text={"Download"}
                //                                 id="div_table_download"
                //                                 BtnHandler={handleDownloadClick}
                //                                 disable={disableDownloads}
                //                             />
                //                         </>
                //                     )}
                //                 </>
                //             )}
                //     </div>

                //     {open && (
                //         <WorkspaceModal
                //             open={open}
                //             setOpen={setOpen}
                //             textId={
                //                 modalTitle === "Rename Folder"
                //                     ? "updateFolderName"
                //                     : modalTitle === "Add New Folder"
                //                     ? "newFolderName"
                //                     : modalTitle === "Rename Folder" ||
                //                       modalTitle === "Rename Space"
                //                     ? "updateSpacename"
                //                     : modalTitle === "Add New Space"
                //                     ? "spacename"
                //                     : ""
                //             }
                //             title={modalTitle}
                //             type={modalType}
                //             existingName={existingName}
                //             existingMatcher={existingMatcher}
                //             buttonAction={handleModalButtonAction}
                //             actionButtonName={actionButtonName}
                //             content={content}
                //             setContent={setContent}
                //             id={
                //                 modalTitle === "Delete Item"
                //                     ? "button_modal_delete"
                //                     : modalTitle === "Archive Item" ||
                //                       modalTitle === "Archive Space"
                //                     ? "button_modal_archive"
                //                     : modalTitle === "Rename Folder" ||
                //                       modalTitle === "Rename Space"
                //                     ? "Change"
                //                     : modalTitle === "Add New Folder"
                //                     ? "Add_Folder"
                //                     : modalTitle === "Delete Space"
                //                     ? "button_modal_delete"
                //                     : modalTitle === "Add New Space"
                //                     ? "Add_Space"
                //                     : ""
                //             }
                //         />
                //     )}
                //     {shareFile && (
                //         <ShareFile
                //             showShareModal={shareFile}
                //             closeModal={() => setShareFile(false)}
                //             space={activeSpace}
                //             activeWorkspace={activeWorkspace}
                //             parentFolderId={parent.id}
                //             lastSelectedRowData={currentRows.find(
                //                 (r) => r.id == selectedRows[0]
                //             )}
                //             folderData={parent}
                //         />
                //     )}
                //     {move && (
                //         <Move
                //             activeWorkspace={activeWorkspace}
                //             folderData={currentRows}
                //             itemSpace={activeSpace}
                //             isFileOrFolderSelected={true}
                //             selectedRowData={currentRows.filter((r) =>
                //                 selectedRows.includes(r.id)
                //             )}
                //             setMove={setMove}
                //             moveValue={move}
                //             parentFolderId={
                //                 parent.id === activeSpace.id ? 0 : parent.id
                //             }
                //             enable={true}
                //             disable={disableOperations}
                //             fetchFolderListData={fetchFolderData}
                //             parent={parent}
                //         />
                //     )}
                //     <Loader show={showLoader} />
                //     <AlertDialog
                //         open={showAlertDialog}
                //         setOpen={setShowAlertDialog}
                //         message={alertDialogMessage}
                //         title={""}
                //         isAlert={false}
                //     ></AlertDialog>
                // </>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "-9px",
                    }}
                >
                    <CustomSearchDropdown
                        items={fileTypeList}
                        label={"File Type"}
                        isChipView={false}
                        handleSelection={() => {}}
                        selection={[]}
                        customClassName={classes.root}
                        customWidth={92}
                    ></CustomSearchDropdown>
                    <CustomDateDropdown
                        setDateErr={setDateErr}
                        dateErr={dateErr}
                        setSelectedTimeFilter={() => {}}
                        selection={selectedTimeState}
                        // customClassName={"date_range_class"}
                        customClassName={classes.dateRoot}
                        label={"Date Range"}
                        customWidth={92}
                    ></CustomDateDropdown>
                </div>
            )}
            <Paper
                style={{ overflowX: "auto", width: "100%", marginLeft: "0px",height:tableHeight }}
                id="alert_tab"
            >
                <TableContainer id="table_alert" className="invisibleScroller">
                    {listRowData.data && listRowData.data.length ? (
                        <Table
                            stickyHeader
                            aria-label="sticky table"
                            style={{ width: "100%" }}
                        >
                            <TableHead
                                sx={{
                                    position: "sticky",
                                    top: "-1px",
                                    backgroundColor: "#EAEEF5",
                                    zIndex: "1",
                                }}
                            >
                                <TableRow>
                                    <TableCell
                                        padding="checkbox"
                                        style={{ background: "none" }}
                                    >
                                        <Checkbox
                                            color="primary"
                                            indeterminate={
                                                selectedIds.length > 0 &&
                                                selectedIds.length <
                                                    listRowData.data.length
                                            }
                                            checked={
                                                listRowData.data.length > 0 &&
                                                selectedIds.length ===
                                                    listRowData.data.length
                                            }
                                            onChange={handleSelectAllClick}
                                            inputProps={{
                                                "aria-label":
                                                    "select all alerts",
                                            }}
                                        />
                                    </TableCell>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align="left"
                                            className={
                                                {
                                                    Name: classNameObjectName,
                                                    "Date Created":
                                                        classNameCreatedOn,
                                                    "Date Last Modified":
                                                        classNameUpdatedOn,
                                                    Shared: classNameIsShared,
                                                    Owner: classNameShareBy,
                                                    Starred: classNameIsStarred,
                                                }[column.label]
                                            }
                                            style={{
                                                background: "none",
                                                minWidth: column.minWidth,
                                                cursor: "pointer",
                                                fontWeight: "900",
                                                verticalAlign: "baseline",
                                            }}
                                            title={column.label}
                                            onClick={() =>
                                                handleSorting(column.label)
                                            }
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {listRowData.data
                                    // .slice(
                                    //     page * rowsPerPage,
                                    //     page * rowsPerPage + rowsPerPage
                                    // )
                                    .map((row, rid) => {
                                        const isItemSelected = isSelected(
                                            row.objectId
                                        );
                                        const labelId = `enhanced-table-checkbox-${rid}`;
                                        return (
                                            <TableRow
                                                hover
                                                sx={{ height: "56px" }}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                // style={}
                                                onClick={() => {}}
                                                key={row.index}
                                                selected={isItemSelected}
                                            >
                                                <TableCell
                                                    padding="checkbox"
                                                    onClick={(event) => {
                                                        if (
                                                            row.objectType !==
                                                                "WORKSPACE" &&
                                                            row.objectType !==
                                                                "EMAIL_ATTACHMENT" &&
                                                            row.objectType !==
                                                                "EMAIL"
                                                        ) {
                                                            event.stopPropagation();
                                                            handleClick(
                                                                event,
                                                                row.index,
                                                                row
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <Checkbox
                                                        disabled={
                                                            row.objectType ===
                                                                "WORKSPACE" ||
                                                            row.objectType ===
                                                                "EMAIL_ATTACHMENT" ||
                                                            row.objectType ===
                                                                "EMAIL"
                                                        }
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            "aria-labelledby":
                                                                labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align="left"
                                                    >
                                                        {getCellContent(
                                                            column.id,
                                                            row
                                                        )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    ) : (
                        <div style={{ textAlign: "center", padding: "12%" }}>
                            No Results Found.
                        </div>
                    )}
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 20, 30]}
                    component="div"
                    count={
                        listRowData?.totalRecords
                            ? listRowData?.totalRecords
                            : 0
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
            {open && (
                <WorkspaceModal
                    open={open}
                    setOpen={setOpen}
                    textId={
                        modalTitle === "Rename Folder"
                            ? "updateFolderName"
                            : modalTitle === "Add New Folder"
                            ? "newFolderName"
                            : modalTitle === "Rename Folder" ||
                              modalTitle === "Rename Space"
                            ? "updateSpacename"
                            : modalTitle === "Add New Space"
                            ? "spacename"
                            : ""
                    }
                    title={modalTitle}
                    type={modalType}
                    existingName={existingName}
                    existingMatcher={existingMatcher}
                    buttonAction={handleModalButtonAction}
                    actionButtonName={actionButtonName}
                    content={content}
                    setContent={setContent}
                    id={
                        modalTitle === "Delete Item"
                            ? "button_modal_delete"
                            : modalTitle === "Archive Item" ||
                              modalTitle === "Archive Space"
                            ? "button_modal_archive"
                            : modalTitle === "Rename Folder" ||
                              modalTitle === "Rename Space"
                            ? "Change"
                            : modalTitle === "Add New Folder"
                            ? "Add_Folder"
                            : modalTitle === "Delete Space"
                            ? "button_modal_delete"
                            : modalTitle === "Add New Space"
                            ? "Add_Space"
                            : ""
                    }
                />
            )}
            {shareFile && (
                <ShareFile
                    showShareModal={shareFile}
                    closeModal={() => setShareFile(false)}
                    lastSelectedRowData={{
                        ...selectedRows[0],
                        isEditable: selectedRows[0]?.isEditable,
                        id: selectedRows[0]?.objectId,
                        name: selectedRows[0]?.objectName,
                        type: selectedRows[0]?.objectType,
                        documentId:
                            selectedRows[0]?.objectType === "FILE"
                                ? selectedRows[0]?.clickActionParameters
                                : 0,
                    }}
                    // folderData={parent}
                />
            )}
            {move && (
                <Move
                    // activeWorkspace={activeWorkspace}
                    // folderData={currentRows}
                    // itemSpace={activeSpace}
                    isFileOrFolderSelected={true}
                    selectedRowData={selectedRows}
                    setMove={setMove}
                    moveValue={move}
                    handleClickResult={handleClickResult}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    // parentFolderId={
                    //     parent.id === activeSpace.id ? 0 : parent.id
                    // }
                    workSpaceFilterTable={true}
                    enable={true}
                    // disable={disableOperations}
                    // fetchFolderListData={fetchFolderData}
                    // parent={parent}
                />
            )}
            <Loader show={showLoader} />
        </div>
    );
};
export default WorkSpaceFilterTable;
