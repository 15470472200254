import React, { useState, useEffect } from "react";
import Billing from "./Billing";
import ForgotPassword from "./ForgotPassword";
import Plans from "./Plans";
import SignUp from "./SignUp";
import DashBoard from "./DashBoard";
import Header from "./Header";
import Footer from "./Footer";
import InviteView from "./InviteView";
import UpdatePassword from "./UpdatePassword";
import Login from "../common/Login";
import { removeUserSession } from "../common/Login/ActiveUser";
import Chat from "../common/Chat";
import http from "../../../bae-client/HTTP";
import Agreement from "../common/policy-agreement";
import CustomizedSnackbar from "../../stylized/snackbar";
import DashBoardContainer from "./dashboard/index";

const getCurrentPage = () => {
    if (window.location.pathname === "/forgotPassword") return "forgotPassword";
    if (window.location.pathname === "/signUp") return "signUp";
};

const App = () => {
    const params = new URLSearchParams(window.location.search);
    const [pageType, setPageType] = useState(params.get("action"));
    const [showDashboard, setShowDashboard] = useState(false);
    const [currentPage, setCurrentPage] = useState("");
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [agreementStatus, setAgreementStatus] = useState(false);
    const [userAgreements, setUserAgreements] = useState([]);
    const [userEmail, setUserEmail] = useState("");

    useEffect(() => {
        if (pageType === "new") setShowDashboard(true);
    }, []);
    useEffect(() => {
        if (
            (userLoggedIn || sessionStorage.getItem("user")) &&
            !agreementStatus
        ) {
            http.sendRestRequest(
                `userLicenseAgreement/listByStatus?agreementStatus=PENDING`
            )
                .then((response) => {
                    const pendingStatus = response.filter(
                        (agr) => agr.agreementStatus === "PENDING"
                    );
                    if (!pendingStatus.length) setAgreementStatus(true);
                    setUserAgreements(pendingStatus);
                })
                .catch((error) => console.log(error));
        }
    }, [userLoggedIn, agreementStatus]);

    const setUser = (user) => {
        setUserLoggedIn(user);
        if (!user) setPageType("");
    };
    const changeCurrentPage = (currentPage) => {
        setCurrentPage(currentPage);
    };
    const changeUserEmail = (mail) => {
        setUserEmail(mail);
        setPageType("updatePassword");
        setCurrentPage("updatePassword");
    };
    const handlePageChange = () => {
        setPageType("");
        setCurrentPage("");
    };
    const importContent = () => {
        if (currentPage === "forgotPassword") {
            return (
                <ForgotPassword
                    changeUserEmail={changeUserEmail}
                ></ForgotPassword>
            );
        } else if (pageType === "updatePassword") {
            return <UpdatePassword userEmail={userEmail}></UpdatePassword>;
            // } else if (
            //     this.state.currentPage === "signUp" ||
            //     this.pageType === "plans"
            // ) {
            //     return <Plans></Plans>;
        } else if (currentPage === "signUp" || pageType === "signUp") {
            return <SignUp></SignUp>;
        } else if (pageType === "billing") {
            return <Billing></Billing>;
        } else if (pageType === "redirectInviteUsers") {
            return (
                <>
                    {userAgreements.length ? (
                        <Agreement
                            agreements={userAgreements}
                            setAgreementStatus={setAgreementStatus}
                            setUserAgreements={setUserAgreements}
                            setUserLoggedIn={setUserLoggedIn}
                        ></Agreement>
                    ) : (
                        <InviteView hideDashboard={hideDashboard}></InviteView>
                    )}
                </>
            );
        }
    };
    const hideDashboard = () => {
        setShowDashboard(false);
        setPageType("");
        window.location.href =
            window.location.origin + window.location.pathname;
    };

    if (currentPage || pageType) {
        if (
            (pageType === "redirectInviteUsers" && userLoggedIn) ||
            currentPage ||
            pageType === "plans" ||
            pageType === "signUp" ||
            pageType === "updatePassword"
        )
            return (
                <div className="container-fluid">
                    <Header changePage={handlePageChange}></Header>
                    {importContent()}
                    <Footer></Footer>
                </div>
            );
        else
            return (
                <div className="h-100 doCenter">
                    <Login
                        setUser={setUser}
                        changeCurrentPage={changeCurrentPage}
                    ></Login>
                </div>
            );
    } else {
        if (userLoggedIn || sessionStorage.getItem("user")) {
            if (agreementStatus)
                return (
                    <>
                        {/* <DashBoard setUser={setUser}></DashBoard> */}
                        <DashBoardContainer setUser={setUser} />
                        <Chat></Chat>
                        <CustomizedSnackbar />
                    </>
                );
            else
                return (
                    <>
                        {userAgreements.length ? (
                            <Agreement
                                agreements={userAgreements}
                                setAgreementStatus={setAgreementStatus}
                                setUserAgreements={setUserAgreements}
                                setUserLoggedIn={setUserLoggedIn}
                            ></Agreement>
                        ) : null}
                    </>
                );
        } else
            return (
                <div className="h-100 doCenter">
                    <Login
                        setUser={setUser}
                        changeCurrentPage={changeCurrentPage}
                    ></Login>
                </div>
            );
    }
};

export default App;
