import * as React from "react";
import DialogView from "../../../../../../../stylized/DialogView";
import FormWrapper from "../../../../Integration/FormWrapper/FormWrapper";
import AlertDialog from "../../../../../../../stylized/AlertDialog";
import { ButtonComponent } from "../../../../../../../button/button";

export default function Move({
    disable,
    setMove,
    moveValue,
    parentFolderId,
    activeWorkspace,
    selectedCheckedData,
    selectedRowData,
    setSelectedRowData,
    isFileOrFolderSelected,
    lastSelectedRowData,
    folderData,
    itemSpace,
    setIsMove = () => {},
    handleClickResult = () => {},
    rowsPerPage,
    page,
    enable,
    fetchFolderListData = () => {},
    workSpaceFilterTable,
    parent,
}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [displayShow, setDisplayShow] = React.useState(false);
    const [moveItem, setMoveItem] = React.useState(null);
    const [showUploadOption, setShowUploadOption] = React.useState(false);
    const [alertDialogShow, setAlertDialogShow] = React.useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);

    const [alertDialogContent, setAlertDialogContent] = React.useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const handleConfirmationDialog = () => {
        showAlertView("Move Item", "Multiple Selection Not Allowed");
    };
    const showAlertView = (title, body, messageId, buttonId, isError) => {
        setAlertDialogContent({
            title: title,
            message: body,
            messageId: messageId,
            buttonId: buttonId,
        });
        isError ? setAlertDialogShow(true) : setShowSnackbar(true);
    };
    React.useEffect(() => {
        if (moveValue) {
            handleMove(); // Ensure this doesn't unintentionally update state repeatedly
        }
    }, [moveValue]); // Check dependencies

    const handleMove = (event) => {
        setIsMove(event?.target?.innerText || "default");
        setAnchorEl(null);
        setMoveItem("folder");
        setShowUploadOption(true);
    };

    const handleCloseUploadDialog = () => {
        setShowUploadOption(false);
        setMove(false);
    };

    return (
        <>
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
                handleConfirmationDialog={handleConfirmationDialog}
                messageId={alertDialogContent.messageId}
                buttonId={alertDialogContent.buttonId}
                buttonName={alertDialogContent.buttonName}
            ></AlertDialog>
            <DialogView
                show={showUploadOption}
                size="lg"
                handleClose={handleCloseUploadDialog}
                showTitle={false}
                showFooter={false}
                onBackdropClick={false}
                style={displayShow ? { display: "none" } : { display: "block" }}
            >
                <FormWrapper
                    setIsMove={setIsMove}
                    Display={setDisplayShow}
                    activeWorkspace={activeWorkspace}
                    documentDetails={lastSelectedRowData}
                    parentFolderId={parentFolderId}
                    onHide={handleCloseUploadDialog}
                    parent={parent}
                    folderData={folderData}
                    fetchFolderData={fetchFolderListData}
                    isFileOrFolderSelected={isFileOrFolderSelected}
                    selectedRowData={selectedRowData}
                    setSelectedRowData={setSelectedRowData}
                    selectedCheckedData={selectedCheckedData}
                    accountType={"headerTitle"}
                    itemSpace={itemSpace}
                    defaultStep={1}
                    handleClickResult={handleClickResult}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    // formType={"shareChat"}
                    workSpaceFilterTable={workSpaceFilterTable}
                    heading={"Move"}
                    hideFile={moveItem === "folder"}
                    hideFolder={moveItem === "space"}
                ></FormWrapper>
            </DialogView>
        </>
    );
}
