import React, { useState, useEffect } from "react";
import "./WorkspaceCardItemView.css";
import { useSelector, useDispatch } from "react-redux";
import http from "../../../bae-client/HTTP";
import RenameWorkspace from "./ContentPane/settings/renameWorkspace";
import AlertDialog from "../../stylized/AlertDialog";
import CustomSnackbar from "../../stylized/CustomSnackbar/CustomSnackbar";
import * as Mixpanel from "../../../mix-panel/Mixpanel";
import {
    fetchDirectoriesAction,
    fetchWorkspaceListAction,
    setActivePageAction,
    setActiveWorkspaceAction,
    setSnackbarAction,
    setSpaceListAction,
    setWorkspaceRedirectionAction,
} from "../../../services/services/app";
import Loader from "../../stylized/Loader";
import GeneralTable from "./LeftPane/GeneralTable";
import { getParsedDateByDateAndTime } from "../../../bae-client/Utils";
import { setShowSettingWs } from "../../../actions/Rippleaction";
import CustomSearch from "./ContentPane/Workspace/searchbar/CustomSearch";
import WorkspaceFilterTable from "./ContentPane/Workspace/WorkspaceFilterTable/WorkspaceFilterTable";
import DocumentViewerModal from "../../stylized/DocumentViewerModal";
import DocViwer from "../../stylized/DocViwer";
import { setActivePage } from "../../../actions";

const WorkspaceSettings = ({ isInWorkspaces = false }) => {
    const [workspace, setWorkspace] = useState(null);
    const [workspaces, setWorkspaces] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [currentShowFileLocation, setCurrentShowFileLocation] = useState("");
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [search, setSearch] = useState("");
    const [alertDialogShow, setAlertDialogShow] = useState(false);
    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonName: "",
        buttonId: "",
    });
    const [listRowData, setListRowData] = useState([]);
    const [loading, setLoading] = useState(false);
    const showSettingsWs = useSelector((state) => state.showSettingsWs);
    const [workspacesValue, setWorkspacesValue] = useState(null);
    const dispatch = useDispatch();
    const workspaceList = useSelector((state) => state.workspaceList);
    const [seeAllBtn, setSeeAllBtn] = useState(false);

    useEffect(() => {
        if (workspaceList && workspaceList.length) {
            setWorkspaces(
                workspaceList.map((workspace) =>
                    workspace.workspaceName.toLowerCase().trim()
                )
            );
            setWorkspacesValue(Object.values(workspaceList).sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn)));
        }
    }, [workspaceList]);

    const closeDocumentDisplayModal = () => {
        setShowDocumentModal(false);
        setCurrentShowFileLocation("");
    };
    const handleCloseModal = () => setModalShow(false);
    const submitRenameWorkspace = (workspaceName, workspaceId) => {
        setModalShow(false);
        let url = "workspace/workspace";
        const data = {
            workspaceId: workspaceId,
            workspaceName: workspaceName.trim(),
        };
        setLoading(true);
        http.sendRestRequest(url, "PUT", data)
            .then((response) => {
                Mixpanel.callWorkspaceManagementEvent("Rename");
                setSnackbarMessage("Workspace has been renamed successfully");
                setShowSnackbar(true);
                dispatch(fetchWorkspaceListAction());
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };
    const renameWorkspaceHandler = (workspace) => {
        setSnackbarMessage("");
        setWorkspace(workspace);
        setModalShow(true);
    };

    const deleteWorkspaceHandler = (workspace) => {
        setWorkspace(workspace);
        setAlertDialogContent({
            title: "Delete Workspace",
            message: "Are you sure you want to delete this workspace?",
            isAlert: true,
            messageId: "workspaceDeleteMsg",
            buttonName: "Delete",
            buttonId: "workspaceDeleteBtn",
        });
        setAlertDialogShow(true);
    };
    const sortDataByDate = (sortType) => {
        const workspaceArray = Object.values(workspaceList);
        if (sortType === "assending") {
            workspaceArray.sort((a, b) => {
                return (
                    new Date(a.updatedOn).getTime() -
                    new Date(b.updatedOn).getTime()
                );
            });
            // this.setState({ logRowsData: rawData });
        } else if (sortType === "descending") {
            workspaceArray.sort((a, b) => {
                return (
                    new Date(b.updatedOn).getTime() -
                    new Date(a.updatedOn).getTime()
                );
            });

            // this.setState({ logRowsData: rawData });
        }
        setWorkspacesValue(workspaceArray);
    };
    const sortByAlphabetically = (sortType) => {
        const workspaceArray = Object.values(workspaceList);
        if (sortType === "assending") {
            workspaceArray.sort((a, b) =>
                a.workspaceName.localeCompare(b.workspaceName)
            );
        } else if (sortType === "descending") {
            workspaceArray.sort((a, b) =>
                b.workspaceName.localeCompare(a.workspaceName)
            );
        }
        setWorkspacesValue(workspaceArray);
    };
    const handleClickResult = (pageNumber, rowsPerPageNumber) => {
        let url = "workspace/search";
        let body = {
            page: pageNumber || pageNumber === 0 ? pageNumber + 1 : 1,
            pageSize: rowsPerPageNumber ? rowsPerPageNumber : 10,
            keyword: debouncedSearch,
            searchInDocumentContents: true,
        };
        setLoading(true);
        http.sendRestRequest(url, "POST", body)
            .then((resp) => {
                setListRowData(resp);
                // setFilteredData(resp.data);
                // setShowDropdown(true);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };
    const handleDateFilter = (listValue, sortBy) => {
        let actionType = listValue;
        if (actionType === "Date Added") {
            if (sortBy === "asc") {
                sortDataByDate("descending");
            } else if (sortBy === "desc") {
                sortDataByDate("assending");
            }
        } else if (actionType === "Title") {
            if (sortBy === "asc") {
                sortByAlphabetically("descending");
            } else if (sortBy === "desc") {
                sortByAlphabetically("assending");
            }
        }
    };
    const renderDocumentDisplayModal = (documentId) => {
        setLoading(true);
        http.sendRestRequest(
            `document/documentDetail?documentId=${parseInt(documentId)}`
        )
            .then((response) => {
                setLoading(false);
                setSelectedDocument(response.documentName);
                setCurrentShowFileLocation(response);
                setShowDocumentModal(true);
            })
            .catch((error) => {
                setLoading(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
                console.log(error);
            });
    };
    const handleConfirmationDialog = (response) => {
        setSnackbarMessage("");
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });
        if (response === "YES" && workspace && workspace.workspaceId) {
            let url = "workspace/workspace";
            const data = {
                workspaceId: workspace.workspaceId,
            };
            setLoading(true);
            http.sendRestRequest(url, "DELETE", data)
                .then((response) => {
                    Mixpanel.callWorkspaceManagementEvent("Delete");
                    setSnackbarMessage(
                        "Workspace has been deleted successfully"
                    );
                    setLoading(false);
                    setShowSnackbar(true);
                    dispatch(fetchWorkspaceListAction());
                })
                .catch((error) => {
                    setLoading(false);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                    console.log(error);
                });
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        handleClickResult(newPage);
    };
    const renderViewDocumentModal = () => {
        return (
            <DocViwer
                docName={selectedDocument}
                fileLocation={currentShowFileLocation}
            ></DocViwer>
        );
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
        handleClickResult(0, +event.target.value);
    };
    const setCurrentWorkspace = (workspace) => {
        localStorage.setItem("workspaceId", workspace.workspaceId);
        http.sendRestRequest(
            `workspace/spaceList?workspaceId=${workspace.workspaceId}`
        )
            .then((response) => {
                dispatch(setActiveWorkspaceAction(workspace));
                dispatch(setSpaceListAction(response));
                dispatch(setActivePageAction("Workspace"));
            })
            .catch((err) => console.log(err));
    };

    const setCurrentWorkspaceSearch = (workspace) => {
        localStorage.setItem("workspaceId", workspace.objectId);
        http.sendRestRequest(
            `workspace/spaceList?workspaceId=${workspace.objectId}`
        )
            .then((response) => {
                dispatch(setActiveWorkspaceAction(workspace));
                dispatch(setSpaceListAction(response));
                dispatch(setActivePageAction("Workspace"));
            })
            .catch((err) => console.log(err));
    };

    const handleActivityClick = (item) => {
        if (item?.objectType === "SPACE") {
            const spaceId = item?.objectId;
            const idArray = item?.clickActionParameters?.split("/") || [];
            const workspaceId = idArray.length > 0 ? idArray[0] : null;
            const resWorkspace = workspaceList.find(
                (workspace) =>
                    parseInt(workspace.workspaceId) === parseInt(workspaceId)
            );

            http.sendRestRequest(
                `workspace/spaceList?workspaceId=${workspaceId}`
            )
                .then((spaces) => {
                    const space = spaces.find(
                        (space) => space.spaceId === spaceId
                    );
                    if (!space) {
                        dispatch(
                            setSnackbarAction({
                                open: true,
                                message: `Space Got Archived/Deleted!`,
                                severity: "error",
                            })
                        );
                        return;
                    } else {
                        const breadCrumbItems = [
                            {
                                id: resWorkspace?.workspaceId,
                                name: resWorkspace?.workspaceName,
                                type: "workspace",
                                parentId: 0,
                            },
                        ];
                        breadCrumbItems.push({
                            id: space?.spaceId,
                            name: space?.spaceName,
                            type: "space",
                            parentId: resWorkspace?.workspaceId,
                        });
                        let activeSpace = {
                            id: space?.spaceId,
                            name: space?.spaceName,
                            updatedOn: space?.updatedOn,
                            createdOn: space?.createdOn,
                            isShared: space?.isShared ? "Shared" : "Personal",
                            isStarred: space?.isStarred,
                            type: "space",
                            documentId: null,
                        };
                        dispatch(
                            setWorkspaceRedirectionAction(
                                {
                                    activeSpace: activeSpace,
                                    breadCrumbItems: breadCrumbItems,
                                    folderId: 0,
                                    folderName: activeSpace?.name,
                                    activeWorkspace: {
                                        workspaceId: resWorkspace?.workspaceId,
                                        workspaceName:
                                            resWorkspace?.workspaceName,
                                    },
                                },
                                true
                            )
                        );
                        dispatch(setActivePage("Workspace"));
                    }
                })
                .catch((error) => {
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: error.error,
                            severity: "error",
                        })
                    );
                });
        } else if (item?.objectType === "FOLDER") {
            const folderId = item?.objectId;
            const idArray = item?.clickActionParameters?.split("/") || [];
            let folderName = "";
            setLoading(true);
            http.sendRestRequest(`folders/folderDetails?folderId=${folderId}`)
                .then((resp) => {
                    setLoading(false);
                    const folderIdArray = resp.folderIdPath.split("/");
                    let parentFolderId = 0;
                    if (folderIdArray.length !== 1) {
                        parentFolderId =
                            folderIdArray[folderIdArray.length - 2];
                    }
                    const folderNameArray = resp.folderNamePath.split("/");
                    const spaceId = resp.spaceId;
                    const workspaceId = idArray[0];
                    const resWorkspace = workspaceList.find(
                        (workspace) =>
                            parseInt(workspace.workspaceId) ===
                            parseInt(workspaceId)
                    );
                    http.sendRestRequest(
                        `folders/folderData?spaceId=${spaceId}&folderId=${parentFolderId}`
                    )
                        .then((res) => {
                            let isFolderPresent = res.find(
                                (item) => item.objectId === folderId
                            );
                            if (!isFolderPresent) {
                                dispatch(
                                    setSnackbarAction({
                                        open: true,
                                        message: `Folder Got Archived!`,
                                        severity: "error",
                                    })
                                );
                                setLoading(false);
                                return;
                            } else {
                                http.sendRestRequest(
                                    `workspace/spaceList?workspaceId=${workspaceId}`
                                )
                                    .then((spaces) => {
                                        const space = spaces.find(
                                            (space) => space.spaceId === spaceId
                                        );
                                        if (!space) {
                                            dispatch(
                                                setSnackbarAction({
                                                    open: true,
                                                    message: `Space Got Deleted!`,
                                                    severity: "error",
                                                })
                                            );
                                            return;
                                        } else {
                                            const breadCrumbItems = [
                                                {
                                                    id: resWorkspace?.workspaceId,
                                                    name: resWorkspace?.workspaceName,
                                                    type: "workspace",
                                                    parentId: 0,
                                                },
                                            ];

                                            breadCrumbItems.push({
                                                id: space?.spaceId,
                                                name: space?.spaceName,
                                                type: "space",
                                                parentId:
                                                    resWorkspace?.workspaceId,
                                            });
                                            let activeSpace = {
                                                id: space?.spaceId,
                                                name: space?.spaceName,
                                                updatedOn: space?.updatedOn,
                                                createdOn: space?.createdOn,
                                                isShared: space?.isShared
                                                    ? "Shared"
                                                    : "Personal",
                                                isStarred: space?.isStarred,
                                                type: "space",
                                                documentId: null,
                                            };
                                            folderIdArray.forEach(
                                                (element, index) => {
                                                    if (
                                                        parseInt(folderId) ===
                                                        parseInt(element)
                                                    ) {
                                                        folderName =
                                                            folderNameArray[
                                                                index
                                                            ];
                                                    }
                                                    breadCrumbItems.push({
                                                        id: parseInt(element),
                                                        name: folderNameArray[
                                                            index
                                                        ],
                                                        type: "folder",
                                                        parentId:
                                                            index === 0
                                                                ? 0
                                                                : parseInt(
                                                                      folderIdArray[
                                                                          index -
                                                                              1
                                                                      ]
                                                                  ),
                                                    });
                                                }
                                            );
                                            const redirectionObject = {
                                                breadCrumbItems:
                                                    breadCrumbItems,
                                                folderId: parseInt(folderId),
                                                folderName,
                                                type: "folder",
                                                activeSpace: activeSpace,
                                                activeWorkspace: {
                                                    workspaceId:
                                                        resWorkspace?.workspaceId,
                                                    workspaceName:
                                                        resWorkspace?.workspaceName,
                                                },
                                            };
                                            dispatch(
                                                setWorkspaceRedirectionAction(
                                                    redirectionObject,
                                                    true
                                                )
                                            );
                                            dispatch(
                                                setActivePage("Workspace")
                                            );
                                        }
                                        setLoading(false);
                                    })
                                    .catch((err) => {
                                        setLoading(false);
                                        console.log(err);
                                        dispatch(
                                            setSnackbarAction({
                                                open: true,
                                                message: `Space Got Deleted!`,
                                                severity: "error",
                                            })
                                        );
                                        return;
                                    });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            setLoading(false);
                        });
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: `Folder Is not present in the given location!`,
                            severity: "error",
                        })
                    );
                    return;
                });
        }
    };

    const renderWorkspaceItems = () => {
        const columns = [
            {
                id: "workspaceName",
                label: "Title",
                minWidth: "100px",
                textAlign: "left",
                renderCell: (value, data, index) => {
                    return (
                        <span
                            className="textEllipsis1"
                            title={value}
                            onClick={() => {
                                dispatch(setShowSettingWs(false));
                                dispatch(setActivePageAction("Workspace"));
                                setCurrentWorkspace(data);
                            }}
                        >
                            {value}
                        </span>
                    );
                },
            },
            {
                id: "updatedOn",
                label: "Date Added",
                minWidth: "150px",
                textAlign: "center",
                renderCell: (value) => getParsedDateByDateAndTime(value),
            },
            {
                id: "Actions",
                label: "Actions",
                minWidth: "150px",
                textAlign: "center",
                // renderCell: (value) => `<${value}>`,
            },
        ];

        if (workspaceList !== undefined && workspaceList.length > 0) {
            let workspaceListArray = [];

            return (
                <>
                    {showSettingsWs ? (
                        <div className="all_ws">All Workspaces</div>
                    ) : null}
                    <GeneralTable
                        columns={columns}
                        data={workspacesValue}
                        module={"Workspace"}
                        onSort={handleDateFilter}
                        renameWorkspace={renameWorkspaceHandler}
                        deleteWorkspace={deleteWorkspaceHandler}
                    />
                </>
            );
        } else return <div> no workspaces found</div>;
    };

    return (
        <>
            <div
                className="wsCardContainer invisibleScroller"
                // style={{ paddingTop: "20px" }}
            >
                {isInWorkspaces && (
                    <div style={{ width: "50%" }}>
                        <CustomSearch
                            setLoading={setLoading}
                            handleActivityClick={handleActivityClick}
                            setCurrentWorkspaceSearch={
                                setCurrentWorkspaceSearch
                            }
                            renderDocumentDisplayModal={
                                renderDocumentDisplayModal
                            }
                            setShowDropdown={setShowDropdown}
                            showDropdown={showDropdown}
                            setSeeAllBtn={setSeeAllBtn}
                            setSearch={setSearch}
                            search={search}
                            setDebouncedSearch={setDebouncedSearch}
                            debouncedSearch={debouncedSearch}
                            setListRowData={setListRowData}
                            handleClickResult={handleClickResult}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </div>
                )}
                {seeAllBtn ? (
                    <WorkspaceFilterTable
                        handleActivityClick={handleActivityClick}
                        setCurrentWorkspaceSearch={setCurrentWorkspaceSearch}
                        setShowDropdown={setShowDropdown}
                        renderDocumentDisplayModal={renderDocumentDisplayModal}
                        debouncedSearch={debouncedSearch}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        listRowData={listRowData}
                        setListRowData={setListRowData}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        handleClickResult={handleClickResult}
                    />
                ) : (
                    renderWorkspaceItems()
                )}
            </div>
            <RenameWorkspace
                modalShow={modalShow}
                handleCloseModal={handleCloseModal}
                submitRenameWorkspace={submitRenameWorkspace}
                workspace={workspace}
                workspaces={workspaces}
            ></RenameWorkspace>
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
                handleConfirmationDialog={handleConfirmationDialog}
                messageId={alertDialogContent.messageId}
                buttonId={alertDialogContent.buttonId}
                buttonName={alertDialogContent.buttonName}
            ></AlertDialog>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
                setOpen={() => setShowAlertDialog(!showAlertDialog)}
            ></AlertDialog>
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
            {showDocumentModal ? (
                <DocumentViewerModal
                    showHeader={true}
                    footer="false"
                    show={showDocumentModal}
                    size="lg"
                    title={selectedDocument}
                    onHide={closeDocumentDisplayModal}
                    onclick={() => {}}
                    submitButtonText="Close"
                    headerTitle={selectedDocument}
                    documentId={currentShowFileLocation?.documentId}
                    fileSource={currentShowFileLocation}
                >
                    {renderViewDocumentModal()}
                </DocumentViewerModal>
            ) : null}

            <Loader show={loading} />
        </>
    );
};

export default WorkspaceSettings;
