import React from "react";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./style.css";
import { useDispatch } from "react-redux";
import { setSnackbarAction } from "../../../../../../services/services/app";

const Dropdown = ({
    options,
    handleDropdownSelection = () => {},
    defaultOption = "",
    isDateDropdown = false,
}) => {
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const [showDateRange, setShowDateRange] = React.useState(false);
    const [startDate, setStartDate] = React.useState(dayjs(""));
    const [endDate, setEndDate] = React.useState(dayjs(""));
    const menuRef = React.useRef(null);
    const rootRef = React.useRef(null);

    const dispatch = useDispatch();

    const closeOpenMenus = (e) => {
        if (
            !menuRef.current?.contains(e.target) ||
            !rootRef.current?.contains(e.target)
        ) {
            if (
                showDateRange &&
                startDate &&
                endDate &&
                typeof startDate !== "string" &&
                typeof endDate !== "string"
            ) {
                return;
            }
            if (isOpen) setIsOpen(false);
            if (showDateRange) setShowDateRange(false);
        }
    };

    const dateSelector = (e, newDate, type) => {
        if (type === "start") {
            setStartDate(dayjs(newDate).format("YYYY-MM-DD HH:mm:ss"));
        } else {
            setEndDate(dayjs(newDate).format("YYYY-MM-DD HH:mm:ss"));
        }
    };
    const handleDateRangeSelection = (e, option) => {
        e.stopPropagation();
        if (option.label === "Custom") {
            setIsOpen(false);
            setShowDateRange(true);
            return;
        } else {
            setShowDateRange(false);
            setSelectedOption(option);
            setIsOpen(false);
        }
    };

    React.useEffect(() => {
        document.addEventListener("mousedown", closeOpenMenus);
        return () => document.removeEventListener("mousedown", closeOpenMenus);
    }, [isOpen]);

    React.useEffect(() => {
        if (
            startDate &&
            endDate &&
            startDate < endDate &&
            typeof startDate === "string" &&
            typeof endDate === "string"
        ) {
            setSelectedOption({ id: 6, label: "Custom" });
            setShowDateRange(false);
            handleDropdownSelection(startDate, endDate, false);
        }
        if (startDate > endDate) {
            dispatch(
                setSnackbarAction({
                    open: true,
                    message: "Start date should be less than end date",
                    severity: "error",
                })
            );
            setStartDate(dayjs(""));
            setEndDate(dayjs(""));
            return;
        }
    }, [startDate, endDate]);

    React.useEffect(() => {
        if (selectedOption?.label === "Last 24 hours") {
            const startDate = dayjs()
                .subtract(1, "day")
                .format("YYYY-MM-DD:HH:mm:ss");
            const endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
            handleDropdownSelection(startDate, endDate, false, "Last 24 hours");
        } else if (selectedOption?.label === "Today") {
            const startDate = dayjs().format("YYYY-MM-DD") + ":00:00:00";
            const endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
            handleDropdownSelection(startDate, endDate, true, "Today");
        } else if (selectedOption?.label === "Last 7 days") {
            const startDate = dayjs()
                .subtract(7, "day")
                .format("YYYY-MM-DD:HH:mm:ss");
            const endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
            handleDropdownSelection(startDate, endDate, false, "Last 7 days");
        } else if (selectedOption?.label === "Last 14 days") {
            const startDate = dayjs()
                .subtract(14, "day")
                .format("YYYY-MM-DD:HH:mm:ss");
            const endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
            handleDropdownSelection(startDate, endDate, false, "Last 14 days");
        } else if (selectedOption?.label === "Last 30 days") {
            const startDate = dayjs()
                .subtract(30, "day")
                .format("YYYY-MM-DD:HH:mm:ss");
            const endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
            handleDropdownSelection(startDate, endDate, false, "Last 30 days");
        } else if (selectedOption?.label === "Last 90 days") {
            const startDate = dayjs()
                .subtract(90, "day")
                .format("YYYY-MM-DD:HH:mm:ss");
            const endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
            handleDropdownSelection(startDate, endDate, false, "Last 90 days");
        } else if (selectedOption?.label === "Last Year") {
            const startDate = dayjs()
                .subtract(1, "year")
                .format("YYYY-MM-DD:HH:mm:ss");
            const endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
            handleDropdownSelection(startDate, endDate, false, "Last Year");
        } else if (selectedOption?.label === "All") {
            handleDropdownSelection("All", "All", false, "All");
        }
    }, [selectedOption]);
    return (
        <div
            className="dd-container"
            onClick={() => {
                setIsOpen((prev) => !prev);
                setShowDateRange(false);
                setEndDate(dayjs(""));
                setStartDate(dayjs(""));
            }}
            ref={rootRef}
        >
            {selectedOption?.label || defaultOption || "Select"}
            <span>
                {isOpen ? (
                    <ArrowDropUpOutlinedIcon />
                ) : (
                    <ArrowDropDownOutlinedIcon />
                )}
            </span>
            {isOpen && (
                <div className="dd-content" ref={menuRef}>
                    {options.map((option) => (
                        <div
                            key={option.id}
                            onClick={(e) => handleDateRangeSelection(e, option)}
                        >
                            {option.label}
                        </div>
                    ))}
                </div>
            )}
            {showDateRange && isDateDropdown && (
                <div
                    className="dd-content"
                    ref={menuRef}
                    onClick={(e) => e.stopPropagation()}
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                            components={["DatePicker", "DatePicker"]}
                        >
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                onChange={(newValue, ct) =>
                                    dateSelector(ct, newValue, "start")
                                }
                                disableFuture
                            />
                        </DemoContainer>
                        <DemoContainer components={["DatePicker"]}>
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                onChange={(newValue, ct) =>
                                    dateSelector(ct, newValue, "end")
                                }
                                disableFuture
                                minDate={dayjs(startDate).add(1, "day")}
                                sx={{ margin: 0 }}
                            />
                        </DemoContainer>
                    </LocalizationProvider>
                </div>
            )}
        </div>
    );
};

export default Dropdown;
