import React, { Component } from "react";
import http from "../../../bae-client/HTTP";
import AlertDialog from "../../stylized/AlertDialog";
import TrashBinListItem from "../../stylized/TrashBinListItem";
import EmptyTrash from "./EmptyTrash";
import Loader from "../../stylized/Loader";
import TitleBar from "./TiltleBar";
import * as Mixpanel from "../../../mix-panel/Mixpanel";
import GeneralTable from "./LeftPane/GeneralTable";
import { getParsedDateByDateAndTime } from "../../../bae-client/Utils";
import { getIconPathByType } from "../common/IconList";
const renderIcon = (type) => {
    switch (type) {
        case "file":
            return getIconPathByType("file_small");
        case "folder":
            return getIconPathByType("folder_small");
        case "space":
            return getIconPathByType("space_small");
        case "workspace":
            return getIconPathByType("workspace_small");
        default:
            return null;
    }
};
const columns = [
    {
        id: "objectName",
        label: "Title",
        minWidth: "100px",
        textAlign: "left",
        renderCell: (value, row) => {
            return <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                <img src={renderIcon(row.objectType.toLowerCase())} alt="" style={{ width: '20px' }} />
                <span className="textEllipsis1" title={value}>
                    {value}
                </span>
            </div>
        }
        ,
    },
    {
        id: "deletedOn",
        label: "Date",
        minWidth: "150px",
        textAlign: "center",
        renderCell: (value) => getParsedDateByDateAndTime(value),
    },

    {
        id: "Actions",
        label: "Actions",
        minWidth: "150px",
        textAlign: "center",
        // renderCell: (value) => `<${value}>`,
    },
];

class Trash_Bin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logRowsData: [],
            showLoader: false,
            sortBy: "descending",
            sortAplphaType: "descending",
            selectedFilter: "",
            alertDialogShow: false,
            alertDialogTitle: "",
            alertDialogMessage: "",
            openModal: false,
        };

        this.restoreFile = this.restoreFile.bind(this);
        this.setAlertDialogShow = this.setAlertDialogShow.bind(this);
        this.handleRestoreBtnClick = this.handleRestoreBtnClick.bind(this);
    }
    componentDidMount = () => {
        Mixpanel.callPageViewEvent("Trash Bin");
        this.getDeletedList();
    };

    restoreFile = (fileObject) => {
        let { objectType, objectId } = fileObject;
        objectType =
            fileObject.objectType === ""
                ? "FILE"
                : fileObject.objectType.toUpperCase();

        const endpoints = {
            FOLDER: `folders/restoreDeleted?folderRegistryId=${objectId}`,
            FILE: `files/restoreDeleted?spacePersistenceId=${objectId}`,
            SPACE: `space/restore?spaceId=${objectId}`,
            WORKSPACE: `workspace/restore?workspaceId=${objectId}`,
            EMAIL: `emailFiles/restoreDeleted?emailFileId=${objectId}`,
            DEFAULT: `delete/restore?objectId=${objectId}&objectType=${objectType}`,
        };

        const url = endpoints[objectType?.toUpperCase()] || endpoints.DEFAULT;
        const successMessage = `${
            objectType || "Object"
        } has been restored successfully!`;

        this.setState({ showLoader: true });

        http.sendRestRequest(url, "PUT")
            .then(() => {
                this.setState({
                    showLoader: false,
                    alertDialogShow: true,
                    alertDialogTitle: "Success",
                    alertDialogMessage: successMessage,
                });
                this.getDeletedList();
            })
            .catch((err) => {
                this.setState({
                    showLoader: false,
                    alertDialogShow: true,
                    alertDialogTitle: "Failure",
                    alertDialogMessage:
                        err.error ||
                        "An error occurred while restoring the item.",
                });
            });
    };

    handleRestoreBtnClick = (row, index) => {
        console.log(row, index);
        let objectType =
            row.objectType === "" ? "FILE" : row.objectType.toUpperCase();
        if (
            objectType === "FILE" ||
            objectType === "FOLDER" ||
            objectType === "SPACE" ||
            objectType === "ALERT" ||
            objectType === "WORKSPACE" ||
            objectType === "EMAIL"
        ) {
            this.restoreFile(row);
        } else {
            this.setState({
                alertDialogShow: true,
                alertDialogTitle: "",
                alertDialogMessage:
                    "Restoring delete item is only enabled for files and Alerts!",
            });
        }
    };
    deleteFile = (fileObject) => {
        let url = "";
        console.log(fileObject, fileObject.objectType.toUpperCase());
        switch (fileObject.objectType.toUpperCase()) {
            case "FOLDER":
                url = `folders/purge?folderRegistryId=${fileObject.objectId}`;
                break;
            case "FILE":
                url = `files/purge?spacePersistenceId=${fileObject.objectId}`;
                break;
            case "SPACE":
                url = `space/purge?spaceId=${fileObject.objectId}`;
                break;
            case "WORKSPACE":
                url = `workspace/purge?workspaceId=${fileObject.objectId}`;
                break;
            case "EMAIL":
                url = `emailFiles/purge?emailFileId=${fileObject.objectId}`;
                break;
            default:
                url = `files/purge?spacePersistenceId=${fileObject.objectId}`;
                return;
        }
        this.setState({ showLoader: true });
        http.sendRestRequest(url, "DELETE")
            .then((resp) => {
                const itemNames = {
                    File: "File",
                    Folder: "Folder",
                    Space: "Space",
                    Alert: "Notification",
                    Workspace: "Workspace",
                };
                const item = itemNames[fileObject.objectType] || "Item";
                this.setState({
                    showLoader: false,
                    alertDialogShow: true,
                    alertDialogTitle: "",
                    alertDialogMessage: `${item} has been deleted successfully!`,
                });
                this.getDeletedList();
            })
            .catch((err) => {
                this.setState({
                    showLoader: false,
                    alertDialogShow: true,
                    alertDialogTitle: "Error",
                    alertDialogMessage:
                        err?.error || "An error occurred during deletion.",
                });
            });
    };

    handleDeleteBtnClick = (row, index) => {
        let objectType =
            row.objectType === "" ? "FILE" : row.objectType.toUpperCase();
        if (
            objectType === "FILE" ||
            objectType === "FOLDER" ||
            objectType === "SPACE" ||
            objectType === "ALERT" ||
            objectType === "WORKSPACE" ||
            objectType === "EMAIL"
        ) {
            this.deleteFile(row);
        } else {
            this.setState({
                alertDialogShow: true,
                alertDialogTitle: "",
                alertDialogMessage:
                    "Deleting item is only enabled for files and Alerts!",
            });
        }
    };

    handleRestoreAllBtnClick = () => {
        this.setState({
            alertDialogShow: true,
            alertDialogTitle: "",
            alertDialogMessage: "Restoring all items is not enabled yet!",
        });
    };
    sortDataByExistingState = (data) => {
        let dataForSorting = data;
        if (this.state.sortBy === "assending") {
            dataForSorting.sort((a, b) => {
                return (
                    new Date(a.deletedOn).getTime() -
                    new Date(b.deletedOn).getTime()
                );
            });
        } else if (this.state.sortBy === "descending") {
            dataForSorting.sort((a, b) => {
                return (
                    new Date(b.deletedOn).getTime() -
                    new Date(a.deletedOn).getTime()
                );
            });
        }
        return dataForSorting;
    };
    sortDataByDate = (sortType) => {
        if (sortType === "assending") {
            this.state.logRowsData.sort((a, b) => {
                return (
                    new Date(a.deletedOn).getTime() -
                    new Date(b.deletedOn).getTime()
                );
            });
            // this.setState({ logRowsData: rawData });
        } else if (sortType === "descending") {
            this.state.logRowsData.sort((a, b) => {
                return (
                    new Date(b.deletedOn).getTime() -
                    new Date(a.deletedOn).getTime()
                );
            });
            // this.setState({ logRowsData: rawData });
        }
    };
    sortByAlphabetically = (sortType) => {
        if (sortType === "assending") {
            this.state.logRowsData.sort((a, b) =>
                a.objectType.localeCompare(b.objectType)
            );
        } else if (sortType === "descending") {
            this.state.logRowsData.sort((a, b) =>
                b.objectType.localeCompare(a.objectType)
            );
        }
    };
    handleDateFilter = (listValue, sortBy) => {
        // sortBtnClicked(listValue);
        let actionType = listValue;
        if (actionType === "Date") {
            if (sortBy === "asc") {
                this.sortDataByDate("descending");
                // this.setState({ sortBy: "descending" });
            } else if (sortBy === "desc") {
                this.sortDataByDate("assending");
                // this.setState({ sortBy: "assending" });
            }
        } else if (actionType === "Title") {
            if (sortBy === "asc") {
                this.sortByAlphabetically("descending");
                // this.setState({ sortAplphaType: "descending" });
            } else if (sortBy === "desc") {
                this.sortByAlphabetically("assending");
                // this.setState({ sortAplphaType: "assending" });
            }
        }
    };

    handleRestoreBtnClick = (row, index) => {
        let objectType =
            row.objectType === "" ? "FILE" : row.objectType.toUpperCase();
        if (
            objectType === "FILE" ||
            objectType === "FOLDER" ||
            objectType === "SPACE" ||
            objectType === "ALERT" ||
            objectType === "WORKSPACE" ||
            objectType === "EMAIL"
        ) {
            this.restoreFile(row);
        } else {
            this.setState({
                alertDialogShow: true,
                alertDialogTitle: "",
                alertDialogMessage:
                    "Restoring item is only enabled for files and Alerts!",
            });
        }
    };

    renderCardRow = () => {
        if (this.state.logRowsData.length)
            return (
                <GeneralTable
                    openModal={this.state.openModal}
                    restoreFile={this.handleRestoreBtnClick} // Prop passed to invoke restore
                    deletedItems={this.handleDeleteBtnClick}
                    columns={columns}
                    data={this.state.logRowsData}
                    onRowClick={this.handleRowSelected}
                    onSort={this.handleDateFilter}
                    page={this.state.page}
                    module={"trash"}
                    rowsPerPage={this.state.rowsPerPage}
                />
            );
        else if (!this.state.showLoader)
            return (
                <div
                    style={{
                        height: "84vh",
                        background: "white",
                        borderRadius: "10px",
                    }}
                >
                    <EmptyTrash></EmptyTrash>
                </div>
            );
    };

    getDeletedList = () => {
        this.setState({ showLoader: true });
        let url = "delete/objectList";
        http.sendRestRequest(url, "GET")
            .then((resp) => {
                let deletedItems = this.sortDataByExistingState(resp);
                if (deletedItems.length > 0) {
                    this.setState({
                        showLoader: false,
                        logRowsData: deletedItems,
                    });
                } else {
                    this.setState({
                        showLoader: false,
                        logRowsData: [],
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    showLoader: false,
                    alertDialogShow: true,
                    alertDialogTitle: "",
                    alertDialogMessage: err.error,
                });
            });
    };

    setAlertDialogShow = () => {
        this.setState({
            alertDialogShow: false,
            alertDialogTitle: "",
            alertDialogMessage: "",
        });
    };

    render() {
        return (
            <>
                <div className="w-100 pl-0 pr-0 contentPaneBottom">
                    {this.renderCardRow()}
                </div>
                <Loader show={this.state.showLoader}></Loader>
                <AlertDialog
                    open={this.state.alertDialogShow}
                    setOpen={this.setAlertDialogShow}
                    title={this.state.alertDialogTitle}
                    message={this.state.alertDialogMessage}
                    buttonId="closeModalButton"
                ></AlertDialog>
            </>
        );
    }
}
export default Trash_Bin;
