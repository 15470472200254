import dayjs from "dayjs";
import { getUTCDateFromLocal } from "../../../components/functional/UnityCentral/dashboard/utils/helper";

export const getTimeFiltersForLast24Hours = () => {
    const startDate = dayjs()
        .subtract(1, "day")
        .format("YYYY-MM-DD:HH:mm:ss.SSS");
    const endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss.SSS");
    return {
        startDate: getUTCDateFromLocal(startDate),
        endDate: getUTCDateFromLocal(endDate),
        timeRange: "Last 24 hours",
    };
};
