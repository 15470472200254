import React from "react";
import "../functional/UnityCentral/ContentPane/Home/home.css";
const textStyle = {
    fontFamily: "Montserrat",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "100%",
    letterSpacing: "0.15px",
    textAlign: "center",
    verticalAlign: "middle",
    color:"#9DA5B4",
    paddingTop:"10px"
};
export const EmptyHomeScreen = ({ heading, image, subTitle,style }) => {
    return (
        <div className="home-screen-empty">
            <h2>{heading}</h2>
            <img
                src={image}
                alt=""
                style={style}
            />
            <div style={textStyle}>{subTitle}</div>
        </div>
    );
};
