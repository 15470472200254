import dayjs from "dayjs";
import moment from "moment";
import { getUTCDateFromLocal } from "../utils/helper";

export const getStartDateEndDate = (localTimeFilters) => {
    let startDate = "",
        endDate = "";
    if (localTimeFilters?.timeRange === "Last 24 hours") {
        startDate = dayjs().subtract(1, "day").format("YYYY-MM-DD:HH:mm:ss");
        endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
    } else if (localTimeFilters?.timeRange === "Today") {
        startDate = dayjs().format("YYYY-MM-DD") + ":00:00:00";
        endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
    } else if (localTimeFilters?.timeRange === "Last 7 days") {
        startDate = dayjs().subtract(7, "day").format("YYYY-MM-DD:HH:mm:ss");
        endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
    } else if (localTimeFilters?.timeRange === "Last 14 days") {
        startDate = dayjs().subtract(14, "day").format("YYYY-MM-DD:HH:mm:ss");
        endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
    } else if (localTimeFilters?.timeRange === "Last 30 days") {
        startDate = dayjs().subtract(30, "day").format("YYYY-MM-DD:HH:mm:ss");
        endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
    } else if (localTimeFilters?.timeRange === "Last 90 days") {
        startDate = dayjs().subtract(90, "day").format("YYYY-MM-DD:HH:mm:ss");
        endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
    } else if (localTimeFilters?.timeRange === "Last Year") {
        startDate = dayjs().subtract(1, "year").format("YYYY-MM-DD:HH:mm:ss");
        endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
    }

    if (localTimeFilters?.timeRange === "All") {
        startDate = "";
        endDate = "";
    } else if (localTimeFilters?.timeRange === "Today") {
        const currentTime = moment().format("HH:mm:ss");
        const updateStartDate = startDate.split(" ")[0] + " 00:00:00";
        const updateEndDate = endDate.split(" ")[0] + " " + currentTime;
        startDate = getUTCDateFromLocal(updateStartDate);
        endDate = getUTCDateFromLocal(updateEndDate);
    } else {
        const currentTime = moment().format("HH:mm:ss");
        const updateStartDate = startDate.split(" ")[0] + " " + currentTime;
        const updateEndDate = endDate.split(" ")[0] + " " + currentTime;
        startDate = getUTCDateFromLocal(updateStartDate);
        endDate = getUTCDateFromLocal(updateEndDate);
    }
    return { startDate, endDate };
};
