import React, { useEffect } from "react";
import "./style.css";
import { NavLink, useLocation } from "react-router-dom";
import UserSection from "../../LeftPane/user-section";
import WorkspaceList from "../../LeftPane/WorkspaceList";
import { useDispatch, useSelector } from "react-redux";
import { setUnreadAlertCountAction } from "../../../../../services/services/app";

const Navbar = ({ visibilityModal, handleLogout, setUser, showModal }) => {
    // const [unreadAlertCount, setUnreadAlertCount] = React.useState(0);
    let basePathName = window.runtimeConfig.instanceName
        ? "/" + window.runtimeConfig.instanceName
        : "";
    const modules = [
        {
            name: "Home",
            icon: "./images/UC-Icons-BW/home-icon-bw.svg",
            route: `/`,
        },
        {
            name: "Workspace",
            icon: "./images/UC-Icons-BW/workspace-icon-bw.svg",
            route: `/workspace`,
        },
        {
            name: "Flow",
            icon: "./images/UC-Icons-BW/flow-icon-bw.svg",
            route: `/flow`,
        },
        {
            name: "Ripple",
            icon: "./images/UC-Icons-BW/ripple-icon-bw.svg",
            route: `/ripple`,
        },
        {
            name: "Info Feed",
            icon: "./images/design/info-feed-icon.svg",
            route: `/info-feeds`,
        },
        {
            name: "Alerts",
            icon: "./images/design/bell.svg",
            route: `/alertlog`,
        },
        {
            name: "Chat",
            icon: "./images/UC-Icons-BW/chat-icon-bw.svg",
            route: `/chat`,
        },
        {
            name: "Directory",
            icon: "./images/UC-Icons-BW/directory-icon-bw.svg",
            route: `/directory`,
        },
        {
            name: "Settings",
            icon: "./images/UC-Icons-BW/settings-icon-bw.svg",
            route: `/settings`,
        },
    ];

    const infoFeedUnreadCount = useSelector(
        (state) => state.infoFeedUnreadCount
    );
    const unreadAlertCount = useSelector((state) => state.unreadAlertCount);
    const unreadChatCount = useSelector((state) => state.unreadChatCount);
    const { pathname } = useLocation();
    return (
        <div className="dashboard-navbar-container">
            <div style={{ background: "#21242c", paddingTop: "1.5rem" }}>
                <NavLink to="/" exact className={"nav-item"}>
                    <img
                        src="./images/Group 443.svg"
                        alt=""
                        style={{ padding: "0", height: "37px", width: "48px" }}
                    />
                </NavLink>
            </div>
            <div className="nav-divider"></div>
            <div className="dashboard-navbar">
                <div className="nav-modules">
                    {modules.map((module, index) => {
                        if (index === 1)
                            return (
                                <div
                                    className="nav-item"
                                    onClick={() => visibilityModal(!showModal)}
                                    title={"Workspaces"}
                                >
                                    <div
                                        className={
                                            pathname ===
                                                basePathName + module.route ||
                                            pathname ===
                                                basePathName +
                                                    module.route +
                                                    "/"
                                                ? "nav-item-icon active"
                                                : "nav-item-icon"
                                        }
                                    >
                                        <img src={module.icon} alt="" />
                                    </div>
                                    <span>{module.name}</span>
                                </div>
                            );
                        else
                            return (
                                <NavLink
                                    to={basePathName + module.route}
                                    exact
                                    className={"nav-item"}
                                    key={module.name}
                                    onClick={() => {
                                        module.name === "Workspace"
                                            ? visibilityModal(!showModal)
                                            : visibilityModal(false);
                                    }}
                                    title={module.name}
                                >
                                    <div
                                        className={
                                            pathname ===
                                                basePathName + module.route ||
                                            pathname ===
                                                basePathName +
                                                    module.route +
                                                    "/"
                                                ? "nav-item-icon active"
                                                : "nav-item-icon"
                                        }
                                    >
                                        {module.name === "Info Feed" &&
                                            infoFeedUnreadCount > 0 && (
                                                <span
                                                    className="counter  textEllipsis"
                                                    title={infoFeedUnreadCount}
                                                >
                                                    {infoFeedUnreadCount}
                                                </span>
                                            )}
                                        {module.name === "Alerts" &&
                                            unreadAlertCount > 0 && (
                                                <span
                                                    className="counter textEllipsis"
                                                    title={unreadAlertCount}
                                                >
                                                    {unreadAlertCount}
                                                </span>
                                            )}
                                        {module.name === "Chat" &&
                                            unreadChatCount > 0 && (
                                                <span
                                                    className="counter textEllipsis"
                                                    title={unreadChatCount}
                                                >
                                                    {unreadChatCount}
                                                </span>
                                            )}
                                        <img src={module.icon} alt="" />
                                    </div>

                                    <span>{module.name}</span>
                                </NavLink>
                            );
                    })}
                </div>
            </div>
            <div className="nav-divider"></div>
            <div className="dashboard-navbar-bottom">
                <UserSection
                    visibility={visibilityModal}
                    handleLogout={handleLogout}
                    setUser={setUser}
                    hideWSList={visibilityModal}
                />
            </div>
            <WorkspaceList
                showModal={showModal}
                setShowModal={visibilityModal}
            />
        </div>
    );
};

export default Navbar;
