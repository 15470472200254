import React, { useEffect, useState } from "react";
import "./style.css";
import Card from "../components/card/Card";
import Box from "../components/box/Box";
import { GET } from "../serivce/API";
import Alerts from "./Alerts";
import RecentActivity from "../../ContentPane/Home/recent-activities/recentActivity";
import InfoFeedTable from "../info-feeds/InfoFeedTable";
import { useNavigate } from "react-router";
import Dropdown from "../components/dropdown/Dropdown";
import moment from "moment";
import { getUTCDateFromLocal } from "../utils/helper";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
    setHomeTimeFiltersAction,
    setInfoFeedsTimeFiltersAction,
    setUnreadAlertCountAction,
} from "../../../../../services/services/app";
import Loading from "../../../../stylized/Loading";

import { EmptyHomeScreen } from "../../../../stylized/EmptyHomeScreen";
import http from "../../../../../bae-client/HTTP";
import WelcomeShow from "../../ContentPane/WorkspaceContainer/welcomePage";
import { setAddWorkspaceShowModal } from "../../../../../actions";
import { getStartDateEndDate } from "./helper";
const HomePage = () => {
    const [elementCount, setElementCount] = useState({
        directoryMember: 0,
        document: 0,
        flowInstance: 0,
        workspace: 0,
    });
    const [gettingStarted, setGettingStarted] = useState(null);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [showInfoFeed, setShowInfoFeed] = useState(false);
    const [alertsLoading, setAlertsLoading] = useState(false);
    const [allAlerts, setAllAlerts] = useState([]);
    const timeFilters = useSelector((state) => state.homeTimeFilters);
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const fetchAlerts = async () => {
        try {
            const { startDate, endDate } = getStartDateEndDate(timeFilters);
            setAlertsLoading(true);
            const data = await GET(
                `alert/list?pageNo=1&pageSize=50&Status=OPEN&startDate=${encodeURIComponent(
                    startDate
                )}&endDate=${encodeURIComponent(endDate)}`
            );
            setAllAlerts(data?.data || []);
            setAlertsLoading(false);
        } catch (error) {
            console.error(error);
            setAlertsLoading(false);
        }
    };

    const handleDateRangeSelection = (
        startDate,
        endDate,
        isToday,
        timeRange
    ) => {
        if (startDate === "All" && endDate === "All") {
            dispatch(
                setHomeTimeFiltersAction({
                    startDate: "",
                    endDate: "",
                    timeRange: "All",
                })
            );
            dispatch(
                setInfoFeedsTimeFiltersAction({ startDate: "", endDate: "" })
            );
        } else if (isToday) {
            const currentTime = moment().format("HH:mm:ss");
            const updateStartDate = startDate.split(":")[0] + " 00:00:00";
            const updateEndDate = endDate.split(":")[0] + " " + currentTime;
            dispatch(
                setHomeTimeFiltersAction({
                    startDate: getUTCDateFromLocal(updateStartDate),
                    endDate: getUTCDateFromLocal(updateEndDate),
                    timeRange: timeRange,
                })
            );
            dispatch(
                setInfoFeedsTimeFiltersAction({
                    startDate: getUTCDateFromLocal(updateStartDate),
                    endDate: getUTCDateFromLocal(updateEndDate),
                    timeRange: timeRange,
                })
            );
        } else {
            const currentTime = moment().format("HH:mm:ss");
            const updateStartDate = startDate.split(" ")[0] + " " + currentTime;
            const updateEndDate = endDate.split(" ")[0] + " " + currentTime;
            dispatch(
                setHomeTimeFiltersAction({
                    startDate: getUTCDateFromLocal(updateStartDate),
                    endDate: getUTCDateFromLocal(updateEndDate),
                    timeRange: timeRange,
                })
            );
            dispatch(
                setHomeTimeFiltersAction({
                    startDate: getUTCDateFromLocal(updateStartDate),
                    endDate: getUTCDateFromLocal(updateEndDate),
                    timeRange: timeRange,
                })
            );
        }
    };
    const fetchElementCount = async () => {
        try {
            const { startDate, endDate } = getStartDateEndDate(timeFilters);
            const data = await GET(
                `home/elementCounts?startDate=${encodeURIComponent(
                    startDate
                )}&endDate=${encodeURIComponent(endDate)}`
            );
            setElementCount(data);
        } catch (error) {
            console.error(error);
        }
    };

    const handleClick = (alert) => {
        const isRead = alert?.isRead;
        if (isRead) return;
        let payload = null;
        if (alert?.alertRegistryId) {
            payload = [
                {
                    alertRegistryId: alert.alertRegistryId,
                    isRead: true,
                },
            ];
            setAlertsLoading(true);
            http.sendRestRequest("alert/readOrUnread", "PUT", payload)
                .then(() => {
                    http.sendRestRequest("alert/unreadCount").then((response) =>
                        dispatch(
                            setUnreadAlertCountAction(
                                response?.unreadAlerts || 0
                            )
                        )
                    );

                    fetchAlerts();
                })
                .catch((e) => {
                    console.log(e);
                    setAlertsLoading(false);
                });
        } else {
            payload = [
                {
                    genericId: alert.genericId,
                    exceptionLogObjectId: alert.exceptionLogObjectId,
                    isRead: true,
                },
            ];
            setAlertsLoading(true);
            http.sendRestRequest(
                "documentChain/exception/readOrUnread",
                "PUT",
                payload
            )
                .then(() => {
                    http.sendRestRequest("alert/unreadCount").then((response) =>
                        dispatch(
                            setUnreadAlertCountAction(
                                response?.unreadAlerts || 0
                            )
                        )
                    );

                    fetchAlerts();
                })
                .catch((e) => {
                    console.log(e);
                    setAlertsLoading(false);
                });
        }
    };

    useEffect(() => {
        http.sendRestRequest("home/gettingStarted")
            .then((response) => {
                setGettingStarted(response);
                setShowWelcomeModal(response?.showGettingStarted);
            })
            .catch((err) => console.log(err));
        fetchElementCount(timeFilters);
        fetchAlerts(timeFilters);
        dispatch(setInfoFeedsTimeFiltersAction(timeFilters));
        setTimeout(() => setShowInfoFeed(true), 500);
    }, [timeFilters]);
    const createWorkspaceHandler = () => {
        document.getElementById("div_leftPane_workspaceDropdown").onclick();
        dispatch(setAddWorkspaceShowModal(true));
    };
    return (
        <div className="home-page-container">
            <div className="home-time-filter">
                <Dropdown
                    options={[
                        { id: 1, label: "Last 24 hours" },
                        { id: 6, label: "Today" },
                        { id: 2, label: "Last 7 days" },
                        { id: 3, label: "Last 30 days" },
                        { id: 4, label: "Last 90 days" },
                        { id: 5, label: "Last Year" },
                        { id: 6, label: "All" },
                    ]}
                    defaultOption={timeFilters?.timeRange || "Last 24 hours"}
                    handleDropdownSelection={handleDateRangeSelection}
                    isDateDropdown
                />
            </div>
            <div className="home-feeds">
                <div className="home-left-column">
                    <div className="home-left-column-top">
                        <Box
                            icon="./images/design/directory member.svg"
                            name="Directory Members"
                            count={elementCount.directoryMember}
                            color={"#F3E8FF"}
                        />
                        <Box
                            icon="./images/design/documents.svg"
                            name="Documents"
                            count={elementCount.document}
                            color={"#FFF4DE"}
                        />

                        <Box
                            icon="./images/design/workspace.svg"
                            name="Workspaces"
                            count={elementCount.workspace}
                            color={"#DCFCE7"}
                        />
                        <Box
                            icon="./images/design/flow.svg"
                            name="Flow Instance"
                            count={elementCount.flowInstance}
                        />
                    </div>
                    <div className="home-left-column-middle">
                        <Card>
                            {showInfoFeed && (
                                <div className="home-info-feed">
                                    <div className="home-info-feed-header">
                                        <span>Information Feed</span>
                                        <div
                                            onClick={() =>
                                                navigate("/info-feeds")
                                            }
                                            title="View All"
                                        >
                                            View All
                                        </div>
                                    </div>
                                    <div className="home-info-feed-table">
                                        <InfoFeedTable
                                            hideHeader
                                            timeFilters={timeFilters}
                                        />
                                    </div>
                                </div>
                            )}
                        </Card>
                    </div>
                    <div className="home-left-column-bottom">
                        <Card>
                            <RecentActivity timeFilters={timeFilters} />
                        </Card>
                    </div>
                </div>
                <div className="home-right-column">
                    <Card>
                        {alertsLoading ? (
                            <Loading />
                        ) : (
                            <div className="home-alert-section">
                                <div className="home-alert-header">
                                    <span>Alerts</span>
                                    <div onClick={() => navigate("/alertlog")}>
                                        View All
                                    </div>
                                </div>
                                <div
                                    className="home-alerts"
                                    style={{
                                        margin:
                                            allAlerts?.length === 0
                                                ? "auto 0"
                                                : null,
                                    }}
                                >
                                    {allAlerts && allAlerts?.length ? (
                                        allAlerts.map((alert) => (
                                            <Alerts
                                                key={alert.id}
                                                alert={alert}
                                                fetchAlerts={fetchAlerts}
                                                handleClick={handleClick}
                                            />
                                        ))
                                    ) : (
                                        <EmptyHomeScreen
                                            image="./images/alert_empty_icon.svg"
                                            subTitle="Nothing here yet"
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </Card>
                </div>
            </div>
            {showWelcomeModal && (
                <WelcomeShow
                    showModal={showWelcomeModal}
                    onHide={() => {
                        setShowWelcomeModal(!showWelcomeModal);
                    }}
                    onClose={() => {
                        setShowWelcomeModal(!showWelcomeModal);
                    }}
                    openCreateWorkspaceModal={createWorkspaceHandler}
                />
            )}
        </div>
    );
};

export default HomePage;
