export const getIcon = (object) => {
    const type =
        object.objectType.toLowerCase() !== "file" &&
        object.objectType !== "EMAIL" &&
        object.objectType !== "EMAIL_ATTACHMENT"
            ? object?.objectType.toLowerCase()
            : object?.objectName?.split(".").pop().toLowerCase();
    console.log(object, type);

    // if (type === "file") return "./images/FILE.svg";
    if (type === "png") return "./images/fileTypeIcon/jpg-icon.svg";
    if (type === "jpg") return "./images/fileTypeIcon/jpg-icon.svg";
    if (type === "folder") return "./images/FOLDER.svg";
    if (type === "space") return "./images/spaceIcon.svg";
    if (type === "workspace") return "./images/Workspace Icon.svg";
    if (type === "pdf") return "./images/fileTypeIcon/pdf.svg";
    if (type === "doc") return "./images/fileTypeIcon/doc.svg";
    if (type === "docx") return "./images/fileTypeIcon/doc.svg";

    if (type === "xls") return "./images/excel-icon.svg";
    if (type === "xlsx") return "./images/excel-icon.svg";
    if (type === "xlsm") return "./images/excel-icon.svg";
    if (type === "ppt") return "./images/ppt-icon.svg";
    if (type === "img") return "./images/fileTypeIcon/img.svg";
    if (type === "audio") return "./images/fileTypeIcon/audio.svg";
    if (type === "video") return "./images/fileTypeIcon/video.svg";
    if (type === "code") return "./images/fileTypeIcon/code.svg";
    if (type === "txt") return "./images/fileTypeIcon/text.svg";
    if (type === "zip") return "./images/fileTypeIcon/zip.svg";
    if (type === "exe") return "./images/fileTypeIcon/exe.svg";
    if (type === "json") return "./images/basicfile.png";
    if (type === "edi") return "./images/basicfile.png";
    if (type === "pptx") return "./images/ppt-icon.svg";
    if (type === "xml") return "./images/fileTypeIcon/xml-file.svg";
    if (type === "html") return "./images/fileTypeIcon/htmlIcon.svg";
};
