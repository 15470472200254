import React, { useCallback, useEffect } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import FileViewer from "react-file-viewer";
import http from "../../bae-client/HTTP";
import config from "../../config";
import XmlFileViewer from "./FileViewer/XmlFileViewer";
import "./FileViewer/docFileView.css";
import MimeType from "../helper/MimeType";
import { saveAs } from "file-saver";
import * as Mixpanel from "../../mix-panel/Mixpanel";
const onError = (e) => {};
const handleClick = (event) => {
    // event.preventDefault();
    // event.stopPropagation();
};

const handleDownload = (props) => {
    const baseUrl = window.location.origin;
    saveAs(
        baseUrl +
            "/" +
            props.fileLocation.documentLocation +
            "/" +
            props.fileLocation.documentName,
        props.fileLocation.documentName
    );
    let fileExt = props.fileLocation.documentName.split(".").pop();
    Mixpanel.callFileManagementEvent("Download", fileExt);
};
const handleContextMenu = (e) => {
    e.preventDefault(); // This will disable the right-click context menu
};
function DocViwer(props) {
    useEffect(() => {
        if (props.docName) {
            let fileExt = props.docName.split(".").pop();
            // Mixpanel.callFileManagementEvent("View", fileExt);
        }
    }, []);
    const renderModal =useCallback(()=>{
        if (props.docName && props.fileLocation) {
            const baseUrl = window.location.origin;
            const folderName = config.uploadFolder;
            const fileName =
                "" +
                baseUrl +
                "/" +
                folderName +
                "/" +
                props.spaceId +
                "/" +
                props.parentId +
                "/" +
                props.docName;
            const fileType = props.docName.split(".").pop().toLowerCase();
            const mimeType = MimeType.get(fileType); //fileType === "txt" ? "text/plain" : "audio/mpeg";
            let docs;
    
            if (
                ["pptx", "xlsx", "docx", "ppt", "xls", "doc", "xlsm"].includes(
                    fileType
                ) &&
                props.fileLocation?.documentLocation
            ) {
                const url =
                    baseUrl +
                    "/" +
                    props.fileLocation.documentLocation.split("\\").join("/") +
                    "/" +
                    encodeURIComponent(props.fileLocation.documentName);
                docs = [
                    {
                        uri: url,
                        fileType: fileType === "xlsm" ? "xlsx" : fileType,
                    },
                ];
            } else
                docs = [
                    {
                        uri:
                            "data:" +
                            mimeType +
                            ";base64," +
                            props.fileLocation.documentStream,
                        fileType:
                            fileType === "json" || fileType === "edi"
                                ? "txt"
                                : fileType, //fileType !== "json" ? fileType : fileType === "txt",
                    },
                ];
            if (
                fileType === "pptx" ||
                fileType === "ppt" ||
                fileType === "jpg" ||
                fileType === "png" ||
                fileType === "txt" ||
                fileType === "doc" ||
                fileType === "xlsx" ||
                fileType === "xls" ||
                fileType === "xlsm" ||
                fileType === "json" ||
                fileType === "edi"
            ) {
                return (
                    // <div className="docViewContainer">
                    <div
                        onContextMenu={handleContextMenu}
                        style={{
                            position: "relative",
                            width: "100%",
                            // height: "25rem",
                        }}
                    >
                        <DocViewer
                            key={props.fileLocation.documentName}
                            documents={docs}
                            pluginRenderers={DocViewerRenderers}
                            // style={{width: "100%", height: "100%"}}
                            theme={{
                                secondary: "#ffffff",
                            }}
                            config={{
                                header: {
                                    disableHeader: true,
                                    disableFileName: true,
                                    retainURLParams: false,
                                },
                            }}
                        />
                        {/* {" "} */}
                    </div>
                );
            } else if (
                fileType === "pdf" ||
                fileType === "mp4" ||
                fileType === "docx" ||
                fileType === "mp3"
            ) {
                return (
                    <div
                        //onClick={(e) => e.stopPropagation()}
                        //onClickCapture={(e) => e.stopPropagation()}
                        //onDoubleClick={(e) => e.stopPropagation()}
                        onContextMenu={handleContextMenu}
                    >
                        <section
                            onClick={handleClick}
                            style={{ marginBottom: "5px" }}
                        >
                            {/* <span
                                style={{ position: "relative", top: "-2rem" }}
                                className="newFontMontserrat"
                            >
                                {props.docName}
                            </span> */}
                            <FileViewer
                                //onClick={(e) => e.stopPropagation()}
                                key={props.fileLocation.documentName}
                                fileType={fileType}
                                filePath={docs[0].uri}
                                onError={onError}
                            />
                        </section>
                    </div>
                );
            } else if (fileType === "xml") {
                return (
                    <div onContextMenu={handleContextMenu}>
                        <XmlFileViewer
                            key={props.fileLocation.documentName}
                            fileUrl={docs[0].uri}
                            fileName={props.docName}
                        ></XmlFileViewer>
                    </div>
                );
            } else if (
                // fileType === "xml" ||
                fileType === "html" ||
                fileType === "htm"
            ) {
                return (
                    <div
                        id="html-viewer"
                        className="invisibleScroller"
                        style={{
                            position: "relative",
                            width: "100%",
                            overflow: "auto",
                            // height: "25rem",
                        }}
                    >
                        <iframe
                            className="docViewContainer"
                            src={docs[0].uri}
                            key={props.fileLocation.documentName}
                            style={{
                                border: "none",
                                width: "100%",
                                // height: "25rem",
                                background: "white",
                                // margin:"0px 100px 0px 100px"
                            }}
                        ></iframe>
                        {/* <div
                            onContextMenu={handleContextMenu}
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                zIndex: 10, // Make sure this overlay is on top of the iframe
                                background: "rgba(255, 255, 255, 0)", // Transparent background
                            }}
                        ></div> */}
                    </div>
                );
            } else if (
                fileType === "ai" ||
                fileType === "svg" ||
                fileType === "gif"
            ) {
                return (
                    <div onContextMenu={handleContextMenu}>
                        <div
                            className="docViewContainer"
                            style={{
                                width: "41.75rem",
                                height: "68vh",
                                margin: "auto",
                                background: "#fff",
                            }}
                        >
                            <div style={{ paddingTop: "50px" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        padding: "25px",
                                    }}
                                >
                                    <img
                                        src="./images/basicfile.png"
                                        width="50px"
                                        height="50px"
                                    ></img>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        padding: "25px",
                                    }}
                                >
                                    Sorry! Preview is not available for this file.
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        justifyContent: "center",
                                        padding: "25px",
                                    }}
                                >
                                    <button
                                        className="addAlertBtn"
                                        style={{
                                            border: "none",
                                            padding: "8px 20px",
                                        }}
                                        onClick={() => handleDownload(props)}
                                    >
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        } else {
            return null;
        }
    },[props.docName && props.fileLocation])
    return (
        <>
        {renderModal()}
        </>
    )
  
}

export default DocViwer;
