import React, { useEffect, useState } from "react";
import "./style.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import ContentPane from "../ContentPane";
import { useDispatch, useSelector } from "react-redux";
import { resetStore } from "../../../../actions";
import * as Mixpanel from "../../../../mix-panel/Mixpanel";
import http from "../../../../bae-client/HTTP";
import { setChatList } from "../../../../actions/CommunicatorActions";
import {
    setInfoFeedUnreadCountAction,
    setUnreadAlertCountAction,
} from "../../../../services/services/app";

// let basePathName = "UnityCentral";
let basePathName = window.runtimeConfig.instanceName
    ? "/" + window.runtimeConfig.instanceName
    : "";
// if (process.env.NODE_ENV !== "development") {
//     basePathName += "/" + http.restApp + "/UnityCentral";
// }
export const paths = [
    { path: "/", activePage: "Home" },
    { path: "/home", activePage: "Home" },
    { path: "/workspace", activePage: "Workspace" },
    { path: "/ripple", activePage: "Ripple" },
    { path: "/chat", activePage: "Chat" },
    { path: "/apps", activePage: "Apps" },
    { path: "/archive", activePage: "Archive" },
    { path: "/alertLog", activePage: "AlertLog" },
    { path: "/settings", activePage: "Settings" },
    { path: "/flow", activePage: "DocuChain" },
    { path: "/ripplelog", activePage: "Ripple Log" },
    { path: "/controltower", activePage: "Control Tower" },
    { path: "/trashbin", activePage: "Trash Bin" },
    { path: "/directory", activePage: "Directory" },
    { path: "/info-feeds", activePage: "Info Feed" },
    { path: "/none", activePage: "None" },
];

const DashBoardContainer = ({ setUser }) => {
    const activePage = useSelector((state) => state.activePage);
    const [showModal, setShowModal] = useState(false);
    const dispatch = useDispatch();

    const visibilityModal = (value) => {
        setShowModal(value);
    };

    const handleLogout = () => {
        dispatch(resetStore());
        Mixpanel.callUserIdentityEvent("Logoff");
    };

    useEffect(() => {
        let url = "communicator/chatList";
        http.sendRestRequest(url)
            .then((res) => {
                res.sort((a, b) => {
                    return (
                        Date.parse(b.messageOn.split(".")[0]) -
                        Date.parse(a.messageOn.split(".")[0])
                    );
                });
                dispatch(setChatList(res));
            })
            .catch((err) => {
                this.showAlertView("", err.error);
                console.log(err);
                this.setState({ showLoader: false });
            });
        http.sendRestRequest("documentLogs/unreadCount").then((response) =>
            dispatch(
                setInfoFeedUnreadCountAction(response?.unreadDocumentLog || 0)
            )
        );
        http.sendRestRequest("alert/unreadCount").then((response) =>
            dispatch(setUnreadAlertCountAction(response?.unreadAlerts || 0))
        );
    }, []);
    return (
        <div className="dashboard-container">
            <BrowserRouter>
                <Navbar
                    visibilityModal={visibilityModal}
                    setUser={setUser}
                    handleLogout={handleLogout}
                    showModal={showModal}
                />
                <div
                    className="dashboard-content"
                    onClick={() => setShowModal(false)}
                >
                    <Routes>
                        {paths.map((path) => (
                            <Route
                                exact
                                path={basePathName + path.path}
                                element={
                                    <ContentPane
                                        activePage={activePage}
                                        userName={""}
                                        closeModal={() => {}}
                                        setUser={() => {}}
                                        handleLogout={handleLogout}
                                    />
                                }
                            ></Route>
                        ))}
                    </Routes>
                </div>
            </BrowserRouter>
        </div>
    );
};

export default DashBoardContainer;
