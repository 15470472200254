import { useEffect, useState, useRef } from "react";
import Card from "../components/card/Card";
import { ButtonComponent } from "../../../../button/button";
import { GET, POST } from "../serivce/API";
import http from "../../../../../bae-client/HTTP";
import Loader from "../../../../stylized/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
    setInfoFeedPaginationAction,
    setInfoFeedsTimeFiltersAction,
    setInfoFeedTableDataAction,
    setInfoFeedUnreadCountAction,
    setSnackbarAction,
    setWorkspaceRedirectionAction,
} from "../../../../../services/services/app";
import {
    fetchFilterData,
    openInstance,
    setGenericIdToDisplayNameMap,
    setGenericIdToNameMap,
} from "../../../../../actions/DashboardActions";
import AddDocuments from "../../ContentPane/document-chain/modals/AddDocuments";
import AddInstance from "../../ContentPane/document-chain/modals/AddInstance";
import AlertDialog from "../../../../stylized/AlertDialog";
import { setInstanceList } from "../../../../../actions/DocumentChainActions";
import CustomSnackbar from "../../../../stylized/CustomSnackbar/CustomSnackbar";
import { setActivePage } from "../../../../../actions";
import { useLocation, useNavigate } from "react-router";
import _, { get } from "lodash";
import Dropdown from "../components/dropdown/Dropdown";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import { TablePagination } from "@mui/material";
import DocumentViewerModal from "../../../../stylized/DocumentViewerModal";
import DocViwer from "../../../../stylized/DocViwer";
import moment from "moment";
import { getLocalDateFromUTC, getUTCDateFromLocal } from "../utils/helper";
import { getIconUrls } from "../../ContentPane/Workspace/constant";
import { EmptyHomeScreen } from "../../../../stylized/EmptyHomeScreen";
import "./style.css";
import dayjs from "dayjs";
import TableActions from "./TableActions";
import Loading from "../../../../stylized/Loading";
import { getTimeFiltersForLast24Hours } from "../../../../../services/services/app/helper";

const columns = [
    { key: "documentName", label: "Document Name", type: "text" },
    { key: "source", label: "Source", type: "text" },
    { key: "workspaceLocation", label: "Location", type: "text" },
    { key: "flowPath", label: "Flow", type: "text" },
    { key: "flowStatus", label: "Status", type: "text" },
    { key: "createdOn", label: "Time Of Arrival", type: "date" },
];

const InfoFeedTable = ({ hideHeader }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    let basePathName = window.runtimeConfig.instanceName
        ? "/" + window.runtimeConfig.instanceName
        : "";
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const genericIdToNameMap = useSelector((state) => state.genericIdToNameMap);
    const [loading, setLoading] = useState(false);
    const [infoFeedData, setInfoFeedData] = useState({});
    const [data, setData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [currentShowFileName, setCurrentShowFileName] = useState("");
    const [currentShowFileLocation, setCurrentShowFileLocation] = useState("");
    const [documentModalShow, setDocumentModalShow] = useState(false);
    const workspaceList = useSelector((state) => state.workspaceList);
    const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
    const [selectedRows, setSelectedRows] = useState([]);
    const [filterBy, setFilterBy] = useState("");

    const { currentPage, rowsPerPage, totalRows } = useSelector(
        (state) => state.infoFeedPagination
    );
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [flowInfo, setFlowInfo] = useState(null);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [alertDialogShow, setAlertDialogShow] = useState(false);
    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
    });
    const localTimeFilters = useSelector((state) => state.infoFeedsTimeFilters);
    const infoFeedTableData = useSelector((state) => state.infoFeedTableData);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedInstance, setSelectedInstance] = useState(null);
    const [values, setValues] = useState(null);
    const [documentIdState, setDocumentIdState] = useState(null);
    const InstanceList = useSelector((state) => state.InstanceList);
    const [showAddInstanceModal, setShowAddInstanceModal] = useState(false);
    const fetchInfoFeedData = async () => {
        let startDate = "",
            endDate = "";
        if (localTimeFilters?.timeRange === "Last 24 hours") {
            startDate = dayjs()
                .subtract(1, "day")
                .format("YYYY-MM-DD:HH:mm:ss");
            endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
        } else if (localTimeFilters?.timeRange === "Today") {
            startDate = dayjs().format("YYYY-MM-DD") + ":00:00:00";
            endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
        } else if (localTimeFilters?.timeRange === "Last 7 days") {
            startDate = dayjs()
                .subtract(7, "day")
                .format("YYYY-MM-DD:HH:mm:ss");
            endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
        } else if (localTimeFilters?.timeRange === "Last 14 days") {
            startDate = dayjs()
                .subtract(14, "day")
                .format("YYYY-MM-DD:HH:mm:ss");
            endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
        } else if (localTimeFilters?.timeRange === "Last 30 days") {
            startDate = dayjs()
                .subtract(30, "day")
                .format("YYYY-MM-DD:HH:mm:ss");
            endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
        } else if (localTimeFilters?.timeRange === "Last 90 days") {
            startDate = dayjs()
                .subtract(90, "day")
                .format("YYYY-MM-DD:HH:mm:ss");
            endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
        } else if (localTimeFilters?.timeRange === "Last Year") {
            startDate = dayjs()
                .subtract(1, "year")
                .format("YYYY-MM-DD:HH:mm:ss");
            endDate = dayjs().format("YYYY-MM-DD:HH:mm:ss");
        }

        if (localTimeFilters?.timeRange === "All") {
            startDate = "";
            endDate = "";
        } else if (localTimeFilters?.timeRange === "Today") {
            const currentTime = moment().format("HH:mm:ss");
            const updateStartDate = startDate.split(" ")[0] + " 00:00:00";
            const updateEndDate = endDate.split(" ")[0] + " " + currentTime;
            startDate = getUTCDateFromLocal(updateStartDate);
            endDate = getUTCDateFromLocal(updateEndDate);
        } else {
            const currentTime = moment().format("HH:mm:ss");
            const updateStartDate = startDate.split(" ")[0] + " " + currentTime;
            const updateEndDate = endDate.split(" ")[0] + " " + currentTime;
            startDate = getUTCDateFromLocal(updateStartDate);
            endDate = getUTCDateFromLocal(updateEndDate);
        }
        try {
            setInfoFeedData({});
            const data = await GET(
                `documentLogs/list?pageNo=${currentPage}&pageSize=${rowsPerPage}&startDate=${encodeURIComponent(
                    startDate
                )}&endDate=${encodeURIComponent(endDate)}`
            );
            setInfoFeedData(data?.summary || {});
            setData(data?.data || []);
            setOriginalData(data?.data || []);
            dispatch(setInfoFeedTableDataAction(data?.data || []));
            if (data?.data?.length > 0) {
                dispatch(
                    setInfoFeedPaginationAction({
                        currentPage,
                        rowsPerPage,
                        totalRows: data?.totalRecords || 0,
                    })
                );
            }
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };
    useEffect(() => {
        if (localTimeFilters.startDate) fetchInfoFeedData();
    }, [localTimeFilters, currentPage, rowsPerPage]);

    useEffect(() => {
        if (infoFeedTableData.length) setData(infoFeedTableData);
    }, [infoFeedTableData]);

    useEffect(() => {
        return () => dispatch(setInfoFeedTableDataAction([]));
    }, []);

    useEffect(() => {
        if (data && data.length > 0) {
            createMapGenericIdToName();
        }
    }, [data]); // Runs when `data` changes

    const fetchLatestFlowsData = () => {
        http.sendRestRequest("documentChain/objects")
            .then((response) => {
                dispatch(fetchFilterData(response));
            })
            .catch((err) => console.log(err));
    };
    const sortData = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });
        setData((prevData) =>
            [...prevData].sort((a, b) => {
                if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
                if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
                return 0;
            })
        );
    };
    const renderViewDocumentModal = (event) => {
        return (
            <DocViwer
                docName={currentShowFileName}
                fileLocation={currentShowFileLocation}
            ></DocViwer>
        );
    };
    const handleDocumentModalClose = () => {
        setDocumentModalShow(false);
        setCurrentShowFileLocation("");
    };
    const handleRowDoubleClick = (documentId) => {
        setLoading(true);
        http.sendRestRequest(`document/documentDetail?documentId=${documentId}`)
            .then((response) => {
                // setShowDocumentModal(true);
                setDocumentModalShow(true);
                setCurrentShowFileName(response.documentName);
                setLoading(false);
                const fileType = response.documentName.split(".").pop();
                if (fileType === "pdf") {
                    setTimeout(() => {
                        setCurrentShowFileLocation(response);
                    }, 1);
                } else {
                    setCurrentShowFileLocation(response);
                }
            })
            .catch((error) => {
                setLoading(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };
    const toggleRowSelection = (row) => {
        if (!row.isRead) {
            const payload = [{ id: row.id, isRead: true }];
            http.sendRestRequest("documentLogs/readOrUnread", "PUT", payload)
                .then(() => {
                    setData((state) =>
                        state.map((item) =>
                            item.id === row.id
                                ? { ...item, isRead: true }
                                : item
                        )
                    );
                    setOriginalData((state) =>
                        state.map((item) =>
                            item.id === row.id
                                ? { ...item, isRead: true }
                                : item
                        )
                    );
                    dispatch(
                        setInfoFeedTableDataAction(
                            infoFeedTableData.map((item) =>
                                item.id === row.id
                                    ? { ...item, isRead: true }
                                    : item
                            )
                        )
                    );
                    setOriginalData(data?.data || []);
                    dispatch(setInfoFeedTableDataAction(data?.data || []));
                    setSelectedRows((prev) =>
                        prev.includes(row.id)
                            ? prev.filter((rowId) => rowId !== row.id)
                            : [row.id]
                    );
                })
                .catch((error) => {
                    console.error(error);
                });
        } else
            setSelectedRows((prev) =>
                prev.includes(row.id)
                    ? prev.filter((rowId) => rowId !== row.id)
                    : [row.id]
            );
    };

    const handleChangePage = (event, newPage) => {
        // if (data.length < rowsPerPage) return;
        dispatch(
            setInfoFeedPaginationAction({
                rowsPerPage,
                currentPage: newPage + 1,
            })
        );
    };

    const handleChangeRowsPerPage = (event) => {
        dispatch(
            setInfoFeedPaginationAction({
                rowsPerPage: parseInt(event.target.value, 10),
                currentPage: 1,
                totalRows,
            })
        );
    };

    const handleMarkAsReadOrUnRead = (type, docIds) => {
        const selectedDocument = [];
        docIds.forEach((id) => {
            selectedDocument.push({ id, isRead: true });
        });
        if (selectedDocument.length) {
            setLoading(true);
            const url = "documentLogs/readOrUnread";
            http.sendRestRequest(url, "PUT", selectedDocument)
                .then(() => {
                    setLoading(false);
                    http.sendRestRequest("documentLogs/unreadCount").then(
                        (response) =>
                            dispatch(
                                setInfoFeedUnreadCountAction(
                                    response?.unreadDocumentLog || 0
                                )
                            )
                    );
                    fetchInfoFeedData();
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                });
        }
    };

    const infoTable = useRef(null);
    const handleScroll = _.throttle(() => {
        const rowsScrolled =
            infoTable.current?.scrollTop &&
            parseInt(infoTable.current.scrollTop / 35) - 1; // substracting header row
        let docRowIds = [];
        if (rowsScrolled && rowsScrolled > 0) {
            data.slice(0, rowsScrolled).forEach((row) => {
                if (!row.isRead) {
                    docRowIds.push(row.id);
                }
            });
        }

        handleMarkAsReadOrUnRead("read", docRowIds);
    }, 1000);

    const handleOpenInWS = (row) => {
        if (!row || row.length === 0) {
            console.error("Invalid row data");
            return;
        }

        const matchedData = data.find((item) => item.id === row[0]);

        if (!matchedData) {
            console.error("No matching data found for row:", row[0]);
            return;
        }

        if (!matchedData.workspaceIdLocation) {
            console.error("workspaceIdLocation is missing for row:", row[0]);
            return;
        }

        // Extract folder ID
        const workspaceIdSegments = matchedData.workspaceIdLocation.split("|");
        const lastSegment = workspaceIdSegments[0]; // Extract the first part before '|'
        const folderIdArray = lastSegment.split("/");
        const globalWorkspaceId = parseInt(folderIdArray[0]);

        const folderId =
            folderIdArray.length > 2
                ? parseInt(folderIdArray[folderIdArray.length - 1])
                : 0;
        let folderName = "";
        setLoading(true);

        if (folderIdArray.length === 2) {
            const workspaceId = parseInt(folderIdArray[0]);
            const spaceId = parseInt(folderIdArray[1]);
            const resWorkspace = workspaceList.find(
                (workspace) =>
                    parseInt(workspace.workspaceId) === parseInt(workspaceId)
            );
            http.sendRestRequest(
                `folders/folderData?spaceId=${spaceId}&folderId=${0}`
            )
                .then((res) => {
                    http.sendRestRequest(
                        `workspace/spaceList?workspaceId=${workspaceId}`
                    )
                        .then((spaces) => {
                            const space = spaces.find(
                                (space) => space.spaceId === spaceId
                            );
                            if (!space) {
                                dispatch(
                                    setSnackbarAction({
                                        open: true,
                                        message: `Space Got Deleted!`,
                                        severity: "error",
                                    })
                                );
                                return;
                            } else {
                                const breadCrumbItems = [
                                    {
                                        id: workspaceId,
                                        name: resWorkspace?.workspaceName,
                                        type: "workspace",
                                        parentId: 0,
                                    },
                                ];

                                breadCrumbItems.push({
                                    id: space?.spaceId,
                                    name: space?.spaceName,
                                    type: "space",
                                    parentId: resWorkspace?.workspaceId,
                                });
                                let activeSpace = {
                                    id: space?.spaceId,
                                    name: space?.spaceName,
                                    updatedOn: space?.updatedOn,
                                    createdOn: space?.createdOn,
                                    isShared: space?.isShared
                                        ? "Shared"
                                        : "Personal",
                                    isStarred: space?.isStarred,
                                    type: "space",
                                    documentId: null,
                                };
                                const redirectionObject = {
                                    breadCrumbItems: breadCrumbItems,
                                    folderId: parseInt(folderId),
                                    folderName,
                                    type: "folder",
                                    activeSpace: activeSpace,
                                    activeWorkspace: {
                                        workspaceId: resWorkspace?.workspaceId,
                                        workspaceName:
                                            resWorkspace?.workspaceName,
                                    },
                                };
                                dispatch(
                                    setWorkspaceRedirectionAction(
                                        redirectionObject
                                    )
                                );
                                dispatch(setActivePage("Workspace"));
                                navigate(`${basePathName}/workspace`);
                            }
                            setLoading(false);
                        })
                        .catch((err) => {
                            setLoading(false);
                            console.log(err);
                            dispatch(
                                setSnackbarAction({
                                    open: true,
                                    message: `Space Got Deleted!`,
                                    severity: "error",
                                })
                            );
                            return;
                        });
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        } else {
            http.sendRestRequest(`folders/folderDetails?folderId=${folderId}`)
                .then((resp) => {
                    setLoading(false);

                    const folderIdArray = resp.folderIdPath.split("/");

                    let parentFolderId = 0;
                    if (folderIdArray.length !== 1) {
                        parentFolderId =
                            folderIdArray[folderIdArray.length - 1];
                    } else {
                        parentFolderId = folderIdArray[0];
                    }
                    const folderNameArray = resp.folderNamePath.split("/");
                    const spaceId = resp.spaceId;

                    const resWorkspace = workspaceList.find(
                        (workspace) =>
                            parseInt(workspace.workspaceId) ===
                            parseInt(globalWorkspaceId)
                    );
                    // Second API call to check if folder is present

                    http.sendRestRequest(
                        `folders/folderData?spaceId=${spaceId}&folderId=${parseInt(
                            parentFolderId
                        )}`
                    )
                        .then((res) => {
                            http.sendRestRequest(
                                `workspace/spaceList?workspaceId=${globalWorkspaceId}`
                            )
                                .then((spaces) => {
                                    const space = spaces.find(
                                        (space) => space.spaceId === spaceId
                                    );
                                    if (!space) {
                                        dispatch(
                                            setSnackbarAction({
                                                open: true,
                                                message: `Space Got Deleted!`,
                                                severity: "error",
                                            })
                                        );
                                        return;
                                    } else {
                                        const breadCrumbItems = [
                                            {
                                                id: resWorkspace?.workspaceId,
                                                name: resWorkspace?.workspaceName,
                                                type: "workspace",
                                                parentId: 0,
                                            },
                                        ];

                                        breadCrumbItems.push({
                                            id: space?.spaceId,
                                            name: space?.spaceName,
                                            type: "space",
                                            parentId: resWorkspace?.workspaceId,
                                        });
                                        let activeSpace = {
                                            id: space?.spaceId,
                                            name: space?.spaceName,
                                            updatedOn: space?.updatedOn,
                                            createdOn: space?.createdOn,
                                            isShared: space?.isShared
                                                ? "Shared"
                                                : "Personal",
                                            isStarred: space?.isStarred,
                                            type: "space",
                                            documentId: null,
                                        };
                                        folderIdArray.forEach(
                                            (element, index) => {
                                                if (
                                                    parseInt(folderId) ===
                                                    parseInt(element)
                                                ) {
                                                    folderName =
                                                        folderNameArray[index];
                                                }
                                                breadCrumbItems.push({
                                                    id: parseInt(element),
                                                    name: folderNameArray[
                                                        index
                                                    ],
                                                    type: "folder",
                                                    parentId:
                                                        index === 0
                                                            ? 0
                                                            : parseInt(
                                                                  folderIdArray[
                                                                      index - 1
                                                                  ]
                                                              ),
                                                });
                                            }
                                        );
                                        const redirectionObject = {
                                            breadCrumbItems: breadCrumbItems,
                                            folderId: parseInt(folderId),
                                            folderName,
                                            type: "folder",
                                            activeSpace: activeSpace,
                                            activeWorkspace: {
                                                workspaceId:
                                                    resWorkspace?.workspaceId,
                                                workspaceName:
                                                    resWorkspace?.workspaceName,
                                            },
                                        };
                                        dispatch(
                                            setWorkspaceRedirectionAction(
                                                redirectionObject,
                                                true
                                            )
                                        );
                                        dispatch(setActivePage("Workspace"));
                                        navigate(`${basePathName}/workspace`);
                                    }
                                    setLoading(false);
                                })
                                .catch((err) => {
                                    setLoading(false);
                                    console.log(err);
                                    dispatch(
                                        setSnackbarAction({
                                            open: true,
                                            message: `Space Got Deleted!`,
                                            severity: "error",
                                        })
                                    );
                                    return;
                                });
                        })
                        .catch((err) => {
                            console.log(err);
                            setLoading(false);
                        });
                })
                .catch((err) => {
                    setLoading(false);
                    console.error("Error fetching folder details:", err);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message:
                                "Folder is not present in the given location!",
                            severity: "error",
                        })
                    );
                });
        }
    };

    const createMapGenericIdToName = () => {
        let genericIdToNameMap = new Map();
        let genericIdToDisplayNameMap = new Map();

        data.forEach((filterItem) => {
            const flowIdPath = filterItem.flowIdPath?.split("|")[0]; // First entry before "|"
            const flowPath = filterItem.flowPath?.split("|")[0]; // First entry before "|"

            if (!flowIdPath || !flowPath) return;

            const [genericId] = flowIdPath.split("/"); // Extract genericId from flowIdPath
            const [genericName] = flowPath.split("/"); // Extract generic name from flowPath

            if (!genericId || !genericName) return;

            genericIdToNameMap.set(genericId, genericName);
            genericIdToDisplayNameMap.set(genericId, genericName);
        });

        dispatch(setGenericIdToNameMap(genericIdToNameMap));
        dispatch(setGenericIdToDisplayNameMap(genericIdToDisplayNameMap));
    };

    const handleOpenInFlow = (row) => {
        const matchedData = data.find((item) => item.id === row[0]);
        if (!matchedData) return;

        if (matchedData.flowStatus === "UNCHAINED") {
            return dispatch(
                setSnackbarAction({
                    open: true,
                    message: "This document is not attached to any flow",
                    severity: "error",
                })
            );
        }
        const flowIdPath = matchedData.flowIdPath?.split("|")[0];
        const [genericId, instanceId] = flowIdPath.split("/");
        const genericName = genericIdToNameMap?.get(genericId) || "Unknown";

        dispatch(
            openInstance({
                genericId: parseInt(genericId),
                genericName,
                instanceIds: [parseInt(instanceId)],
            })
        );
        navigate(`${basePathName}/flow`);
        dispatch(setActivePage("DocuChain"));
        // const navFlowElement = document.getElementById("nav_Flow");
        // console.log(navFlowElement, "SSS");
        // if (navFlowElement) {
        //     navFlowElement.click();
        // } else {
        //     console.warn("Element #nav_Flow not found in DOM");
        // }
    };

    const changeOpenDialogFlag = (value) => {
        setShowAlertDialog(value);
        setAlertDialogMessage("");
    };
    const closeInstance = () => {
        setShowAddInstanceModal(false);
        setShowDocumentModal(true);
    };
    const addNewInstance = (name, tags, branchId, branchName) => {
        setLoading(true);
        setShowAddInstanceModal(false);
        let encodedFamilyTags = encodeURIComponent(tags);
        http.sendRestRequest(
            `documentChain/instance?genericId=${
                selectedRow.id
            }&instanceName=${encodeURIComponent(
                name
            )}&familyTags=${encodedFamilyTags}&branchId=${branchId}&branchName=${branchName}`,
            "POST"
        )
            .then(() => {
                setSnackbarMessage("Instance created successfully!");
                setShowSnackbar(true);
                http.sendRestRequest(
                    `documentChain/instance/list?genericId=${selectedRow.id}`
                )
                    .then((instanceData) => {
                        setLoading(false);
                        const prevInstances = [...InstanceList].map(
                            (instance) => instance.instanceId
                        );
                        dispatch(setInstanceList(instanceData));

                        const newInstance = instanceData.filter(
                            (instance) =>
                                !prevInstances.includes(instance.instanceId)
                        );
                        setSelectedInstance(newInstance[0]);
                        setShowDocumentModal(true);
                    })
                    .catch((error) => {
                        setLoading(false);
                        setShowAlertDialog(true);
                        setAlertDialogMessage(error.error);
                    });
                fetchLatestFlowsData();
            })
            .catch((error) => {
                setLoading(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
            });
    };
    const openAddInstanceModal = () => {
        setShowAddInstanceModal(true);
        // setShowDocumentModal(false);
    };
    const handleOpenAttachToFlow = (row) => {
        const matchedData = data.find((item) => item.id === row[0]);
        if (!matchedData) return;
        const workspaceIdSegments = matchedData.workspaceIdLocation.split("|");
        const lastSegment = workspaceIdSegments[0]; // Extract the first part before '|'
        const folderIdArray = lastSegment.split("/");
        const spaceIdNew = parseInt(folderIdArray[1]);
        const folderIds =
            folderIdArray.length > 2 ? parseInt(folderIdArray[2], 10) : 0;
        // if (matchedData?.flowIdPath) {
        const documentId = matchedData.documentId;
        if (documentId) {
            setDocumentIdState(documentId);
            setShowDocumentModal(true);
        }
        // if (documentId) {
        //     http.sendRestRequest(
        //         "documentLogs/list?pageNo=1&pageSize=50&startDate=&endDate=",
        //         "GET",
        //         [parseInt(documentId)]
        //     )
        //         .then((result) => {
        //             const workspaceId = folderIdArray[0];
        //             const spaceId = spaceIdNew;
        //             let folderId;
        //             if (folderIdArray.length === 2) {
        //                 folderId = 0;
        //             } else {
        //                 folderId = folderIds;
        //             }

        //             const value = {
        //                 workSpaceId: workspaceId,
        //                 spaceId,
        //                 folderId: folderId === spaceId ? "0" : folderId,
        //                 documentId,
        //             };
        //             setValues(value);

        //             const flowInfo = data[0]?.split("/");
        //             if (flowInfo?.length > 1)
        //                 if (flowInfo[0] === "Flow") {
        //                     flowName = flowInfo[1];
        //                 } else if (flowInfo[0] === "Instance") {
        //                     const arr = flowInfo[1].split("/");
        //                     flowName = arr[0];
        //                     instanceName = arr[1];
        //                 } else if (flowInfo[0] === "Step") {
        //                     const arr = flowInfo[1].split("/");
        //                     flowName = arr[0];
        //                     instanceName = arr[1];
        //                     branchName = arr.length === 4 && arr[2];
        //                     stepName = arr[arr.length - 1];
        //                 }
        //             setFlowInfo({
        //                 flowName,
        //                 instanceName,
        //                 branchName,
        //                 stepName,
        //             });
        //             setShowDocumentModal(true);
        //         })
        //         .catch((err) => {
        //             console.error(err);
        //             dispatch(
        //                 setSnackbarAction({
        //                     snackbarOpen: true,
        //                     snackbarType: "error",
        //                     snackbarMessage:
        //                         err?.error || "Something went wrong!",
        //                 })
        //             );
        //         });
        // }
        // }
    };

    const handleDateRangeSelection = (
        startDate,
        endDate,
        isToday,
        timeRange
    ) => {
        if (startDate === "All" && endDate === "All")
            dispatch(
                setInfoFeedsTimeFiltersAction({
                    startDate: "",
                    endDate: "",
                    timeRange: "All",
                })
            );
        else if (isToday) {
            const currentTime = moment().format("HH:mm:ss");
            const updateStartDate = startDate.split(" ")[0] + " 00:00:00";
            const updateEndDate = endDate.split(" ")[0] + " " + currentTime;
            dispatch(
                setInfoFeedsTimeFiltersAction({
                    startDate: getUTCDateFromLocal(updateStartDate),
                    endDate: getUTCDateFromLocal(updateEndDate),
                    timeRange: timeRange,
                })
            );
        } else {
            const currentTime = moment().format("HH:mm:ss");
            const updateStartDate = startDate.split(" ")[0] + " " + currentTime;
            const updateEndDate = endDate.split(" ")[0] + " " + currentTime;
            dispatch(
                setInfoFeedsTimeFiltersAction({
                    startDate: getUTCDateFromLocal(updateStartDate),
                    endDate: getUTCDateFromLocal(updateEndDate),
                    timeRange: timeRange,
                })
            );
        }
    };

    return (
        <>
            {(pathname === `${basePathName}/info-feeds` ||
                pathname === `${basePathName}/info-feeds/`) && (
                <div className="table-header" title="Information Feed">
                    Information Feed
                </div>
            )}
            {selectedRows.length === 1 && (
                <div className="table-actions">
                    <ButtonComponent
                        BtnHandler={() => handleOpenInWS(selectedRows)}
                        icon={"./images/design/folder-icon.svg"}
                        text={"Open in Workspace"}
                        type={"secondary"}
                    />
                    <ButtonComponent
                        BtnHandler={() => handleOpenInFlow(selectedRows)}
                        icon={"./images/design/flow-icon.svg"}
                        text={"Open in Flow"}
                        type={"secondary"}
                    />
                    <ButtonComponent
                        BtnHandler={() => handleOpenAttachToFlow(selectedRows)}
                        icon={"./images/design/paperclip.svg"}
                        text={"Attach To Flow"}
                        type={"secondary"}
                    />
                </div>
            )}
            <TableActions
                selectedRows={selectedRows}
                infoFeedData={infoFeedData}
                setFilterBy={setFilterBy}
                filterBy={filterBy}
                handleDateRangeSelection={handleDateRangeSelection}
                hideHeader={hideHeader}
                pathname={pathname}
                setData={setData}
                originalData={originalData}
                localTimeFilters={localTimeFilters}
                basePathName={basePathName}
            />
            <div
                style={{
                    height: `${
                        !hideHeader
                            ? "calc(100% - 6.75rem)"
                            : selectedRows.length === 1
                            ? "calc(100% - 0.5rem)"
                            : "calc(100% + 3rem)"
                    }`,
                }}
            >
                <Card>
                    {infoFeedData.totalEmails === undefined ? (
                        <Loading />
                    ) : data.length === 0 ? (
                        <EmptyHomeScreen
                            subTitle={"Nothing here yet"}
                            image={"./images/home_empty_icon.svg"}
                            style={{ width: "100px", height: "100px" }}
                        />
                    ) : (
                        <div className="table-container">
                            <div
                                className="table-data"
                                onScroll={handleScroll}
                                ref={infoTable}
                            >
                                <div className="table-wrapper">
                                    <div className="header-row">
                                        {columns.map((column) => (
                                            <div
                                                key={column.key}
                                                onClick={() =>
                                                    sortData(column.key)
                                                }
                                                title={column.label}
                                                className="header-cell"
                                            >
                                                {column.label}
                                                {sortConfig.key ===
                                                    column.key && (
                                                    <div>
                                                        {sortConfig.direction ===
                                                        "asc" ? (
                                                            <ArrowDropUpOutlinedIcon />
                                                        ) : (
                                                            <ArrowDropDownOutlinedIcon />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="table-body">
                                        {data.map((row) => (
                                            <div
                                                key={row.id}
                                                className={`${
                                                    selectedRows.includes(
                                                        row.id
                                                    )
                                                        ? "body-row selected"
                                                        : "body-row"
                                                }     ${
                                                    row.isRead ? "" : "unread"
                                                } `}
                                                onClick={() =>
                                                    toggleRowSelection(row)
                                                }
                                                onDoubleClick={(e) => {
                                                    handleRowDoubleClick(
                                                        row.documentId
                                                    );
                                                    e.stopPropagation();
                                                }}
                                            >
                                                {columns.map(
                                                    (column, index) => (
                                                        <div
                                                            className="body-cell"
                                                            key={column.key}
                                                        >
                                                            {(selectedRows.includes(
                                                                row.id
                                                            ) ||
                                                                !row.isRead) &&
                                                                index === 0 && (
                                                                    <div className="marker"></div>
                                                                )}
                                                            {column.key ===
                                                            "documentName" ? (
                                                                <div
                                                                    className={`textEllipsis ${
                                                                        row.isRead
                                                                            ? ""
                                                                            : "bold"
                                                                    }`}
                                                                    title={
                                                                        row[
                                                                            column
                                                                                .key
                                                                        ]
                                                                    }
                                                                >
                                                                    <span>
                                                                        <img
                                                                            src={getIconUrls(
                                                                                "file",
                                                                                row[
                                                                                    column
                                                                                        .key
                                                                                ]
                                                                            )}
                                                                            alt=""
                                                                            style={{
                                                                                height: "20px",
                                                                                width: "20px",
                                                                                paddingRight:
                                                                                    "4px",
                                                                            }}
                                                                        />
                                                                    </span>
                                                                    {row[
                                                                        column
                                                                            .key
                                                                    ] || ""}
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className={`textEllipsis ${
                                                                        row.isRead
                                                                            ? ""
                                                                            : "bold"
                                                                    }`}
                                                                    title={
                                                                        column.key ===
                                                                        "createdOn"
                                                                            ? getLocalDateFromUTC(
                                                                                  row[
                                                                                      column
                                                                                          .key
                                                                                  ]
                                                                              )
                                                                            : row[
                                                                                  column
                                                                                      .key
                                                                              ]
                                                                    }
                                                                >
                                                                    {column.label ===
                                                                        "Location" ||
                                                                    column.label ===
                                                                        "Flow"
                                                                        ? row[
                                                                              column
                                                                                  .key
                                                                          ] ||
                                                                          ""
                                                                        : column.key ===
                                                                          "createdOn"
                                                                        ? getLocalDateFromUTC(
                                                                              row[
                                                                                  column
                                                                                      .key
                                                                              ]
                                                                          )
                                                                        : row[
                                                                              column
                                                                                  .key
                                                                          ] ||
                                                                          ""}
                                                                </div>
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="table-pagination">
                                <TablePagination
                                    component="div"
                                    count={totalRows}
                                    page={currentPage - 1}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                />
                            </div>
                        </div>
                    )}
                </Card>
            </div>

            <Loader show={loading} />
            <DocumentViewerModal
                showHeader={true}
                footer="false"
                show={documentModalShow}
                size="lg"
                onHide={handleDocumentModalClose}
                title={currentShowFileName}
                modalBodyClass="documentViewModal"
                onclick={() => {}}
                submitButtonText="Close"
                headerTitle={currentShowFileName}
                documentId={currentShowFileLocation.documentId}
                fileSource={currentShowFileLocation}
            >
                {documentModalShow ? renderViewDocumentModal() : null}
            </DocumentViewerModal>
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
            ></AlertDialog>
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
                setOpen={changeOpenDialogFlag}
            ></AlertDialog>
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
            {showAddInstanceModal ? (
                <AddInstance
                    selectedGenericInstances={InstanceList}
                    selectedInstance={selectedInstance}
                    showAddInstanceModal={showAddInstanceModal}
                    closeModal={closeInstance}
                    currentFamilyTags={[]}
                    currentInstanceName={""}
                    heading={"Add New Instance"}
                    branchsId={"1"}
                    branchsName={"1"}
                    disabled={false}
                    editNewInstance={""}
                    addNewInstance={addNewInstance}
                ></AddInstance>
            ) : null}
            {showDocumentModal ? (
                <AddDocuments
                    documentId={documentIdState}
                    setSelectedRow={setSelectedRow}
                    openAddInstanceModal={openAddInstanceModal}
                    values={values}
                    folderId={values?.folderId}
                    showDocumentModal={showDocumentModal}
                    closeModal={() => {
                        setShowDocumentModal(false);
                        fetchInfoFeedData();
                    }}
                    // flowInfo={flowInfo}
                />
            ) : null}
        </>
    );
};

export default InfoFeedTable;
