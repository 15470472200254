import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../style.css";
import "./style.css";
import DocList from "./docList";
import AddDocumentChainModal from "./add-docu-chain-modal";
import http from "../../../../../../bae-client/HTTP";
import {
    fetchDocumentChainList,
    setSelectedFlowIds,
    setSelectedExceptionIds,
    setAllInstanceWithFilter,
    setSelectedTimeFilterGlobal,
} from "../../../../../../actions/DocumentChainActions";
import {
    fetchFilterData,
    setGenericIdToDisplayNameMap,
} from "../../../../../../actions/DashboardActions";
import { setSelectedNextDoc } from "../../../../../../actions/Rippleaction";
import Loader from "../../../../../stylized/Loader";
import EditDocumentChainModal from "./edit-docu-chain-modal";
import GenericContainer from "./empty-container/GenericContainer";
import CustomSnackbar from "../../../../../stylized/CustomSnackbar/CustomSnackbar";
import SplitView from "./split-view";
import AlertDialog from "../../../../../stylized/AlertDialog";
import config from "../../../../../../config";
import SearchField from "../../../../../stylized/search-field";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import InstanceListView from "./instance-list";
import { openInstance } from "../../../../../../actions/DashboardActions";
import { setActivePage, setHomeRedirection } from "../../../../../../actions";
import CustomSearchDropdown from "../../../../../stylized/CustomDropDown/index";
import CustomDateDropdown from "../../../../../stylized/CustomDateDropdown";
import { makeStyles } from "@material-ui/core/styles";
import RecentActivityManager from "../../../../../../bae-client/RecentActivityManager";
import { setSnackbarAction } from "../../../../../../services/services/app";

const getDefaultTime = () => {
    const today = new Date();
    const weekBeforeDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 7
    );
    let ddEndDate = today.getDate();
    let mmEndDate = today.getMonth() + 1; //January is 0!
    let yyyyEndDate = today.getFullYear();

    let ddStartDate = weekBeforeDate.getDate();
    let mmStartDate = weekBeforeDate.getMonth() + 1; //January is 0!
    let yyyyStartDate = weekBeforeDate.getFullYear();

    if (ddEndDate < 10) {
        ddEndDate = "0" + ddEndDate;
    }
    if (mmEndDate < 10) {
        mmEndDate = "0" + mmEndDate;
    }
    if (ddStartDate < 10) {
        ddStartDate = "0" + ddStartDate;
    }
    if (mmStartDate < 10) {
        mmStartDate = "0" + mmStartDate;
    }
    const endDate =
        yyyyEndDate + "-" + mmEndDate + "-" + ddEndDate + " 23:59:59.999";
    const startDate =
        yyyyStartDate + "-" + mmStartDate + "-" + ddStartDate + " 00:00:00.000";
    return {
        startDate,
        endDate,
    };
};
const useStyles = makeStyles({
    root: {
        // maxWidth: "9rem",
        // width: "100%",
        // background: "white !important",
        // borderRadius: "6px !important",
        // border: "none !important",
        maxWidth: "10rem",
        borderRadius: "6px !important",
        display: "inline-flex",
        flexDirection: "column",
        position: "relative",
        minWidth: "0px",
        padding: "0px",
        // margin: "8px",
        border: "0px",
        verticalAlign: "top",
        width: "100%",
    },
});

const DocuChainList = ({
    documentChainList,
    selectedRow,
    setSelectedRow,
    setShowDocumentChain,
    setEditGeneric,
    sortData,
}) => {
    const classes = useStyles();
    const isReadOnlyUser = sessionStorage.getItem("isReadOnlyUser") === "true";
    const DashboardDCFilterData = useSelector(
        (state) => state.DashboardDCFilterData || []
    );
    const selectedFlowIds = useSelector((state) => state.selectedFlowIds);
    const selectedExceptionIds = useSelector(
        (state) => state.selectedExceptionIds
    );
    const selectedTimeFilterGlobal = useSelector(
        (state) => state.selectedTimeFilterGlobal
    );
    const [selectedNextdocuments, setSelectedNextdocuments] = useState([]);
    const workspaceList = useSelector((state) => state.workspaceList);
    const dispatch = useDispatch();
    const [dateEr, setDateEr] = React.useState(false);
    const [dateErr, setDateErr] = useState(false);
    const [showSnackbar, setShowSnackbar] = React.useState(false);
    const [reloadResult, setReloadResult] = useState(false);
    const [WorkspaceId, setWorkspaceId] = useState(null);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [showLoader, setShowLoader] = React.useState(false);
    const [showAddDocumentChainModal, setShowAddDocumentChainModal] =
        React.useState(false);
    const [showEditDocumentChainModal, setShowEditDocumentChainModal] =
        React.useState(false);
    const [libraryView, setLibraryView] = React.useState("list");
    const [errorAlert, setErrorAlert] = useState({
        showAlertView: false,
        isAlert: false,
        alertDialogTitle: "",
        alertDialogMessage: "",
    });
    const [genericList, setGenericList] = useState([]);
    const [activeList, setActiveList] = useState("Flows");
    const [searchText, setSearchText] = useState("");
    const [allInstances, setAllInstances] = useState([]);
    const allInstanceWithFilter = useSelector(
        (state) => state.allInstanceWithFilter
    );
    const [allFlows, setAllFlows] = useState([]);
    const selectedFlows = useSelector((state) => state.selectedFlows);
    const selectedNextDoc = useSelector((state) => state.selectedNextDoc);
    const exceptionProperties = [
        { text: "DELAYED", value: "DELAYED", id: "DELAYED", index: 0 },
        { text: "SKIPPED", value: "SKIPPED", id: "SKIPPED", index: 1 },
        {
            text: "KEY MISSING",
            value: "KEY MISSING",
            id: "KEY MISSING",
            index: 2,
        },
        {
            text: "KEY MISMATCH",
            value: "KEY MISMATCH",
            id: "KEY MISMATCH",
            index: 3,
        },
        {
            text: "PROACTIVE ALERT",
            value: "PROACTIVE ALERT",
            id: "PROACTIVE ALERT",
            index: 4,
        },
        {
            text: "DATE CHANGED",
            value: "DATE CHANGED",
            id: "DATE CHANGED",
            index: 5,
        },
        { text: "OTHERS", value: "OTHERS", id: "OTHERS", index: 6 },
    ];
    const [allExceptions, setAllExceptions] = useState(exceptionProperties);
    const [selectedExceptionsLocal, setSelectedExceptionsLocal] = useState([]);
    const selectedExceptions = useSelector((state) => state.selectedExceptions);
    const [selectedTime, setSelectedTime] = useState(getDefaultTime());
    const [flowIds, setFlowIds] = useState([]);
    const [selectedTimeFilterLocal, setSelectedTimeFilterLocal] = useState({
        startDate: "",
        endDate: "",
        selectedTimeType: "",
    });
    const [selectedTimeFilter, setSelectedTimeFilter] = useState([]);
    const homeRedirection = useSelector((state) => state.homeRedirection);

    useEffect(() => {
        if (homeRedirection?.flag) {
            if (homeRedirection.type === "LibraryTab")
                setShowAddDocumentChainModal(false);
            else setShowAddDocumentChainModal(true);
            dispatch(setHomeRedirection({ flag: false, type: "" }));
        }
    }, [homeRedirection?.flag]);

    const change = (data) => {
        if (data && data.selectedTimeType === "Custom") {
            if (!data.startDate || !data.endDate) return;
            setSelectedTime({
                startDate: data.startDate + " 00:00:00.000",
                endDate: data.endDate + " 23:59:59.999",
            });
        } else if (data && data.selectedTimeType === "All") {
            setSelectedTime({
                startDate: "all",
                endDate: "all",
            });
        } else if (data && data.startDate && data.endDate) {
            setSelectedTime({
                startDate: data.startDate + " 00:00:00.000",
                endDate: data.endDate + " 23:59:59.999",
            });
        }
    };
    const viewButtonStyle = {
        width: "141px",
        height: "36px",
        padding: "6px 16px",
        gap: "8px",
        marginLeft: "0px",
        borderRadius: "4px ",
        backgroundColor: "#0B5CD7",
        color: "white",
        cursor: "pointer",
        opacity: "0.8", // Assuming you meant a slight transparency, adjust as needed
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    const instanceOperations = (operation, data) => {
        if (operation === "Open Instance") openInstanceHandler(data);
        if (operation === "Delete Instance") {
            setShowLoader(true);
            let url = `documentChain/instance?genericId=${data.genericId}&instanceId=${data.instanceId}`;
            http.sendRestRequest(url, "DELETE")
                .then((response) => {
                    const genericIds = flowIds.map((dc) => dc.id);
                    const exceptions = selectedExceptionsLocal.map(
                        (ele) => ele.id
                    );
                    const payload = {
                        genericIds: genericIds,
                        startDate: selectedTimeFilterLocal.startDate
                            ? selectedTimeFilterLocal.startDate +
                              " 00:00:00.000"
                            : "",
                        endDate: selectedTimeFilterLocal.endDate
                            ? selectedTimeFilterLocal.endDate + " 23:59:59.999"
                            : "",
                        exceptions: exceptions,
                        nextSteps: selectedNextdocuments.map((ele) => ele.text),
                    };
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: `Instance Deleted successfully!`,
                            severity: "success",
                        })
                    );
                    http.sendRestRequest(
                        "documentChain/generic/instanceList",
                        "POST",
                        payload
                    )
                        .then((resp) => {
                            setAllInstances(resp);
                            dispatch(setAllInstanceWithFilter(resp));
                            // setShowLoader(false);
                        })
                        .catch((error) => {
                            // setShowLoader(false);
                        });
                    setShowLoader(false);
                })
                .catch((error) => {
                    setShowLoader(false);
                });
        }
    };
    const setDateInfo = (info) => {
        setDateEr(info);
    };
    const openInstanceHandler = (data) => {
        const libData = {
            genericId: data.genericId,
            genericName: data.genericName,
            instanceIds: [data.instanceId],
        };
        dispatch(setActivePage(""));
        setShowLoader(true);
        setTimeout(() => {
            setShowLoader(false);
            dispatch(setActivePage("DocuChain"));
        }, 500);
        // dispatch(setActivePage("DocuChain"));
        dispatch(openInstance(libData));
    };

    const newArray = DashboardDCFilterData.filter((item) =>
        selectedFlows.includes(item.genericRegistry.genericDisplayName)
    );

    let allStepNames = newArray.reduce((accumulator, currentObject) => {
        return accumulator.concat(currentObject.stepNames);
    }, []);
    allStepNames = allStepNames.map((ele, index) => {
        return { text: ele, value: ele, index };
    });
    let genericNames = [];
    flowIds.forEach((obj) => {
        genericNames.push(obj.value);
    });
    const newNextStepValues = DashboardDCFilterData.filter((item) =>
        genericNames.includes(item.genericRegistry.genericDisplayName)
    )
        .map((item) => item.stepNames)
        .flat();
    const formattedData = newNextStepValues.map((item, index) => ({
        text: item,
        value: item,
        index: index,
        id: index,
    }));

    function removeDuplicates(arr) {
        let unique = arr.reduce(function (acc, curr) {
            if (!acc.includes(curr)) acc.push(curr);
            return acc;
        }, []);
        return unique;
    }

    const onSearchHandler = (data) => {
        const searchText = data.target.value;
        setSearchText(searchText);
        if (activeList !== "Flows") {
            const newInstanceList = allInstanceWithFilter.filter(
                (instance) =>
                    instance.instanceName
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) > -1
            );
            setAllInstances(newInstanceList);
        } else {
            const newGenericList = documentChainList.filter(
                (flow) =>
                    flow.genericDisplayName
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) > -1
            );
            setGenericList(newGenericList);
        }
    };

    useEffect(() => {
        if (documentChainList.length) {
            setGenericList(documentChainList);
            setAllFlows(
                documentChainList
                    .map((flow, index) => {
                        return {
                            value: flow.genericDisplayName,
                            text: flow.genericDisplayName,
                            id: flow.genericId,
                            index,
                        };
                    })
                    .sort()
            );
        }
    }, [documentChainList]);
    useEffect(() => {
        if (selectedFlowIds.length === 0) {
            dispatch(setSelectedNextDoc([]));
        }
    }, [selectedFlows]);
    useEffect(() => {
        setFlowIds(selectedFlowIds);
        setSelectedExceptionsLocal(selectedExceptionIds);
        setSelectedTimeFilterLocal(selectedTimeFilterGlobal);
        setSelectedNextdocuments(selectedNextDoc);
    }, []);
    useEffect(() => {
        if (activeList !== "Flows") {
            let flowId = flowIds.map((item) => item.id);
            setFlowIds(allFlows.filter((item) => flowId.includes(item.id)));
            dispatch(
                setSelectedFlowIds(
                    allFlows.filter((item) => flowId.includes(item.id))
                )
            );
        }
    }, [activeList]);
    useEffect(() => {
        if (
            // (selectedExceptions.length ||
            //     selectedFlows.length ||
            //     selectedNextDoc.length) &&
            selectedFlows.length &&
            selectedTime.startDate &&
            selectedTime.endDate &&
            documentChainList.length &&
            !reloadResult &&
            activeList === "Instances"
        ) {
            const genericIds = flowIds.map((dc) => dc.id);
            const exceptions = selectedExceptionsLocal.map((ele) => ele.id);
            const payload = {
                genericIds: genericIds,
                // startDate:
                //     selectedTime.startDate === "all"
                //         ? ""
                //         : selectedTime.startDate, //"2023-01-01 00:00:00.000",
                startDate: selectedTimeFilterLocal.startDate
                    ? selectedTimeFilterLocal.startDate + " 00:00:00.000"
                    : "",
                // endDate:
                //     selectedTime.endDate === "all" ? "" : selectedTime.endDate, //"2023-06-30 23:59:59.999",
                endDate: selectedTimeFilterLocal.endDate
                    ? selectedTimeFilterLocal.endDate + " 23:59:59.999"
                    : "",
                exceptions: exceptions,
                nextSteps: selectedNextdocuments.map((ele) => ele.text),
            };
            setShowLoader(true);
            setReloadResult(true);
            http.sendRestRequest(
                "documentChain/generic/instanceList",
                "POST",
                payload
            )
                .then((response) => {
                    let newFilteredValue;
                    if (searchText !== "") {
                        newFilteredValue = response.filter(
                            (instance) =>
                                instance.instanceName
                                    .toLowerCase()
                                    .indexOf(searchText.toLowerCase()) > -1
                        );
                        setAllInstances([]);
                        setAllInstances(newFilteredValue);
                        dispatch(setAllInstanceWithFilter(newFilteredValue));
                    } else {
                        setAllInstances(response);
                        dispatch(setAllInstanceWithFilter(response));
                    }
                    setShowLoader(false);
                })
                .catch((error) => {
                    setShowLoader(false);
                });
        }
    }, [
        selectedFlows,
        selectedExceptions,
        selectedNextDoc,
        selectedTime,
        reloadResult,
        documentChainList,
        activeList,
    ]);

    useEffect(() => {
        if (allInstanceWithFilter.length)
            setAllInstances(allInstanceWithFilter);
    }, [allInstanceWithFilter]);

    const newDocListClickHandler = () => {
        setShowAddDocumentChainModal(true);
    };

    const closeModal = () => {
        setShowAddDocumentChainModal(false);
        setShowEditDocumentChainModal(false);
    };
    const openAlertView = (title, body) => {
        errorAlert["showAlertView"] = true;
        errorAlert["alertDialogMessage"] = body;
        errorAlert["alertDialogTitle"] = title;
        setErrorAlert({ ...errorAlert });
    };
    const changeOpenDialogFlag = (flag) => {
        errorAlert["showAlertView"] = flag;
        setErrorAlert({ ...errorAlert });
    };
    const fetchLatestFlowsData = () => {
        http.sendRestRequest("documentChain/objects")
            .then((response) => {
                dispatch(fetchFilterData(response));
            })
            .catch((err) => console.log(err));
    };

    const addNewDocumentChain = (genericDisplayName, documentChainName) => {
        setShowAddDocumentChainModal(false);
        setShowLoader(true);
        http.sendRestRequest(
            `documentChain/generic?genericName=${encodeURIComponent(
                documentChainName
            )}&genericDisplayName=${encodeURIComponent(genericDisplayName)}`,
            "POST"
        )
            .then((response) => {
                let recentActivity = new RecentActivityManager();
                let dataObj = {
                    objectType: "FLOW",
                    objectId: response.genericId,
                    description: `<span>Created Flow <b>${response.genericDisplayName}</b></span>`,
                    path: "",
                };
                recentActivity
                    .createRecentActivity(dataObj)
                    .then((recentRes) => {})
                    .catch((err) => console.log(err.error));
                setSnackbarMessage("Flow added successfully!");
                fetchLatestFlowsData();
                setShowSnackbar(true);
                dispatch(fetchDocumentChainList());
                setShowLoader(false);
            })
            .catch((err) => {
                openAlertView("Operation Failed", err.error);
                setShowLoader(false);
            });
    };
    // const addNewDocumentChain = (
    //     genericDisplayName,
    //     documentChainName,
    //     newSpacename
    // ) => {
    //     const workspaceNameToFind = "Flow";
    //     const workspace1 = workspaceList.find(
    //         (workspace) => workspace.workspaceName === workspaceNameToFind
    //     );

    //     if (workspace1) {
    //         const workspaceId = workspace1.workspaceId;
    //         setWorkspaceId(workspaceId);
    //         let data = { spaceName: genericDisplayName.trim() };
    //         const spaceManager = new SpaceManager();
    //         setShowAddDocumentChainModal(false);
    //         setShowLoader(true);

    //         spaceManager
    //             .addNewSpace("", workspaceId, data)
    //             .then(() => {
    //                 http.sendRestRequest(
    //                     `documentChain/generic?genericName=${encodeURIComponent(
    //                         documentChainName
    //                     )}&genericDisplayName=${encodeURIComponent(
    //                         genericDisplayName
    //                     )}`,
    //                     "POST"
    //                 )
    //                     .then((response) => {
    //                         setSnackbarMessage("Flow added successfully!");
    //                         setShowSnackbar(true);
    //                         dispatch(fetchDocumentChainList());
    //                         setShowLoader(false);
    //                     })
    //                     .catch((err) => {
    //                         openAlertView("Operation Failed", err.error);
    //                         setShowLoader(false);
    //                     });
    //             })
    //             .catch((err) => {
    //                 console.error("Error from addSpace:", err);
    //             });
    //     }
    // };
    const editDocumentChainHandler = (displayName) => {
        setShowEditDocumentChainModal(false);
        setShowLoader(true);
        let url = config.editable(sessionStorage.user)
            ? `documentChain/generic?genericId=${selectedRow.genericId}&genericName=${selectedRow.docuChain}&genericDisplayName=${displayName}`
            : `documentChain/generic?genericId=${selectedRow.genericId}&genericDisplayName=${displayName}`;

        http.sendRestRequest(url, "PUT")
            .then((response) => {
                setSelectedRow(null);
                setSnackbarMessage("Selected Flow updated successfully!");
                fetchLatestFlowsData();
                setShowSnackbar(true);
                dispatch(fetchDocumentChainList());
                setShowLoader(false);
            })
            .catch((error) => {
                console.log(error);
                openAlertView("", error.error);
                setShowLoader(false);
            });
    };

    const editDocumentChain = () => {
        setShowEditDocumentChainModal(true);
    };
    const handleSort = (columnName, orderBy) => {
        sortData(columnName, orderBy);
    };
    const handleFlowCheck = (index, values) => {
        setFlowIds(values);
        setSelectedNextdocuments([]);
    };
    const setSelectedTimeFilterData = (dateObj) => {
        setSelectedTimeFilterLocal({
            startDate:
                dateObj.startDate !== "NaN-NaN-NaN" ? dateObj.startDate : "",
            endDate: dateObj.endDate !== "NaN-NaN-NaN" ? dateObj.endDate : "",
            selectedTimeType: dateObj.selectedTimeType,
        });

        if (dateObj.selectedTimeType === "Custom") {
            setSelectedTimeFilter([
                dateObj.startDate !== "NaN-NaN-NaN" ? dateObj.startDate : "",
                dateObj.endDate !== "NaN-NaN-NaN" ? dateObj.endDate : "",
            ]);
        } else {
            setSelectedTimeFilter([dateObj.selectedTimeType]);
        }
    };
    const handleExceptionCheck = (index, values) => {
        setSelectedExceptionsLocal(values);
    };
    const handleNextDocumentCheck = (index, values) => {
        setSelectedNextdocuments(values);
    };
    const handleViewClick = () => {
        setReloadResult(false);
        dispatch(setSelectedFlowIds(flowIds));
        dispatch(setSelectedExceptionIds(selectedExceptionsLocal));
        dispatch(setSelectedTimeFilterGlobal(selectedTimeFilterLocal));
        dispatch(setSelectedNextDoc(selectedNextdocuments));
    };
    return (
        <div className="docChainContainer" style={{ height: "85vh" }}>
            <div className="headContainer">
                <div className="doCenter docuListHeader">
                    <div>
                        <ToggleButtonGroup
                            color="primary"
                            value={activeList}
                            exclusive
                            onChange={(e, active) => {
                                if (active) setActiveList(active);
                                setSearchText("");
                                setAllInstances(allInstanceWithFilter);
                                setGenericList(documentChainList);
                            }}
                            aria-label="Platform"
                            style={{ fontFamily: "Montserrat" }}
                        >
                            <ToggleButton
                                title="Flows"
                                value="Flows"
                                id="flowTab"
                                style={{ fontFamily: "Montserrat" }}
                            >
                                Flows
                            </ToggleButton>
                            <ToggleButton
                                title="Instances"
                                value="Instances"
                                id="instanceTab"
                                style={{ fontFamily: "Montserrat" }}
                            >
                                Instances
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    {activeList !== "Flows" ? (
                        <div
                            id="dropdownContainerRow"
                            className="w-100"
                            style={{
                                justifyContent: "flex-start",
                                display: "flex",
                                alignItems: "center",
                                gap: "12px",
                                marginLeft: "12px",
                                minWidth: "84%",
                                // flexBasis: "50%",
                            }}
                        >
                            <CustomSearchDropdown
                                items={allFlows}
                                label={"Flows"}
                                id={"flows-dropdown-btn"}
                                idInfo={"flows"}
                                // customClassName={"rippleDD rippleDDFilterView"}
                                customClassName={classes.root}
                                multiSelect={true}
                                isSearchable={true}
                                isChipView={false}
                                handleSelection={handleFlowCheck}
                                selection={flowIds}
                                customWidth={92}
                            ></CustomSearchDropdown>
                            <CustomDateDropdown
                                customClassName={classes.root}
                                setSelectedTimeFilter={
                                    setSelectedTimeFilterData
                                }
                                err={true}
                                setDateInfo={setDateInfo}
                                selection={selectedTimeFilterGlobal}
                                setDateErr={setDateErr}
                                dateErr={dateErr}
                                customWidth={142}
                                module={"dropDownWidth"}
                                // customClassName={"rippleDD rippleDDFilterView"}
                            ></CustomDateDropdown>

                            <CustomSearchDropdown
                                items={allExceptions}
                                label={"Exceptions"}
                                id={"exceptions-dropdown-btn"}
                                idInfo={"exceptions"}
                                // customClassName={"rippleDD rippleDDFilterView"}
                                multiSelect={true}
                                customClassName={classes.root}
                                isSearchable={true}
                                isChipView={false}
                                handleSelection={handleExceptionCheck}
                                selection={selectedExceptionsLocal}
                                customWidth={127}
                            ></CustomSearchDropdown>
                            <CustomSearchDropdown
                                items={formattedData}
                                label={"Next Document"}
                                id={"next-doc-dropdown-btn"}
                                idInfo={"next_document"}
                                customClassName={classes.root}
                                // customClassName={"rippleDD rippleDDFilterView"}
                                multiSelect={true}
                                isSearchable={true}
                                isChipView={false}
                                handleSelection={handleNextDocumentCheck}
                                selection={selectedNextdocuments}
                                customWidth={127}
                                // selection={documentChainList.filter(flow => {if(selectedFlows.includes(flow.genericDisplayName)) return flow.genericId })}
                            ></CustomSearchDropdown>

                            <div>
                                <SearchField
                                    id={"search-instances2"}
                                    text={searchText}
                                    onChangeHandler={onSearchHandler}
                                ></SearchField>
                            </div>

                            <div id="view_above">
                                <button
                                    className={"btn share modalButton"}
                                    type="button"
                                    id={"viewResults"}
                                    style={{
                                        ...viewButtonStyle,
                                        cursor: dateEr
                                            ? "not-allowed"
                                            : "pointer",
                                        opacity: dateEr ? 0.5 : 1, // Adjust opacity based on dateEr
                                    }}
                                    title="View Result"
                                    value={"View Result"}
                                    onClick={dateEr ? null : handleViewClick}
                                >
                                    View Results
                                </button>
                            </div>
                        </div>
                    ) : null}
                    <div
                        style={{ display: "flex" }}
                        id={
                            activeList !== "Flows"
                                ? "search_instance"
                                : "search_create"
                        }
                    >
                        {activeList === "Flows" ? (
                            <div style={{ display: "flex", width: "65%" }}>
                                <SearchField
                                    id={"search-instances1"}
                                    text={searchText}
                                    onChangeHandler={onSearchHandler}
                                ></SearchField>

                                <button
                                    className={
                                        isReadOnlyUser
                                            ? "btn share modalButton readOnly"
                                            : "btn share modalButton"
                                    }
                                    type="button"
                                    id={"createNew"}
                                    style={{
                                        margin: "0rem",
                                        marginLeft: "10px",
                                        textTransform: "capitalize",
                                    }}
                                    title="Create New"
                                    value={"Create New"}
                                    onClick={
                                        isReadOnlyUser
                                            ? ""
                                            : newDocListClickHandler
                                    }
                                >
                                    Create New
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            {activeList === "Flows" ? (
                <div
                    className="mainContainer"
                    style={{ flexDirection: "column", marginTop: "0px" }}
                >
                    {!genericList.length ? (
                        <div className="docuList invisibleScroller">
                            <GenericContainer
                                list={genericList}
                            ></GenericContainer>
                        </div>
                    ) : (
                        <div
                            className="tableBody"
                            style={{
                                marginTop: "15px",
                                background: "#FFFFFF",
                                boxShadow: "1px 1px 20px 4px rgba(0,0,0,0.1)",
                            }}
                        >
                            {libraryView === "list" ? (
                                <DocList
                                    list={genericList}
                                    editDocumentChain={editDocumentChain}
                                    selectedRow={selectedRow}
                                    setSelectedRow={setSelectedRow}
                                    setShowDocumentChain={setShowDocumentChain}
                                    setEditGeneric={setEditGeneric}
                                    handleSort={handleSort}
                                ></DocList>
                            ) : (
                                <SplitView
                                    genericId={
                                        selectedRow !== null
                                            ? selectedRow.genericId
                                            : -1
                                    }
                                ></SplitView>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <div
                    className="mainContainer"
                    style={{ flexDirection: "column", marginTop: "0px" }}
                >
                    <div
                        className="tableBody"
                        style={{
                            marginTop: "15px",
                            background: "#FFFFFF",
                            boxShadow: "1px 1px 20px 4px rgba(0,0,0,0.1)",
                        }}
                    >
                        <InstanceListView
                            modal="instance"
                            allInstances={allInstances}
                            instanceOperations={instanceOperations}
                            openInstance={openInstanceHandler}
                        ></InstanceListView>
                    </div>
                </div>
            )}
            <AddDocumentChainModal
                showAddDocumentChainModal={showAddDocumentChainModal}
                closeModal={closeModal}
                openModal={newDocListClickHandler}
                list={genericList}
                ids={"div_createheading_flow"}
                heading={"Create New Flow"}
                addNewDocumentChain={addNewDocumentChain}
            ></AddDocumentChainModal>
            <EditDocumentChainModal
                showEditDocumentChainModal={showEditDocumentChainModal}
                documentChainName={
                    selectedRow ? selectedRow.genericDisplayName : ""
                }
                documentChainId={
                    selectedRow ? selectedRow.genericDisplayName : ""
                }
                closeModal={closeModal}
                editDocumentChainHandler={editDocumentChainHandler}
            ></EditDocumentChainModal>
            <Loader show={showLoader}></Loader>
            <CustomSnackbar
                open={showSnackbar}
                setOpen={setShowSnackbar}
                alertMessage={snackbarMessage}
            ></CustomSnackbar>
            <AlertDialog
                open={errorAlert.showAlertView}
                message={errorAlert.alertDialogMessage}
                title={errorAlert.alertDialogTitle}
                isAlert={errorAlert.isAlert}
                setOpen={changeOpenDialogFlag}
            ></AlertDialog>
        </div>
    );
};

export default DocuChainList;
