import React, { useEffect } from "react";
// import Home from "./Home";
import Ripple from "../Ripple";
import config from "../../../../config";
import Loader from "../../../stylized/Loader";
import RippleLog from "../RippleLog";
import { openInstance } from "../../../../actions/DashboardActions";
// import Archive from "../Archive";
import Directory from "../LeftPane/Directory/Directory";
import FlowSettings from "./document-chain/settings";
import Settings from "../Settings";
import Tutorial from "../Tutorial";
import ControlTowerForm from "../ControlTowerForm";
import RFCTVTest from "../RFCTVTest";
import ControlTower from "../ControlTower";
import IntegrationContainer from "./Integration";
import Communicator from "./communicator";
import { DocChain } from "./document-chain";
import http from "../../../../bae-client/HTTP";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Dashboard from "./document-chain/dashboard/dashboard";
import AggrDashboard from "./document-chain/aggr-dashboard/aggrDashboard";
import "./WorkspaceContainer/style.css";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import AlertDialog from "../../../stylized/AlertDialog";
import { setActivePage } from "../../../../actions";
import Compare from "./document-chain/compare";
import None from "../../../helper/None";
import Workspaces from "./Workspace/Workspaces";

import AlertLog from "./Alerts/AlertLog";
import WorkspaceSettings from "../WorkspaceSettings";
import HomePage from "../dashboard/home/HomePage";
import InformationFeed from "../dashboard/info-feeds/InformationFeed";

export default function ContentPane({
    boardList,
    activePage,
    userName,
    closeModal,
    documentMetaData,
    handleLogout,
    setUser,
}) {
    const location = useLocation();
    const dispatch = useDispatch();
    const [error, setError] = React.useState(null);
    const [value, setValue] = React.useState("1");
    const [showLoader, setShowLoader] = React.useState(false);
    const [renameRipple, setRenameRipple] = React.useState("");
    const [showAlertDialog, setShowAlertDialog] = React.useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = React.useState("");
    const [emailConfigurationCuboidId, setEmailConfigurationCuboidId] =
        React.useState("");
    const [
        dashboardReportRuleConfCuboidId,
        setDashboardReportRuleConfCuboidId,
    ] = React.useState("");
    const [copyRuleConfCuboidId, setCopyRuleConfCuboidId] = React.useState("");
    const [compareRuleConfCuboidId, setCompareRuleConfCuboidId] =
        React.useState("");
    const redirectToRipplelog = useSelector(
        (state) => state.redirectToRipplelog
    );
    const showSettingsWs = useSelector((state) => state.showSettingsWs);
    const redirectToTrash = useSelector((state) => state.redirectToTrash);
    const openInstanceReducer = useSelector((state) => state.InstanceData);
    const homeRedirection = useSelector((state) => state.homeRedirection);
    const workspaceList = useSelector((state) => state.workspaceList);
    const basePathName = window.runtimeConfig.instanceName
        ? "/" + window.runtimeConfig.instanceName
        : "";

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };
    const [rippleLog, setRippleLog] = React.useState(false);
    const [trashLog, setTrashLog] = React.useState(false);
    useEffect(() => {
        if (redirectToRipplelog) {
            setRippleLog(true);
        }
    }, [redirectToRipplelog]);
    useEffect(() => {
        if (redirectToTrash) {
            setTrashLog(true);
        }
    }, [redirectToTrash]);
    const getTabs = () => {
        setShowLoader(true);
        if (config.showLeftPaneDocumentChain) {
            // setShowLoader(true);
            http.sendRestRequest("documentChain/ruleConfigurationTblIds")
                .then((response) => {
                    setEmailConfigurationCuboidId(
                        response["Exception Email Rule Configuration"]
                    );
                    setDashboardReportRuleConfCuboidId(
                        response["Dashboard Report Rule Configuration"]
                    );
                    setCopyRuleConfCuboidId(
                        response["Copy Rule Configuration"]
                    );
                    setCompareRuleConfCuboidId(
                        response["Compare Rule Configuration"]
                    );
                })

                .catch((error) => {
                    console.log(error.message);
                    setShowAlertDialog(true);
                    setAlertDialogMessage(error.error);
                    setShowLoader(false);
                });
        }
    };
    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        if (inputValue.length > 5) {
            setError(true);
        } else if (inputValue.length === 5) {
            setError(false);
        } else {
            setError(null);
        }
    };

    useEffect(() => {
        if (openInstanceReducer) {
            setValue("2");
        }
    }, [openInstanceReducer]);
    useEffect(() => {
        if (homeRedirection?.flag) setValue("2");
    }, [homeRedirection?.flag]);
    useEffect(() => {
        const params = new URLSearchParams(window.document.location.search);
        if (params.get("genericId") && params.get("instanceId")) {
            const libData = {
                genericId: params.get("genericId"),
                instanceIds: [parseInt(params.get("instanceId"))],
            };
            dispatch(openInstance(libData));
        }
    }, []);
    useEffect(() => {
        let basePathName = window.runtimeConfig.instanceName
            ? "/" + window.runtimeConfig.instanceName
            : "";

        const pathPageMapping = {
            [`${basePathName}/flow/`]: "DocuChain",
            [`${basePathName}/flow`]: "DocuChain",
            [`${basePathName}/home/`]: "HOME",
            [`${basePathName}/home`]: "HOME",
            [`${basePathName}/alertlog/`]: "AlertLog",
            [`${basePathName}/alertlog`]: "AlertLog",
            [`${basePathName}/chat/`]: "Chat",
            [`${basePathName}/chat`]: "Chat",
            [`${basePathName}/ripple/`]: "Ripple",
            [`${basePathName}/ripple`]: "Ripple",
            [`${basePathName}/apps/`]: "Apps",
            [`${basePathName}/apps`]: "Apps",
            [`${basePathName}/directory/`]: "Directory",
            [`${basePathName}/directory`]: "Directory",
            [`${basePathName}/archive/`]: "Archive",
            [`${basePathName}/archive`]: "Archive",
            [`${basePathName}/settings/`]: "Settings",
            [`${basePathName}/settings`]: "Settings",
            [`${basePathName}/controltower/`]: "Control Tower",
            [`${basePathName}/controltower`]: "Control Tower",
            [`${basePathName}/ripplelog/`]: "Ripple Log",
            [`${basePathName}/ripplelog`]: "Ripple Log",
            [`${basePathName}/trashbin/`]: "Trash Bin",
            [`${basePathName}/trashbin`]: "Trash Bin",
            [`${basePathName}/workspace/`]: "Workspace",
            [`${basePathName}/workspace`]: "Workspace",
            [`${basePathName}/info-feeds/`]: "Info Feed",
            [`${basePathName}/info-feeds`]: "Info Feed",
            [`${basePathName}/`]: "HOME",
        };

        const page = pathPageMapping[location.pathname];
        if (page) {
            dispatch(setActivePage(page));

            if (page === "Workspace") {
                const workspaceId = localStorage.getItem("workspaceId");
                const isRedirection = localStorage.getItem("redirectionObject");
                let workspace = workspaceId
                    ? workspaceList.find(
                          (ws) => ws.workspaceId === parseInt(workspaceId)
                      )
                    : workspaceList.sort((a, b) => {
                          return new Date(b.updatedOn) - new Date(a.updatedOn);
                      })[0];

                // if (workspace && !isRedirection) {
                //     setShowLoader(true);

                //     http.sendRestRequest(
                //         `workspace/spaceList?workspaceId=${workspace.workspaceId}`
                //     )
                //         .then((response) => {
                //             dispatch(setActiveWorkspaceAction(workspace));
                //             dispatch(setSpaceListAction(response));
                //             setShowLoader(false);
                //         })
                //         .catch((err) => {
                //             console.log(err);
                //             setShowLoader(false);
                //         });
                // }
            }
        }
    }, [location.pathname]);

    if (
        activePage !== undefined &&
        activePage !== "" &&
        activePage === "HOME"
    ) {
        return (
            <>
                <HomePage />
            </>
        );
    } else if (
        activePage !== undefined &&
        activePage !== "" &&
        activePage === "Info Feed"
    ) {
        return <InformationFeed></InformationFeed>;
    } else if (
        activePage !== undefined &&
        activePage !== "" &&
        activePage === "Workspace"
    ) {
        if (showSettingsWs === true) {
            return <WorkspaceSettings isInWorkspaces={true} />;
        } else {
            return <Workspaces></Workspaces>;
        }
    } else if (
        activePage !== undefined &&
        activePage !== "" &&
        activePage === "Ripple"
    ) {
        return redirectToRipplelog ? (
            <RippleLog
                username={userName}
                setRippleLog={setRippleLog}
                setRenameRipple={setRenameRipple}
            />
        ) : (
            <Ripple
                username={userName}
                setRippleLog={setRippleLog}
                rippleLog={rippleLog}
                renameRipple={renameRipple}
            />
        );
    } else if (
        activePage !== undefined &&
        activePage !== "" &&
        activePage === "AlertLog" &&
        (location.pathname === basePathName + "/alertlog/" ||
            location.pathname === basePathName + "/alertlog")
    ) {
        return <AlertLog></AlertLog>;
    } else if (
        activePage !== undefined &&
        activePage !== "" &&
        activePage === "Directory"
    ) {
        return <Directory username={userName}></Directory>;
    } else if (
        activePage !== undefined &&
        activePage !== "" &&
        activePage === "Control Tower"
    ) {
        return <ControlTower username={userName}></ControlTower>;
    } else if (
        activePage !== undefined &&
        activePage !== "" &&
        activePage === "Ripple Log"
    ) {
        return (
            <RippleLog
                username={userName}
                setRenameRipple={setRenameRipple}
            ></RippleLog>
        );
    } else if (
        activePage !== undefined &&
        activePage !== "" &&
        location.pathname !== "/alertlog" &&
        (activePage === "Communicator" || activePage === "Chat")
    ) {
        return <Communicator></Communicator>;
    } else if (
        activePage !== undefined &&
        activePage !== "" &&
        (activePage === "Apps" || activePage === "Integrations")
    ) {
        return (
            // <Integrations></Integrations>
            <IntegrationContainer></IntegrationContainer>
        );
    }
    // else if (
    //     activePage !== undefined &&
    //     activePage !== "" &&
    //     activePage === "Archive"
    // ) {
    //     return <Archive username={userName}></Archive>;
    // }
    // else if (
    //     activePage !== undefined &&
    //     activePage !== "" &&
    //     activePage === "Trash Bin"
    // ) {
    //     return <Trash_Bin username={userName}></Trash_Bin>;
    // }
    else if (
        activePage !== undefined &&
        activePage !== "" &&
        activePage === "Settings"
    ) {
        return (
            <Settings
                username={userName}
                handleLogout={handleLogout}
                setUser={setUser}
            ></Settings>
        );
    } else if (
        activePage !== undefined &&
        activePage !== "" &&
        activePage === "Tutorial"
    ) {
        return <Tutorial username={userName}></Tutorial>;
    } else if (
        activePage !== undefined &&
        activePage !== "" &&
        activePage === "Form"
    ) {
        return <ControlTowerForm username={userName}></ControlTowerForm>;
    } else if (
        activePage !== undefined &&
        activePage !== "" &&
        activePage === "Test API"
    ) {
        return <RFCTVTest></RFCTVTest>;
    } else if (
        activePage !== undefined &&
        activePage !== "" &&
        activePage === "DocuChain"
    ) {
        return (
            <>
                <Box
                    sx={{
                        width: "100%",
                        typography: "body1",
                        height: "90vh",
                        paddingTop: "35px",
                    }}
                >
                    <TabContext value={value}>
                        <Box
                            sx={{
                                borderBottom: 1,
                                borderColor: "divider",
                                marginLeft: "24px",
                            }}
                        >
                            <TabList
                                onChange={handleTabChange}
                                aria-label="lab API tabs example"
                            >
                                <Tab
                                    id="tab_dashboard_flow"
                                    data-id="tab_dashboard_flow"
                                    label="Dashboard"
                                    title="Dashboard"
                                    value="1"
                                    sx={{
                                        width: "111px",
                                        height: "52px",
                                        color: "#4B5362",
                                        marginRight: "30px !important",
                                        fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        lineHeight: "29px",
                                        textTransform: "capitalize",
                                    }}
                                />
                                <Tab
                                    id="tab_library_flow"
                                    data-id="tab_library_flow"
                                    label="Library"
                                    title="Library"
                                    value="2"
                                    sx={{
                                        width: "111px",
                                        height: "52px",
                                        color: "#4B5362",
                                        marginRight: "30px !important",

                                        fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        lineHeight: "29px",
                                        textTransform: "capitalize",
                                    }}
                                />
                                <Tab
                                    id="tab_report_flow"
                                    data-id="tab_report_flow"
                                    label="Report"
                                    title="Report"
                                    value="3"
                                    sx={{
                                        width: "111px",
                                        height: "52px",
                                        color: "#4B5362",
                                        marginRight: "30px !important",

                                        fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        lineHeight: "29px",
                                        textTransform: "capitalize",
                                    }}
                                />
                                <Tab
                                    id="tab_compare_flow"
                                    data-id="tab_compare_flow"
                                    label="Compare"
                                    title="Compare"
                                    value="4"
                                    sx={{
                                        width: "111px",
                                        height: "52px",
                                        color: "#4B5362",
                                        marginRight: "30px !important",

                                        fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        lineHeight: "29px",
                                        textTransform: "capitalize",
                                    }}
                                />
                                <Tab
                                    id="tab_settings_flow"
                                    data-id="tab_settings_flow"
                                    label="Settings"
                                    title="Settings"
                                    value="5"
                                    sx={{
                                        width: "111px",
                                        height: "52px",
                                        color: "#4B5362",
                                        marginRight: "30px !important",

                                        fontFamily: "Montserrat",
                                        fontSize: "18px",
                                        lineHeight: "29px",
                                        textTransform: "capitalize",
                                    }}
                                />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Dashboard />
                        </TabPanel>
                        <TabPanel value="2" sx={{ padding: "0px" }}>
                            <DocChain></DocChain>
                        </TabPanel>
                        <TabPanel value="3">
                            <AggrDashboard></AggrDashboard>
                        </TabPanel>
                        <TabPanel
                            value="4"
                            style={{ height: "92vh", padding: "0px" }}
                        >
                            <Compare></Compare>
                        </TabPanel>
                        <TabPanel
                            value="5"
                            sx={{ padding: "0px", height: "calc(100% - 50px)" }}
                            style={{ height: "calc(100% - 50px)" }}
                        >
                            <FlowSettings
                                config={config}
                                emailConfigurationCuboidId={
                                    emailConfigurationCuboidId
                                }
                                dashboardReportRuleConfCuboidId={
                                    dashboardReportRuleConfCuboidId
                                }
                            ></FlowSettings>
                        </TabPanel>
                    </TabContext>
                </Box>
                <AlertDialog
                    open={showAlertDialog}
                    message={alertDialogMessage}
                    title={""}
                    isAlert={false}
                ></AlertDialog>
                <Loader show={showLoader} />
            </>
        );
    } else {
        return <None></None>;
    }
    // } else if (activePage === "HOME") {
    //     return (
    //         <Home
    //             username={userName}
    //         //  alertData={this.state.homeAlerts}
    //         ></Home>
    //     );
    // }

    // else return null;
}
