import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Timeline from "@material-ui/lab/Timeline";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import { useEffect, useState } from "react";
import http from "../../../../../../bae-client/HTTP";
import { setSnackbarAction } from "../../../../../../services/services/app";
import { EmptyHomeScreen } from "../../../../../stylized/EmptyHomeScreen";
import { getParsedDateByDateAndTime } from "../../../../../../bae-client/Utils";
import Loader from "../../../../../stylized/Loader";
import {
    setActivePageAction,
    setActiveWorkspaceAction,
    setSpaceListAction,
    setWorkspaceRedirectionAction,
    workspaceIdToNameReducer,
} from "../../../../../../services/services/app";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../../../../../../actions";
import { openInstance } from "../../../../../../actions/DashboardActions";
import { fetchHierarchyResponse } from "../../../../../../actions/HierarchyActions";
import { useNavigate } from "react-router";
import Loading from "../../../../../stylized/Loading";
import { getStartDateEndDate } from "../../../dashboard/home/helper";

const RecentActivity = ({ timeFilters }) => {
    const useStyles1 = makeStyles({
        recentViewContainer: {
            width: "80%", // Adjusted for responsiveness

            padding: "8px",
            overflow: "hidden", // Prevents overflow
        },

        timelineDate: {
            fontFamily: "Montserrat",
            fontWeight: 400,
            fontSize: "12px",
            lineHeight: "16px",
            letterSpacing: "0px",
            color: "#6b6b6b", // Adjust color if needed
        },
        timelineText: {
            fontFamily: "Montserrat",
            fontSize: "14px",
            lineHeight: "20px",
            letterSpacing: "0px",
        },
        timelineSeparator: {
            width: "1px",
            height: "16.67px",
            backgroundColor: "#ccc",
        },
        textEllipsis: {
            width: "inherit",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    });
    const navigate = useNavigate();
    const classes1 = useStyles1();

    const [recentActivityData, setRecentActivityData] = useState([]);
    const [alertsLoading, setAlertsLoading] = useState(true);
    const dispatch = useDispatch();
    const workspaceList = useSelector((state) => state.workspaceList);

    useEffect(() => {
        if (timeFilters?.startDate !== undefined) {
            const { startDate, endDate } = getStartDateEndDate(timeFilters);
            setAlertsLoading(true);
            let url = `userInteractions/recentActivity?pageNo=1&pageSize=50&startDate=${encodeURIComponent(
                startDate
            )}&endDate=${encodeURIComponent(endDate)}`;

            http.sendRestRequest(url, "GET")
                .then((resp) => {
                    setAlertsLoading(false);
                    setRecentActivityData(resp?.data || []);
                })
                .catch((err) => {
                    setAlertsLoading(false);
                    console.log(err);
                });
        }
    }, [timeFilters]);
    const useStyles = makeStyles({
        timelineContent: {
            cursor: "pointer",
            wordBreak: "break-word",
            fontFamily: "Montserrat",
            fontSize: "12px",
            fontWeight: "500",
            lineHeight: "20px",
            letterSpacing: "-0.006em",
            textAlign: "left",
        },
        timelineDate: {
            width: "100%",
            fontSize: "0.7rem",
            fontFamily: "Montserrat",
            fontWeight: "600",
            lineHeight: "1rem",
            letterSpacing: "-0.006em",
            textAlign: "left",
        },
        timelineDateWrapper: {
            width: "100%",
            top: "0rem",
            flex: "0",
        },
        titleText: {
            fontSize: "0.7rem",
            fontFamily: "sans-serif",
            fontWeight: "600",
            lineHeight: "1rem",
            letterSpacing: "0.00938em",
            userSelect: "none",
        },
    });
    const classes = useStyles();
    const getTextFromHTML = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        return doc.body.textContent || "";
    };

    const handleActivityClick = (item) => {
        let basePathName = window.runtimeConfig.instanceName
            ? "/" + window.runtimeConfig.instanceName
            : "";

        if (item?.objectType === "INSTANCE") {
            setAlertsLoading(true);
            const payload = {
                endDate: "",
                exceptions: [],
                genericIds: [],
                nextSteps: [],
                startDate: "",
            };
            http.sendRestRequest(
                "documentChain/generic/instanceList",
                "POST",
                payload
            )
                .then((resp) => {
                    const resultInstance = resp.find(
                        (instance) => instance.instanceId === item.objectId
                    );
                    if (resultInstance === undefined) {
                        setAlertsLoading(false);
                        dispatch(
                            setSnackbarAction({
                                open: true,
                                message: `Instance Got Deleted!`,
                                severity: "error",
                            })
                        );
                        return;
                    }
                    const libData = {
                        genericId: resultInstance.genericId,
                        genericName: resultInstance.genericName,
                        instanceIds: [item.objectId],
                    };
                    navigate(`${basePathName}/flow`);
                    dispatch(setActivePage("DocuChain"));
                    dispatch(openInstance(libData));
                    setAlertsLoading(false);
                })
                .catch((error) => {
                    setAlertsLoading(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: error.error,
                            severity: "error",
                        })
                    );
                    return;
                });
        } else if (item?.objectType === "FLOW") {
            setAlertsLoading(true);
            http.sendRestRequest("documentChain/generic/list")
                .then((flows) => {
                    setAlertsLoading(false);
                    const resultflows = flows.find(
                        (flow) => flow.genericId === item.objectId
                    );
                    if (resultflows) {
                        const libData = {
                            genericId: resultflows.genericId,
                            genericName: resultflows.genericName,
                            instanceIds: [],
                        };
                        navigate(`${basePathName}/flow`);
                        dispatch(setActivePage("DocuChain"));
                        dispatch(openInstance(libData));
                    } else {
                        dispatch(
                            setSnackbarAction({
                                open: true,
                                message: `Flow Got Deleted!`,
                                severity: "error",
                            })
                        );
                        return;
                    }
                })
                .catch((error) => {
                    setAlertsLoading(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: error.error,
                            severity: "error",
                        })
                    );
                });
        } else if (item?.objectType === "SPACE") {
            const spaceId = item?.objectId;
            const idArray = item?.path?.split("//") || [];
            const workspaceId = idArray[0];
            const resWorkspace = workspaceList.find(
                (workspace) =>
                    parseInt(workspace.workspaceId) === parseInt(workspaceId)
            );

            http.sendRestRequest(
                `workspace/spaceList?workspaceId=${workspaceId}`
            )
                .then((spaces) => {
                    const space = spaces.find(
                        (space) => space.spaceId === spaceId
                    );
                    if (!space) {
                        dispatch(
                            setSnackbarAction({
                                open: true,
                                message: `Space Got Archived/Deleted!`,
                                severity: "error",
                            })
                        );
                        return;
                    } else {
                        const breadCrumbItems = [
                            {
                                id: resWorkspace?.workspaceId,
                                name: resWorkspace?.workspaceName,
                                type: "workspace",
                                parentId: 0,
                            },
                        ];
                        breadCrumbItems.push({
                            id: space?.spaceId,
                            name: space?.spaceName,
                            type: "space",
                            parentId: resWorkspace?.workspaceId,
                        });
                        let activeSpace = {
                            id: space?.spaceId,
                            name: space?.spaceName,
                            updatedOn: space?.updatedOn,
                            createdOn: space?.createdOn,
                            isShared: space?.isShared ? "Shared" : "Personal",
                            isStarred: space?.isStarred,
                            type: "space",
                            documentId: null,
                        };
                        dispatch(
                            setWorkspaceRedirectionAction(
                                {
                                    activeSpace: activeSpace,
                                    breadCrumbItems: breadCrumbItems,
                                    folderId: 0,
                                    folderName: activeSpace?.name,
                                    activeWorkspace: {
                                        workspaceId: resWorkspace?.workspaceId,
                                        workspaceName:
                                            resWorkspace?.workspaceName,
                                    },
                                },
                                true
                            )
                        );
                        navigate(`${basePathName}/workspace`);
                        dispatch(setActivePage("Workspace"));
                    }
                })
                .catch((error) => {
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: error.error,
                            severity: "error",
                        })
                    );
                });
        } else if (item?.objectType === "FOLDER") {
            const folderId = item?.objectId;
            const idArray = item?.path?.split("/") || [];
            let folderName = "";
            setAlertsLoading(true);
            http.sendRestRequest(`folders/folderDetails?folderId=${folderId}`)
                .then((resp) => {
                    setAlertsLoading(false);
                    const folderIdArray = resp.folderIdPath.split("/");
                    let parentFolderId = 0;
                    if (folderIdArray.length !== 1) {
                        parentFolderId =
                            folderIdArray[folderIdArray.length - 2];
                    }
                    const folderNameArray = resp.folderNamePath.split("/");
                    const spaceId = resp.spaceId;
                    const workspaceId = idArray[0];
                    const resWorkspace = workspaceList.find(
                        (workspace) =>
                            parseInt(workspace.workspaceId) ===
                            parseInt(workspaceId)
                    );
                    http.sendRestRequest(
                        `folders/folderData?spaceId=${spaceId}&folderId=${parentFolderId}`
                    )
                        .then((res) => {
                            let isFolderPresent = res.find(
                                (item) => item.objectId === folderId
                            );
                            if (!isFolderPresent) {
                                dispatch(
                                    setSnackbarAction({
                                        open: true,
                                        message: `Folder Got Archived!`,
                                        severity: "error",
                                    })
                                );
                                setAlertsLoading(false);
                                return;
                            } else {
                                http.sendRestRequest(
                                    `workspace/spaceList?workspaceId=${workspaceId}`
                                )
                                    .then((spaces) => {
                                        const space = spaces.find(
                                            (space) => space.spaceId === spaceId
                                        );
                                        if (!space) {
                                            dispatch(
                                                setSnackbarAction({
                                                    open: true,
                                                    message: `Space Got Deleted!`,
                                                    severity: "error",
                                                })
                                            );
                                            return;
                                        } else {
                                            const breadCrumbItems = [
                                                {
                                                    id: resWorkspace?.workspaceId,
                                                    name: resWorkspace?.workspaceName,
                                                    type: "workspace",
                                                    parentId: 0,
                                                },
                                            ];

                                            breadCrumbItems.push({
                                                id: space?.spaceId,
                                                name: space?.spaceName,
                                                type: "space",
                                                parentId:
                                                    resWorkspace?.workspaceId,
                                            });
                                            let activeSpace = {
                                                id: space?.spaceId,
                                                name: space?.spaceName,
                                                updatedOn: space?.updatedOn,
                                                createdOn: space?.createdOn,
                                                isShared: space?.isShared
                                                    ? "Shared"
                                                    : "Personal",
                                                isStarred: space?.isStarred,
                                                type: "space",
                                                documentId: null,
                                            };
                                            folderIdArray.forEach(
                                                (element, index) => {
                                                    if (
                                                        parseInt(folderId) ===
                                                        parseInt(element)
                                                    ) {
                                                        folderName =
                                                            folderNameArray[
                                                                index
                                                            ];
                                                    }
                                                    breadCrumbItems.push({
                                                        id: parseInt(element),
                                                        name: folderNameArray[
                                                            index
                                                        ],
                                                        type: "folder",
                                                        parentId:
                                                            index === 0
                                                                ? 0
                                                                : parseInt(
                                                                      folderIdArray[
                                                                          index -
                                                                              1
                                                                      ]
                                                                  ),
                                                    });
                                                }
                                            );
                                            const redirectionObject = {
                                                breadCrumbItems:
                                                    breadCrumbItems,
                                                folderId: parseInt(folderId),
                                                folderName,
                                                type: "folder",
                                                activeSpace: activeSpace,
                                                activeWorkspace: {
                                                    workspaceId:
                                                        resWorkspace?.workspaceId,
                                                    workspaceName:
                                                        resWorkspace?.workspaceName,
                                                },
                                            };
                                            dispatch(
                                                setWorkspaceRedirectionAction(
                                                    redirectionObject,
                                                    true
                                                )
                                            );
                                            navigate(
                                                `${basePathName}/workspace`
                                            );
                                            dispatch(
                                                setActivePage("Workspace")
                                            );
                                        }
                                        setAlertsLoading(false);
                                    })
                                    .catch((err) => {
                                        setAlertsLoading(false);
                                        console.log(err);
                                        dispatch(
                                            setSnackbarAction({
                                                open: true,
                                                message: `Space Got Deleted!`,
                                                severity: "error",
                                            })
                                        );
                                        return;
                                    });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            setAlertsLoading(false);
                        });
                })
                .catch((err) => {
                    setAlertsLoading(false);
                    console.log(err);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: `Folder Is not present in the given location!`,
                            severity: "error",
                        })
                    );
                    return;
                });
        } else if (item?.objectType === "WORKSPACE") {
            const resultworkspace = workspaceList.find(
                (workspace) => workspace.workspaceId === item.objectId
            );

            if (resultworkspace === undefined) {
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: `Workspace Got Deleted!`,
                        severity: "error",
                    })
                );
                return;
            }

            setAlertsLoading(true);
            localStorage.setItem("workspaceId", resultworkspace.workspaceId);
            http.sendRestRequest(
                `workspace/spaceList?workspaceId=${resultworkspace.workspaceId}`
            )
                .then((response) => {
                    dispatch(
                        setActiveWorkspaceAction({
                            workspaceId: resultworkspace.workspaceId,
                            workspaceName: resultworkspace.workspaceName,
                            workspaceType: "USER",
                        })
                    );
                    navigate(`${basePathName}/workspace`);
                    dispatch(setSpaceListAction(response));
                    dispatch(setActivePageAction("Workspace"));
                    setAlertsLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setAlertsLoading(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: err.error,
                            severity: "error",
                        })
                    );
                });
        }
    };

    return (
        <>
            {alertsLoading ? (
                <Loading />
            ) : recentActivityData.length === 0 ? (
                <>
                  <div
                        className="heading"
                        style={{
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "24px",
                            letterSpacing: "0px",
                            padding: "4px 8px",
                        }}
                    >
                        Recent Activity
                    </div>
                    <EmptyHomeScreen
                        image="/images/home_empty_icon.svg"
                        subTitle="Nothing here yet"
                        style={{ width: "3rem",marginTop: "-45px" }}
                    />
                </>
            ) : (
                <div
                    id="recentview-Container"
                    className={classes1.recentViewContainer}
                    style={{ padding: "8px 12px" }}
                >
                    <div
                        className="heading"
                        style={{
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                            fontSize: "20px",
                            lineHeight: "24px",
                            letterSpacing: "0px",
                            padding: "1px 0px",
                        }}
                    >
                        Recent Activity
                    </div>

                    <div
                        className="invisibleScroller Time_line"
                        style={{ overflowY: "auto", scrollbarWidth: "thin" }}
                    >
                        <Timeline>
                            {recentActivityData.map((item, idx) => (
                                <TimelineItem
                                    key={idx}
                                    style={{
                                        height: "35px",
                                        minHeight: "25px", // Ensures minimum height
                                        padding: "4px 0", // Adjusts spacing if needed
                                        alignItems: "center", // Ensures proper alignment
                                    }}
                                >
                                    <TimelineOppositeContent color="textSecondary">
                                        <Typography
                                            className={classes1.timelineDate}
                                        >
                                            {getParsedDateByDateAndTime(
                                                item.createdOn
                                            )}
                                        </Typography>
                                    </TimelineOppositeContent>

                                    <TimelineSeparator>
                                        <TimelineDot color="primary" />
                                        {recentActivityData.length - 1 !==
                                            idx && (
                                            <TimelineConnector
                                                style={{
                                                    display: "block", // Ensures it does not stretch
                                                    height: "10px", // Force small height
                                                    width: "1px", // Keep it thin
                                                    backgroundColor: "#ccc",
                                                    flexGrow: 0, // Prevents automatic stretching
                                                    alignSelf: "center", // Keeps it aligned properly
                                                }}
                                            />
                                        )}
                                    </TimelineSeparator>

                                    <div style={{ width: "70%" }}>
                                        <TimelineContent>
                                            <Typography
                                                className={`${classes1.timelineText} ${classes1.textEllipsis}`}
                                                title={item.description}
                                                onClick={() =>
                                                    handleActivityClick(item)
                                                }
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div
                                                    className="textEllipsis"
                                                    title={getTextFromHTML(
                                                        item.description
                                                    )}
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.description,
                                                    }}
                                                ></div>
                                            </Typography>
                                        </TimelineContent>
                                    </div>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </div>
                </div>
            )}
        </>
    );
};

export default RecentActivity;
