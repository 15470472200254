import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Tooltip from "@mui/material/Tooltip";

export default function Breadcrumb({
    breadcrumbData,
    handleBreadcrumbClick,
    flow,
    email,
}) {
    const getIconSrc = (item, index) => {
        if (!index) {
            switch (item.name) {
                case "Flow":
                    return "./images/flowWksp.svg";
                case "Emails":
                    return "./images/email.svg";
                default:
                    return "./images/workspace-icon-color.svg";
            }
        }
        if (index === 1) {
            return flow
                ? "./images/smallInst.svg"
                : email
                ? "./images/inbox.svg"
                : "./images/spaceIcon.svg";
        }
        if (index === 3 && email) {
            return item?.type?.toUpperCase() === "EMAIL"
                ? "./images/email.svg"
                : "./images/paperclip.svg";
        }
        if (index > 1 && email) {
            return "./images/email.svg";
        }
        return item.type === "email"
            ? "./images/email.svg"
            : "./images/FOLDER.svg";
    };

    return (
        <div role="presentation">
            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}
            >
                {breadcrumbData.map((item, index) => (
                    <div
                        key={item.id}
                        title={item.name}
                        className="workspace-actions"
                        style={{ gap: "8px", cursor: "pointer" }}
                        onClick={(event) => handleBreadcrumbClick(event, item)}
                    >
                        <img
                            src={getIconSrc(item, index)}
                            alt=""
                            className="action-icon"
                        />
                        <span className="action-label textEllipsis">
                                {item.name}
                        </span>
                    </div>
                ))}
            </Breadcrumbs>
        </div>
    );
}
