import React from "react";
import InfoFeedTable from "./InfoFeedTable";

const InformationFeed = () => {
    return (
        <div
            style={{
                padding: "2rem",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
            }}
        >
            <InfoFeedTable />
        </div>
    );
};

export default InformationFeed;
