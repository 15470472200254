import React from "react";
import http from "../../../../../bae-client/HTTP";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setActivePage, setActiveRipple } from "../../../../../actions";
import {
    setChipArray,
    setRedirectToRipplelog,
} from "../../../../../actions/Rippleaction";
import { Menu, MenuItem } from "@mui/material";
import { openInstance } from "../../../../../actions/DashboardActions";
import {
    setAlertLogData,
    setHomeAlertLogData,
} from "../../../../../actions/DocumentChainActions";
import Loader from "../../../../stylized/Loader";
import { setSnackbarAction } from "../../../../../services/services/app";
import { getLocalDateFromUTC } from "../utils/helper";

const getFlowAlertIcon = (alert) => {
    let src =
        alert.blockType.trim().toLowerCase() === "optional" ||
        alert.exceptionType === "SKIPPED"
            ? "./images/yellowAlert.png"
            : alert.exceptionType === "PROACTIVE ALERT"
            ? "./images/Flow/t proactive.svg"
            : "./images/redAlert.png";

    return src;
};

const getAlertIcon = (alert) => {
    let src = "";
    if (alert.alertType.toLowerCase() === "alert") {
        src = "./images/redAlert.svg";
    } else if (alert.alertType.toLowerCase() === "reminder") {
        src = "./images/remiderIcon.svg";
    } else if (alert.alertType.toLowerCase() === "notification") {
        src = "./images/Vector.png";
    } else if (alert.alertType.toLowerCase() === "ripple") {
        src = "./images/Ripple.svg";
    } else if (alert.alertType.toLowerCase() === "menu") {
        src = "./images/alertMenu.png";
    } else if (alert.alertType.toLowerCase() === "integration") {
        src = "./images/Integrations.png";
    } else if (
        alert.alertType.toLowerCase() === "document addition" ||
        alert.alertType.toLowerCase() === "document intersection"
    ) {
        src = "./images/Subtract.png";
    }
    return src;
};

const Alerts = ({ alert, fetchAlerts, handleClick = () => {} }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let basePathName = window.runtimeConfig.instanceName
        ? "/" + window.runtimeConfig.instanceName
        : "";
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const [isDocumentAlert, setIsDocumentAlert] = React.useState(false);
    const [isDocAdditionAlert, setIsDocAdditionAlert] = React.useState(false);
    const [isRippleAlert, setIsRippleAlert] = React.useState(false);
    const [isExceptionAlert, setIsExceptionAlert] = React.useState(false);
    const [showLoader, setShowLoader] = React.useState(false);
    const alertClickHandler = (alert) => {
        let basePathName = window.runtimeConfig.instanceName
            ? "/" + window.runtimeConfig.instanceName
            : "";
        if (alert.alertType === "ripple") {
            let rippleRegistryId = parseInt(alert.clickActionParameters);
            let url = `ripple/rippleRegistry/${rippleRegistryId}`;
            http.sendRestRequest(url, "GET").then((resp) => {
                let rippleData = resp;
                rippleData["rippleId"] = resp.rippleRegistryId;
                dispatch(setActiveRipple(rippleData));
                dispatch(setActivePage("Ripple"));
                navigate(`${basePathName}/ripple`);
                dispatch(setRedirectToRipplelog(false));
                dispatch(setChipArray([]));
            });
        }
    };
    const handleClickMenu = (event, row) => {
        const isFlow = alert?.exceptionLogObjectId ? true : false;
        if (row.alertType === "Document Intersection") {
            setIsDocumentAlert(true);
        } else if (row.alertType === "document addition") {
            setIsDocAdditionAlert(true);
        } else if (row.alertType === "ripple") {
            setIsRippleAlert(true);
        } else if (isFlow) {
            setIsExceptionAlert(true);
        }

        setAnchorEl(event.currentTarget);
    };
    const closeAlert = () => {
        const libData = {
            genericId: alert.genericId,
            exceptionLogObjectId: alert.exceptionLogObjectId,
            status: "CLOSED",
        };
        let url = "documentChain/exception/status";
        setShowLoader(true);
        http.sendRestRequest(url, "PUT", [libData]).then((documentObjects) => {
            dispatch(
                setSnackbarAction({
                    open: true,
                    message: `Alert Closed Successfully!`,
                    severity: "success",
                })
            );
            setShowLoader(false);
            fetchAlerts();
        });
    };
    const handleClose = (e) => {
        if (e && e.target && (e.target.innerText || e.target.id)) {
            if (
                e.target.innerText === "Open Instance" ||
                e.target.id === "Open Instance"
            ) {
                dispatch(
                    openInstance({
                        genericId: alert.genericId,
                        genericName: alert.genericDisplayName,
                        instanceIds: [alert.instanceId],
                    })
                );
                navigate(`${basePathName}/flow`);
                dispatch(setActivePage("DocuChain"));
            } else if (
                e.target.innerText === "Open in Ripple" ||
                e.target.id === "Open in Ripple"
            ) {
                // openInstancePage();
                alertClickHandler(alert);
            } else if (
                e.target.id === "Close Alert" ||
                e.target.id === "Close Alert"
            ) {
                closeAlert(alert);
            }
        }

        setAnchorEl(null);
        setIsDocumentAlert(false);
        setIsRippleAlert(false);
        setIsExceptionAlert(false);
        setIsDocAdditionAlert(false);
    };

    if (!alert) return null;
    return (
        <div
            className="alert-container"
            onClick={() => handleClick(alert)}
        >
            {!alert.isRead && <div className="alert-unread"></div>}
            <div className="alert-left">
                <img
                    src={
                        alert.hasOwnProperty("exceptionLogObjectId")
                            ? getFlowAlertIcon(alert)
                            : getAlertIcon(alert)
                    }
                    alt=""
                />
            </div>
            <div className="alert-center">
                <div
                    className="alert-title"
                    style={{
                        fontFamily: "Montserrat",
                        fontWeight: alert.isRead ? "500" : "600",
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "normal",
                        color: alert.isRead ? "#000000" : "#0B5CD7" }}
                >
                    {alert.hasOwnProperty("exceptionLogObjectId")
                        ? alert?.exceptionType
                        : alert?.hasOwnProperty("alertName") &&
                          alert?.alertType === "ripple" &&
                          alert?.alertName?.split(":")[0]?.trim() === ""
                        ? `Unsaved Ripple ${alert?.alertName}`
                        : alert?.alertName}
                </div>
                <div
                    className="alert-description textEllipsis"
                    dangerouslySetInnerHTML={{
                        __html: alert.exceptionText,
                    }}
                    style={{
                        fontFamily: "Montserrat",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "16px",
                        letterSpacing: "normal", 
                        color:"#00000066"
                    }}
                >
                    {/* {alert.exceptionText || ""} */}
                </div>
            </div>
            <div className="alert-right">
                <div
                    onClick={(event) => {
                        event.stopPropagation();
                        handleClickMenu(event, alert);
                    }}
                >
                    <img src="./images/design/vertical-option.svg" alt="" />
                </div>
            </div>
            <div
                className="alert-date"
                title={getLocalDateFromUTC(alert?.createdOn)}
            >
                {getLocalDateFromUTC(alert?.createdOn)}
            </div>
            {isDocumentAlert ? (
                <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>
                        <div>
                            {/* <img
                                className="deleteIcon"
                                title="Attach to Flow"
                                src="./images/Rename.svg"
                                alt="Attach to Flow"
                            /> */}
                            <span className="btn_title" title="Attach to Flow">
                                Attach to Flow
                            </span>
                        </div>
                    </MenuItem>
                </Menu>
            ) : null}
            {isDocAdditionAlert ? (
                <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>
                        <div>
                            {/* <img
                                    className="deleteIcon"
                                    title="Attach to Flow"
                                    src="./images/Rename.svg"
                                    alt="Attach to Flow"
                                /> */}
                            <span className="btn_title" title="Open File">
                                Open File
                            </span>
                        </div>
                    </MenuItem>
                </Menu>
            ) : null}
            {isRippleAlert ? (
                <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>
                        <div>
                            {/* <img
                                className="deleteIcon"
                                title="Attach to Flow"
                                src="./images/Rename.svg"
                                alt="Attach to Flow"
                            /> */}
                            <span className="btn_title" title="Open in Ripple">
                                Open in Ripple
                            </span>
                        </div>
                    </MenuItem>
                </Menu>
            ) : null}
            {isExceptionAlert ? (
                <Menu
                    id="demo-customized-menu"
                    MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleClose}>
                        <div>
                            {/* <img
                                className="deleteIcon"
                                title="Attach to Flow"
                                src="./images/Rename.svg"
                                alt="Attach to Flow"
                            /> */}
                            <img
                                className="deleteIcon"
                                title="Open Instance"
                                src="./images/arrow-up-right.svg"
                                alt="Open Instance"
                                id="Open Instance"
                            />
                            <span className="btn_title" title="Open Instance">
                                Open Instance
                            </span>
                        </div>
                    </MenuItem>
                    <MenuItem onClick={handleClose} id="Close Alert">
                        <div id="Close Alert">
                            <img
                                className="deleteIcon"
                                title="Close Alert"
                                src="./images/x-square.svg"
                                alt="Close Alert"
                                id="Close Alert"
                            />
                            <span
                                className="btn_title"
                                title="Close Alert"
                                id="Close Alert"
                            >
                                Close Alert
                            </span>
                        </div>
                    </MenuItem>
                </Menu>
            ) : null}
            <Loader show={showLoader}></Loader>
        </div>
    );
};

export default Alerts;
