import React, { useEffect } from "react";
import Dropdown from "../components/dropdown/Dropdown";

const TableActions = ({
    selectedRows,
    infoFeedData,
    handleDateRangeSelection,
    hideHeader,
    pathname,
    setData,
    originalData,
    localTimeFilters,
    basePathName,
}) => {
    const [filterBy, setFilterBy] = React.useState("");

    useEffect(() => {
        if (filterBy) filterData(filterBy);
        else filterData();
    }, [filterBy]);
    const filterData = (filterBy) => {
        if (filterBy === "MANUAL")
            setData(
                originalData.filter((item) => item.documentType === "FILE")
            );
        else if (filterBy === "EMAIL")
            setData(
                originalData.filter(
                    (item) =>
                        item.documentType === "EMAIL" ||
                        item.documentType === "EMAIL_ATTACHMENT"
                )
            );
        else if (filterBy === "CHAINED")
            setData(
                originalData.filter((item) => item.flowStatus === "CHAINED")
            );
        else if (filterBy === "UNCHAINED")
            setData(
                originalData.filter((item) => item.flowStatus === "UNCHAINED")
            );
        else setData(originalData);
    };
    return (
        selectedRows.length === 0 &&
        (pathname === `${basePathName}/info-feeds` ||
            pathname === `${basePathName}/info-feeds/`) && (
            <div className="table-actions">
                <div
                    className="action-card"
                    style={{
                        border: "1px solid #0b5cd7",
                        borderRadius:"5px"
                    }}
                >
                    <div className="action-text">
                        <Dropdown
                            options={[
                                { id: 1, label: "Last 24 hours" },
                                { id: 2, label: "Last 7 days" },
                                { id: 3, label: "Last 30 days" },
                                { id: 4, label: "Last 90 days" },
                                { id: 5, label: "Last Year" },
                                { id: 6, label: "Custom" },
                                { id: 7, label: "All" },
                            ]}
                            defaultOption={
                                localTimeFilters?.timeRange || "Last 24 hours"
                            }
                            handleDropdownSelection={handleDateRangeSelection}
                            isDateDropdown
                        />
                    </div>
                </div>
                {!hideHeader && (
                    <>
                        <div
                            className="action-card"
                            title="Incoming Emails"
                            style={{
                                cursor: "pointer",
                                border: `${
                                    filterBy === "EMAIL"
                                        ? "1px solid #0b5cd7"
                                        : "none"
                                }`,
                                borderRadius: "5px"
                            }}
                            onClick={() =>
                                filterBy !== "EMAIL"
                                    ? setFilterBy("EMAIL")
                                    : setFilterBy("")
                            }
                        >
                            <div className="action-text">{`Incoming Emails:`}</div>
                            <div className="action-number">
                                {infoFeedData?.totalEmails > 0
                                    ? infoFeedData?.totalEmails
                                    : 0}
                            </div>
                        </div>
                        <div
                            className="action-card"
                            title="Incoming Documents"
                            style={{
                                cursor: "pointer",
                                border: `${
                                    filterBy === "MANUAL"
                                        ? "1px solid #0b5cd7"
                                        : "none"
                                }`,
                                borderRadius: "5px"
                            }}
                            onClick={() =>
                                filterBy !== "MANUAL"
                                    ? setFilterBy("MANUAL")
                                    : setFilterBy("")
                            }
                        >
                            <div className="action-text">{`Incoming Documents:`}</div>
                            <div className="action-number">
                                {infoFeedData?.totalDocuments > 0
                                    ? infoFeedData?.totalDocuments
                                    : 0}
                            </div>
                        </div>
                        <div
                            className="action-card"
                            title="Chained"
                            style={{
                                cursor: "pointer",
                                border: `${
                                    filterBy === "CHAINED"
                                        ? "1px solid #0b5cd7"
                                        : "none"
                                }`,
                                borderRadius: "5px"
                            }}
                            onClick={() =>
                                filterBy !== "CHAINED"
                                    ? setFilterBy("CHAINED")
                                    : setFilterBy("")
                            }
                        >
                            <div className="action-text">{`Chained:`}</div>
                            <div className="action-number">
                                {infoFeedData?.totalChainedDocument > 0
                                    ? infoFeedData?.totalChainedDocument
                                    : 0}
                            </div>
                        </div>
                        <div
                            className="action-card"
                            title="Unchained"
                            style={{
                                cursor: "pointer",
                                border: `${
                                    filterBy === "UNCHAINED"
                                        ? "1px solid #0b5cd7"
                                        : "none"
                                }`,
                                borderRadius: "5px"
                            }}
                            onClick={() =>
                                filterBy !== "UNCHAINED"
                                    ? setFilterBy("UNCHAINED")
                                    : setFilterBy("")
                            }
                        >
                            <div className="action-text">{`Unchained:`}</div>
                            <div className="action-number">
                                {infoFeedData?.totalUnchainedDocument > 0
                                    ? infoFeedData?.totalUnchainedDocument
                                    : 0}
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    );
};

export default TableActions;
