import React from "react";
import "./style.css";

const Box = ({ icon, name, count, color }) => {
    return (
        <div
            style={{ backgroundColor: color || "#ECF2FE" }}
            className="box-container"
        >
            <div className="box-top">
                <img src={icon} alt="" />
                <span>{count || "0"}</span>
            </div>
            <div className="box-bottom">
                <span>{name || "Box"}</span>
            </div>
        </div>
    );
};

export default Box;
