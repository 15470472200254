import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddFolderModal from "../../../ContentPane/WorkspaceContainer/AddFolderModal";
import { connect, useSelector } from "react-redux";
import { fetchWorkspaceListData } from "../../../../../../actions/WokspaceActions";
import { fetchFolderListData } from "../../../../../../actions/FolderActions";
import { validateNameLength } from "../../../../../../bae-client/Utils";
import http from "../../../../../../bae-client/HTTP";
import { ValidateEmail } from "../../../../../../bae-client/Utils";
import ListView from "../../../../../stylized/ListView";
import AlertDialog from "../../../../../stylized/AlertDialog";
import Loader from "../../../../../stylized/Loader";
import PopoverView from "../../../../../stylized/PopoverView";
import { Icon } from "@material-ui/core";
import "./FromWrapper.css";
import { setFolderIdToNameMap } from "../../../../../../actions/FolderActions";
import CustomSnackbar from "../../../../../stylized/CustomSnackbar/CustomSnackbar";
import * as Mixpanel from "../../../../../../mix-panel/Mixpanel";
import { setSnackbarAction } from "../../../../../../services/services/app";
import FileSelectionTree from "./FileSelectTree";
import CustomSearchDropdown from "../../../../../stylized/CustomDropDown/index";
import ControlledTreeView from "../../../../../stylized/tree-view/TreeView";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    dropdown: {
        // maxWidth: "9rem",
        // maxWidth: "12rem",
        borderRadius: "6px !important",
        display: "inline-flex",
        flexDirection: "column",
        position: "relative",
        // minWidth: "0px",
        padding: "0px",
        alignItems: "center",
        margin: "8px",
        border: "0px",
        verticalAlign: "top",
        width: "100%",
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps(curruntAction) {
    return [
        curruntAction === "createFilter" ? "Create New Filter" : "Edit Filter",
        "Select Storage",
        "Confirm",
    ];
}

function FormWrapper({
    onHide,
    parent,
    Display,
    documentDetails,
    activeWorkspace,
    accountType,
    fetchWorkspaceListData,
    fetchFolderData,
    selectedCheckedData,
    selectedRowData,
    isFileOrFolderSelected,
    setSelectedRowData,
    defaultStep,
    folderData,
    instanceDetails,
    genericId,
    fetchInstanceList,
    handleShareAttachment,
    heading,
    hideSpace,
    hideFolder,
    selectedInstance,
    hideFile,
    parentFolderId,
    formType,
    itemSpace,
    saveButtonName,
    updateFilterData,
    filterRawObj,
    curruntAction,
    filters,
    warningMsgFrom,
    workSpaceFilterTable,
    alertView = () => {},
    handleClickResult = () => {},
    rowsPerPage,
    page,
}) {
    const cuboidInfoByName = useSelector((state) => state.cuboidInfoByName);
    const workspaceList = useSelector((state) => state.workspaceList);
    const spaceIdToWorkspaceId = useSelector(
        (state) => state.spaceIdToWorkspaceId
    );
    const dispatch = useDispatch();
    const spaceIdToName = useSelector((state) => state.spaceIdToName);
    const workspaceIdToName = useSelector((state) => state.workspaceIdToName);
    const folderIdToName = useSelector((state) => state.folderIdToName);
    const filterList = useSelector((state) => state.filterList);
    const [apiStatus, setApiStatus] = useState(null);
    const [expanded, setExpanded] = React.useState([]);
    const [selected, setSelected] = useState([]);
    const [selectedView, setSelectedView] = useState(null);
    const [addFolderModalShow, setAddFolderModalShow] = useState(false);
    const [apiError, setApiError] = useState("");
    const [isFolderListLoaded, setIsFolderListLoaded] = useState(false);
    const [metaIds, setMetaIds] = useState({
        workspaceCollabId: null,
        workspaceWBId: null,
        workspaceRegistryId: null,
        workspacePersistenceId: null,
        spaceWBId: null,
        spaceRegistryId: null,
        spacePersistenceId: null,
    });

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [alertDialogContent, setAlertDialogContent] = useState({
        title: "",
        message: "",
        isAlert: false,
        messageId: "",
        buttonId: "",
    });
    const [formOneInput, setFormOneInput] = useState({
        nameFilter: "",
        from: "",
        subject: "",
        emailBody: "",
        emailSubject: "",
    });
    const [alertDialogShow, setAlertDialogShow] = useState(false);

    const [isChecked, setIsChecked] = useState(true);
    const [selectedFolder, setSelectedFolder] = useState({
        folderId: "",
        folderName: "",
        documentId: "",
        childrenFolderNames: [],
    });
    const [spaceArray, setSpaceArray] = useState([]);

    const [recentUploadedFolder, setRecentUploadedFolder] = useState(null);
    const [recentUploadedFolderId, setRecentUploadedFolderId] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedAll, setSelectedAll] = React.useState([]);
    const [folderList, setFolderList] = useState(null);
    const [selectedFolderStructure, setSelectedFolderStructure] =
        useState(null);
    const [error, setError] = useState(null);
    const [errorMsg, setErrorMsg] = useState("");
    const [newFolderName, setNewFolderName] = useState("");
    const [dropdownSelectedWorkspace, setDropdownSelectedWorkspace] = useState({
        text: "",
        value: "",
        id: "",
    });
    const [dropdownSelectedSpace, setDropdownSelectedSpace] = useState({
        text: "",
        value: "",
        id: "",
    });
    const [selectedSpace, setSelectedSpace] = useState(null);
    useEffect(() => {
        if (cuboidInfoByName !== null) {
            let dataByName = cuboidInfoByName;
            setMetaIds({
                workspaceCollabId: dataByName.get("__Workspace"),
                workspaceWBId: dataByName.get("Workspace"),
                workspaceRegistryId: dataByName.get("Workspace Registry"),
                workspacePersistenceId: dataByName.get("Workspace Persistence"),
                spaceWBId: dataByName.get("Space"),
                spaceRegistryId: dataByName.get("Space Registry"),
                spacePersistenceId: dataByName.get("Space Persistence"),
            });
        }
    }, [metaIds.workspaceCollabId === null]);
    useEffect(() => {
        if (metaIds.workspaceCollabId) {
            let options = {
                workspaceCollabId: metaIds.workspaceCollabId,
                workspaceWBId: metaIds.workspaceWBId,
                workspaceRegistryId: metaIds.workspaceRegistryId,
                workspacePersistenceId: metaIds.workspacePersistenceId,
            };
            fetchWorkspaceListData(options);
        }
    }, [metaIds.workspaceCollabId]);
    useEffect(() => {
        if (filterRawObj && filterRawObj !== undefined) {
            const spaceId = filterRawObj.spaceId;
            const spaceName = spaceIdToName.get(spaceId);

            const workspaceId = spaceIdToWorkspaceId.get(spaceId);
            const workspaceName = workspaceIdToName.get(workspaceId);

            const folderId = filterRawObj.folderId;
            const folderName = folderIdToName.get(folderId);

            const spaceObj = { spaceId, spaceName };
            loadSpaceData(workspaceId, spaceObj);

            setFormOneInput({
                nameFilter: filterRawObj.filterName,
                from: filterRawObj.mailFrom,
                subject: filterRawObj.mailSubject,
                emailBody: filterRawObj.mailBody,
                emailSubject: filterRawObj.mailSubject,
            });
            // setSelectedWorkspace({
            //     workspaceId: workspaceId,
            //     workspaceName: workspaceName,
            // });
            // setSelectedSpace({ spaceId: spaceId, spaceName: spaceName });
            setDropdownSelectedWorkspace({
                id: workspaceId,
                text: workspaceName,
                value: workspaceName,
            });
            setDropdownSelectedSpace({
                id: spaceId,
                text: spaceName,
                value: spaceName,
            });
            gerateFolderListForSpaceId(spaceId);
            setSelectedFolder({
                folderId: folderId,
                folderName: folderName,
                documentId: "",
                childrenFolderNames: [],
            });
        }
    }, [filterRawObj]);
    const selectHandler = async (node) => {
        setSelectedFolder({
            folderId: node.objectId,
            folderName: node.objectName,
            documentId: node.documentId,
            childrenFolderNames: Array.isArray(node.children)
                ? node.children.map((e) => e.objectName)
                : [],
            parentId: node.parentId,
        });
        setSelectedView(node);

        if (node.documentId === -1) {
            try {
                const response = await getSpaceData(
                    selectedSpace,
                    node.objectId
                );

                const findFolderAndUpdate = (nodes, targetId, response) => {
                    for (let i = 0; i < nodes.length; i++) {
                        if (nodes[i].id === targetId) {
                            nodes[i].children = response;
                            return true;
                        } else if (nodes[i].children) {
                            if (
                                findFolderAndUpdate(
                                    nodes[i].children,
                                    targetId,
                                    response
                                )
                            ) {
                                return true;
                            }
                        }
                    }
                    return false;
                };

                const oldData = [...folderList];
                const folderIndex = oldData.findIndex(
                    (item) => item.id === node.objectId
                );

                if (folderIndex !== -1) {
                    oldData[folderIndex].children = response;
                } else {
                    findFolderAndUpdate(oldData, node.objectId, response);
                }

                setFolderList(oldData);
                setError("");

                if (!expanded.includes(node.objectId)) {
                    setExpanded([...expanded, node.objectId]);
                }
            } catch (err) {
                setShowLoader(false);
            }
        }
    };

    // if (!folderId && spaceId) {
    //     if (!selectedAll.includes(spaceId)) {
    //         setShowLoader(true);
    //         getSpaceData(workspaceId, spaceId, 0)
    //             .then((response) => {
    //                 const oldData = [...treeData];
    //                 const workspaceIndex = oldData.findIndex(
    //                     (item) => item.id === workspaceId
    //                 );
    //                 const spaceIndex = oldData[
    //                     workspaceIndex
    //                 ].children.findIndex((item) => item.id === spaceId);
    //                 oldData[workspaceIndex].children[spaceIndex].children =
    //                     response;
    //                 setTreeData(oldData);
    //                 setSelectedSpace({
    //                     spaceId: spaceId,
    //                     spaceName:
    //                         oldData[workspaceIndex].children[spaceIndex]
    //                             .name,
    //                 });
    //                 setError("");
    //                 setShowLoader(false);
    //                 if (!expanded.includes(spaceId)) {
    //                     setExpanded([...expanded, spaceId]);
    //                 }
    //             })
    //             .catch((err) => setShowLoader(false));
    //     }
    // } else if (!spaceId) {
    //     const spaceList = await http.sendRestRequest(
    //         `workspace/spaceList?workspaceId=${workspaceId}`
    //     );
    //     const oldData = [...treeData];
    //     const workspaceIndex = oldData.findIndex(
    //         (item) => item.id === workspaceId
    //     );
    //     oldData[workspaceIndex].children = spaceList.map((s) => ({
    //         id: s.spaceId,
    //         name: s.spaceName,
    //         type: "space",
    //     }));
    //     setTreeData(oldData);
    //     setSelectedWorkspace({
    //         workspaceId: workspaceId,
    //         workspaceName: oldData[workspaceIndex].name,
    //     });
    //     if (!expanded.includes(workspaceId)) {
    //         setExpanded([...expanded, workspaceId]);
    //     }
    // } else {
    //     setSelectedFolder({
    //         folderId: folderId,
    //         folderName: folderName,
    //         documentId: "Document ID",
    //         childrenFolderNames: [],
    //     });
    //     if (!selectedAll.includes(folderId)) {
    //         setShowLoader(true);
    //         getSpaceData(workspaceId, spaceId, folderId)
    //             .then((response) => {
    //                 const findFolderAndUpdate = (
    //                     nodes,
    //                     targetId,
    //                     response
    //                 ) => {
    //                     for (let i = 0; i < nodes.length; i++) {
    //                         if (nodes[i].id === targetId) {
    //                             nodes[i].children = response;
    //                             return true;
    //                         } else if (nodes[i].children) {
    //                             if (
    //                                 findFolderAndUpdate(
    //                                     nodes[i].children,
    //                                     targetId,
    //                                     response
    //                                 )
    //                             ) {
    //                                 return true;
    //                             }
    //                         }
    //                     }

    //                     return false;
    //                 };
    //                 const oldData = [...treeData];
    //                 const workspaceIndex = oldData.findIndex(
    //                     (item) => item.id === workspaceId
    //                 );
    //                 const spaceIndex = oldData[
    //                     workspaceIndex
    //                 ].children.findIndex((item) => item.id === spaceId);

    //                 const folderIndex = oldData[workspaceIndex].children[
    //                     spaceIndex
    //                 ].children.findIndex((item) => item.id === folderId);
    //                 const folder = oldData[workspaceIndex].children[
    //                     spaceIndex
    //                 ].children.find((item) => item.id === folderId);
    //                 if (folderIndex !== -1 && folder.id === folderId) {
    //                     oldData[workspaceIndex].children[
    //                         spaceIndex
    //                     ].children[folderIndex].children = response;
    //                 } else {
    //                     findFolderAndUpdate(oldData, folderId, response);
    //                 }
    //                 setTreeData(oldData);
    //                 setError("");
    //                 if (!expanded.includes(folderId)) {
    //                     setExpanded([...expanded, folderId]);
    //                 }
    //             })
    //             .catch((err) => setShowLoader(false));
    //     }
    // }

    useEffect(() => {
        return () => {
            setFormOneInput({
                nameFilter: "",
                from: "",
                subject: "",
                emailBody: "",
                emailSubject: "",
            });
            // setSelectedWorkspace({ workspaceId: "", workspaceName: "" });
            // setSelectedSpace({ spaceId: "", spaceName: "" });
            setDropdownSelectedWorkspace({ id: "", text: "", value: "" });
            setDropdownSelectedSpace({ id: "", text: "", value: "" });
            setSelectedFolder({
                folderId: "0",
                folderName: "",
                documentId: "",
                childrenFolderNames: [],
            });
        };
    }, []);
    const handleFormOneData = (value, id) => {
        setFormOneInput({ ...formOneInput, [id]: value });
    };

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(defaultStep || 0);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps(curruntAction);
    const getConfirmation = () => {
        return (
            <div className="marginTopTen doCenter">
                {"Confirm you want data submitted to space selected" +
                    // confirmMsg +
                    "?"}
            </div>
        );
    };
    const renderFormOne = () => {
        return (
            <div style={{ flexDirection: "column" }}>
                <div
                    className="doCenter"
                    style={{ justifyContent: "center", alignItems: "baseline" }}
                >
                    <label className="outlookModalLabel">Name Filter</label>
                    <input
                        autocomplete="off"
                        type="text"
                        title="Name"
                        id="nameFilter"
                        className="updateSpaceName oModalTextbox paddingLeft cursorPointer"
                        value={formOneInput.nameFilter}
                        onChange={(event) =>
                            handleFormOneData(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div
                    className="doCenter"
                    style={{ justifyContent: "center", alignItems: "baseline" }}
                >
                    <label className="outlookModalLabel">From</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="from"
                        title="from"
                        className="cursorPointer updateSpaceName oModalTextbox paddingLeft"
                        value={formOneInput.from}
                        onChange={(event) =>
                            handleFormOneData(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div
                    className="doCenter"
                    style={{ justifyContent: "center", alignItems: "baseline" }}
                >
                    <label className="outlookModalLabel">Subject</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="subject"
                        title="Subject"
                        className="updateSpaceName oModalTextbox paddingLeft cursorPointer"
                        value={formOneInput.subject}
                        onChange={(event) =>
                            handleFormOneData(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
                <div
                    className="doCenter"
                    style={{ justifyContent: "center", alignItems: "baseline" }}
                >
                    <label className="outlookModalLabel">Email Body</label>
                    <input
                        autocomplete="off"
                        type="text"
                        id="emailBody"
                        title="Email Body"
                        className="updateSpaceName oModalTextbox paddingLeft cursorPointer"
                        value={formOneInput.emailBody}
                        onChange={(event) =>
                            handleFormOneData(
                                event.currentTarget.value,
                                event.currentTarget.id,
                                event
                            )
                        }
                    ></input>
                </div>
            </div>
        );
    };
    const [showFolderPopover, setShowFolderPopover] = useState(false);
    const [folderPopoverTarget, setFolderPopoverTarget] = useState(null);
    const showFolderPopoverClick = (e) => {
        setShowFolderPopover(true);
        setFolderPopoverTarget(e.currentTarget);
    };
    const closeFolderPopover = (e) => {
        setShowFolderPopover(false);
        setFolderPopoverTarget(null);
    };
    const handleFolderListItemClick = (value, text, event, i) => {
        folderList.forEach((folder) => {
            if (folder.text === text) {
                setSelectedFolder({
                    folderId: folder.id,
                    folderName: folder.text,
                    documentId: "",
                    childrenFolderNames: [],
                });
                closeSpacePopover(i);
            }
        });
    };
    const renderFolderMenuItems = () => {
        return (
            <ListView
                className="noBorder"
                listItemClassname="textEllipsis"
                listRowClassname="row m-0"
                id={"departmentList"}
                label={"Department List"}
                items={folderList}
                showIcon="false"
                disableActiveIndex="true"
                onchange={handleFolderListItemClick}
            />
        );
    };
    const renderFolderPopover = () => {
        return (
            <PopoverView
                id={"menu_Department"}
                width="35rem"
                thOrigin="bottom"
                ahOrigin="left"
                show={showFolderPopover}
                anchorEl={folderPopoverTarget}
                closePopover={closeFolderPopover}
            >
                {renderFolderMenuItems()}
            </PopoverView>
        );
    };
    const [showSpacePopover, setShowSpacePopover] = useState(false);
    const [spacePopoverTarget, setSpacePopoverTarget] = useState(null);
    const closeSpacePopover = (e) => {
        setShowSpacePopover(false);
        setSpacePopoverTarget(null);
    };
    const getFolderListForSpace = (spaceId, id) => {
        return new Promise((resolve, reject) => {
            let url = `folders/folderData?spaceId=${spaceId}&folderId=${id}`;
            http.sendRestRequest(url)
                .then((data) => {
                    resolve(data);
                })
                .catch((err) => {
                    showAlertView(
                        "Operation Failed",
                        err.error,

                        true
                    );
                });
        });
    };

    const filterFoldersOnly = (items) => {
        return items
            .filter((item) => item.type === "folder") // Filter only folders
            .map((folder) => ({
                ...folder,
                children: filterFoldersOnly(folder.children || []), // Recursively filter children
            }));
    };

    const gerateFolderListForSpaceId = (spaceId) => {
        setIsFolderListLoaded(false);
        setShowLoader(true);
        setSelectedSpace(spaceId);

        getSpaceData(spaceId, 0)
            .then((folderList) => {
                setFolderList(folderList);
                setIsFolderListLoaded(true);
                setShowLoader(false);
            })
            .catch((err) => {
                showAlertView("Operation Failed", err.error, "", "", true);
                console.log(err);
                setShowLoader(false);
            });
    };
    const handleSpaceCheckClick = (index, values) => {
        setDropdownSelectedSpace(values);
        if (heading !== "Share space" && values.id)
            gerateFolderListForSpaceId(values.id);
    };
    const generateSpaceDropdown = () => {
        return (
            <CustomSearchDropdown
                items={spaceArray}
                label={"Spaces"}
                id={"Spaces"}
                title={heading}
                customClassName={classes.dropdown}
                multiSelect={false}
                isSearchable={true}
                isChipView={true}
                handleSelection={handleSpaceCheckClick}
                selection={dropdownSelectedSpace}
                customWidth={"100%"}
            ></CustomSearchDropdown>
        );
    };
    const loadSpaceData = (workspaceId, spaceObj) => {
        setShowLoader(true);
        let url = `workspace/spaceList?workspaceId=${workspaceId}`;
        http.sendRestRequest(url)
            .then((spaceList) => {
                setShowLoader(false);
                let spaceListArray = [];
                if (spaceList !== null) {
                    spaceList.map((item, i) => {
                        let obj = {};
                        obj["text"] = item.spaceName;
                        obj["value"] = item.spaceName;
                        obj["id"] = item.spaceId;
                        spaceListArray.push(obj);
                    });
                    setSpaceArray(spaceListArray);
                    // if (spaceObj !== null) {
                    //     setSelectedSpace({
                    //         spaceId: spaceObj.spaceId,
                    //         spaceName: spaceObj.spaceName,
                    //     });
                    // } else {
                    // setSelectedSpace({ spaceId: "", spaceName: "" });
                    // setSelectedFolder({
                    //     folderId: "",
                    //     folderName: "",
                    //     documentId: "",
                    // });
                    // setFolderList({});
                    // }
                }
            })
            .catch((err) => {
                setShowLoader(false);
                showAlertView("Operation Failed", err.error, "", "", true);
                console.log(err);
            });
    };
    const renderWorkspaceMenuItems = () => {
        let workspaceItems = [];
        //this.spaceTree = [];
        let workspaceTree = workspaceList.filter((workspace) => {
            const isFlowOrIntegrationOrEmail =
                workspace.workspaceName === "Flow" ||
                workspace.workspaceName === "Integration" ||
                workspace.workspaceName === "Emails";

            return !isFlowOrIntegrationOrEmail;
        });
        if (workspaceTree !== null && workspaceTree !== undefined) {
            workspaceTree.forEach((element) => {
                workspaceItems.push({
                    text: element.workspaceName,
                    value: element.workspaceName,
                    id: element.workspaceId,
                });
            });
            workspaceItemsList = workspaceItems;
        } else {
            workspaceItems.push({
                text: "No Items",
                value: "No Items",
                id: "001",
            });
        }
        return workspaceItems;
    };
    let workspaceItemsList = null;

    const handleWorkspaceCheckClick = (index, values) => {
        setDropdownSelectedWorkspace(values);
        setSpaceArray([]);
        setDropdownSelectedSpace({ text: "", value: "", id: "" });
        setFolderList([]);
        setExpanded([]); // Ensure UI resets expanded folders
        setSelected([]); // Ensure UI resets selected folders
        setSelectedFolder({
            folderId: "0",
            folderName: "",
            documentId: "",
            childrenFolderNames: [],
        });
        loadSpaceData(values.id);
    };

    const generateWorkspaceDropdown = () => {
        return (
            <CustomSearchDropdown
                items={renderWorkspaceMenuItems()}
                label={"Workspaces"}
                id={"Workspaces"}
                title={heading}
                // customClassName={"rippleDD rippleDDFilterView"}
                multiSelect={false}
                isSearchable={true}
                isChipView={false}
                handleSelection={handleWorkspaceCheckClick}
                customClassName={classes.dropdown}
                selection={dropdownSelectedWorkspace}
                customWidth={"100%"}
            />
        );
    };
    const getSpaceData = async (spaceId, id) => {
        const spaceData = await http.sendRestRequest(
            `folders/folderData?spaceId=${spaceId}&folderId=${id}`
        );
        const response = convertData(spaceData);
        return response;
    };

    const convertData = (data) => {
        const response = [];
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                const element = data[key];
                if (element.objectType !== "EMAIL") {
                    if (
                        !(element.objectType === "FILE" && heading === "Move")
                    ) {
                        response.push({
                            id: element.objectId,
                            name: element.objectName,
                            children: convertData(element.children),
                            type:
                                element.objectType === "FILE"
                                    ? "file"
                                    : "folder",
                            ...element,
                        });
                    }
                }
            }
        }
        return response;
    };

    function transformData(data, isFile) {
        if (isFile) {
            return Object.keys(data).reduce((acc, key) => {
                const document = data[key];
                if (document.documentId === -1) {
                    acc.push({
                        id: parseInt(key),
                        ...document,
                        // isFolder: true,
                        children: transformData(
                            document.children || {},
                            isFile
                        ),
                    });
                }
                return acc;
            }, []);
        }
        return Object.keys(data).map((key) => {
            const document = data[key];
            return {
                id: parseInt(key),
                ...document,
                children: transformData(document.children || {}, isFile),
            };
        });
    }

    const renderDropdowns = () => {
        return (
            <div className="doCenter" style={{ flexDirection: "column" }}>
                <>
                    {generateWorkspaceDropdown()}
                    {hideSpace ? null : generateSpaceDropdown()}
                </>
                {hideSpace || hideFolder ? null : (
                    <>
                        <span
                            className="w-100 fontFamily"
                            style={{ fontSize: "14px" }}
                        >
                            Select
                            {hideFile ? " Folder" : " File"}
                        </span>

                        <div
                            className="tree-view invisibleScroller"
                            style={{ padding: "9px 13px" }}
                        >
                            {folderList?.length === 0 ? null : (
                                <FileSelectionTree
                                    data={folderList}
                                    selectHandler={selectHandler}
                                    setSelected={setSelected}
                                    setExpanded={setExpanded}
                                    checkFolder={true}
                                    expanded={expanded}
                                    selected={selected}
                                />
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    };
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return renderFormOne();
            case 1:
                return renderDropdowns();
            case 2:
                return getConfirmation();
            default:
                return "Unknown step";
        }
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
    const validateFilterName = (name) => {
        let isFilterNameExist = false;

        if (filterRawObj !== null && filterRawObj !== undefined) {
            if (filterRawObj.filterName.toLowerCase().trim() !== name) {
                if (filterList.indexOf(name) > 0) {
                    setErrorMsg("Filter name already exist");
                    isFilterNameExist = false;
                } else {
                    setErrorMsg("");
                    isFilterNameExist = true;
                }
            } else isFilterNameExist = true;
        } else {
            if (filterList.indexOf(name) > 0) {
                setErrorMsg("Filter name already exist");
                isFilterNameExist = false;
            } else {
                setErrorMsg("");
                isFilterNameExist = true;
            }
        }
        return isFilterNameExist;
    };
    const validateFilterForm = () => {
        let isValidData = false;
        if (formOneInput.nameFilter.trim() === "") {
            setErrorMsg("Filter name should not empty");
            return false;
        } else if (formOneInput.nameFilter !== "") {
            const filterArray = filters?.map((filter) =>
                filter.filterName.toLowerCase().trim()
            );
            if (
                filterArray.includes(
                    formOneInput.nameFilter.toLowerCase().trim()
                ) &&
                (filterRawObj === null ||
                    filterRawObj?.filterName.trim() !==
                        formOneInput.nameFilter.trim())
            ) {
                setErrorMsg("Filter name already present");
                return false;
            }
            const isValidName = validateFilterName(
                formOneInput.nameFilter.toLowerCase().trim()
            );
            if (isValidName === false) {
                return false;
            }
        }
        if (formOneInput.from === "") {
            setErrorMsg("From field should not empty");
            return false;
        } else if (formOneInput.from !== "") {
            const isValidMail = ValidateEmail(formOneInput.from);
            if (!isValidMail) {
                setErrorMsg("Enter valid email");
                return false;
            } else {
                setErrorMsg("");
                return true;
            }
        } else {
            setErrorMsg("");
            return true;
        }
    };

    const validateStorageForm = () => {
        if (dropdownSelectedWorkspace.id === "") {
            setErrorMsg("Required atleast Workspace for storage");
            return false;
        }
        if (dropdownSelectedSpace.id === "") {
            setErrorMsg("Required atleast Space for storage");
            return false;
        } else {
            setErrorMsg("");
            return true;
        }
    };
    const handleNext = () => {
        let newSkipped = skipped;
        let isValidFormData = false;
        if (activeStep === 0) {
            isValidFormData = validateFilterForm();
        } else if (activeStep === 1) {
            isValidFormData = validateStorageForm();
        }
        if (isValidFormData === true) {
            if (isStepSkipped(activeStep)) {
                newSkipped = new Set(newSkipped.values());
                newSkipped.delete(activeStep);
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            setSkipped(newSkipped);
        }
    };

    const handleBack = () => {
        if (defaultStep) onHide();
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setErrorMsg("");
    };

    const handleReset = () => {
        //setActiveStep(0);
        onHide();
    };

    const getButtonName = () => {
        if (defaultStep && heading === "Move") return saveButtonName || "Move";
        if (defaultStep && heading === "Upload File")
            return saveButtonName || "Upload";
        if (defaultStep) return saveButtonName || "Share";
        if (activeStep === 2) return "Submit";
        else return "Next";
    };
    const isButtonDisableMove = () => {
        if (activeStep === 1) {
            if (hideSpace && dropdownSelectedWorkspace.id !== "") return false;
            else if (
                dropdownSelectedWorkspace.id !== "" &&
                dropdownSelectedWorkspace.text !== "" &&
                dropdownSelectedSpace.id !== "" &&
                dropdownSelectedSpace.text !== ""
            ) {
                return false;
            } else return true;
        }
    };
    const isButtonDisable = () => {
        if (activeStep === 1) {
            if (hideSpace && dropdownSelectedWorkspace?.id !== "") return false;

            if (
                dropdownSelectedWorkspace?.id !== "" &&
                dropdownSelectedWorkspace?.text !== "" &&
                dropdownSelectedSpace?.id !== "" &&
                dropdownSelectedSpace?.text !== ""
            ) {
                if (
                    heading === "Share folder" &&
                    selectedFolder.documentId !== -1
                ) {
                    return true; // Enable button
                }
                if (
                    heading === "Share file" &&
                    selectedFolder.documentId === -1
                ) {
                    return true; // Enable button
                }
                if (
                    heading === "Upload File" &&
                    selectedFolder.folderId &&
                    selectedFolder.documentId === -1
                ) {
                    return true; // Enable button
                }

                if (hideFolder && dropdownSelectedSpace?.text !== "")
                    return false;
                if (hideFolder && isFolderListLoaded) return false;
                if (hideFile && (selectedFolder.folderName || saveButtonName))
                    return false;
                if (selectedFolder.documentId) return false;

                return true; // Default case: Disable button
            }
            return true; // Disable button if dropdown conditions are not met
        }

        return true; // Disable button if not step 1
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    const getUpdateStatus = () => {
        if (apiStatus === "success") {
            return "Filter Applied!";
        } else if (apiStatus === "failure") {
            return "Filter not applied!";
        } else return "Please wait..!";
    };
    const setText = (value, id, event) => {
        if (value && value.length > 100)
            setError("Name should not exceed 100 char length");
        else setError("");
        switch (id) {
            case "newFolderName": {
                setNewFolderName(value);
            }
            default:
                break;
        }
    };
    const getFolderPath = (folderId) => {
        http.sendRestRequest(`folders/folderDetails?folderId=${folderId}`)
            .then((resp) => {
                let expandedPath = resp.folderIdPath.split("/");

                setSelected(expandedPath[expandedPath.length - 1]);
                expandedPath.pop();
                expandedPath.reverse();
                setExpanded(expandedPath);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const addNewFolder = () => {
        if (error) return;
        if (newFolderName.trim().length > 0) {
            if (
                selectedFolder.folderId === "0" &&
                Object.values(folderList)
                    .filter((e) => e.documentId === 0)
                    .map((e) => e.objectName.toUpperCase())
                    .includes(newFolderName.toUpperCase().trim())
            ) {
                setError("Folder name already exists");
                return;
            } else if (
                selectedFolder.folderId > 0 &&
                selectedFolder.childrenFolderNames.includes(
                    newFolderName.trim()
                )
            ) {
                setError("Folder name already exists");
                return;
            }
            if (!validateNameLength(newFolderName)) {
                let msg = "Folder name should be less than 100 characters";
                setError(msg);
                return;
            }
            setShowLoader(true);
            setAddFolderModalShow(false);

            let spaceId = dropdownSelectedSpace.id;
            let parentId = parentFolderId;
            let data = {
                spaceId: spaceId,
                folderName: newFolderName.trim(),
                parentFolderId: selectedFolder.folderId,
            };

            let url = "folders/folder";

            http.sendRestRequest(url, "POST", data)
                .then((resp) => {
                    if (resp) {
                        Mixpanel.callFolderManagementEvent("Add");
                        showAlertView(
                            "",
                            "Folder added successfully",
                            "alert_folder",
                            "btn_folder_close"
                        );
                        let key = Object.values(resp);
                        // let idToName = folderIdToName;

                        // idToName.set(parseInt(key[0]), data.objectName);
                        // dispatch(setFolderIdToNameMap(idToName));
                        setRecentUploadedFolder(resp);
                        setRecentUploadedFolderId(key[0]);
                        setNewFolderName("");
                        setShowLoader(false);
                        Display(false);
                        gerateFolderListForSpaceId(spaceId);
                        setSelectedFolder({
                            folderId: key[0],
                            folderName: newFolderName.trim(),
                            parentId: selectedFolder.folderId,
                            childrenFolderNames: [],
                        });
                        getFolderPath(key[0]);
                    }
                })
                .catch((err) => {
                    setNewFolderName("");
                    showAlertView(
                        "Operation Failed",
                        err.error,
                        "alert_folder",
                        "btn_folder_close",
                        true
                    );
                    setShowLoader(false);
                });
        } else {
            setError("Please enter folder name");
            return;
        }
        if (!validateNameLength(newFolderName)) {
            let msg = "Folder name should be less than 100 characters";
            setError(msg);
            return;
        }
    };

    const moveItems = (body, objectNames) => {
        let workspaceId = dropdownSelectedWorkspace.id;
        let spaceId = dropdownSelectedSpace.id;
        let folderFileId = selectedFolder?.folderId
            ? selectedFolder?.folderId
            : 0;

        setShowLoader(true);
        if (spaceId !== null) {
            let requests = [];
            for (const item of body) {
                let url;
                if (item.objectType.toLowerCase() === "email") {
                    url = `emailFiles/move?targetWorkspaceId=${workspaceId}&targetSpaceId=${spaceId}&targetFolderId=${folderFileId}&emailFileId=${item.objectId}`;
                } else {
                    if (item.objectType === "FOLDER") {
                        url = `folders/move?targetWorkspaceId=${workspaceId}&targetSpaceId=${spaceId}&targetFolderId=${folderFileId}&folderRegistryId=${item.objectId}`;
                    } else
                        url = `files/move?targetWorkspaceId=${workspaceId}&targetSpaceId=${spaceId}&targetFolderId=${folderFileId}&spacePersistenceId=${item.objectId}`;
                }

                requests.push(http.sendRestRequest(url, "PUT"));

                Promise.all(requests)
                    .then(() => {
                        if (itemSpace !== null || workSpaceFilterTable) {
                            body.forEach((item, index) => {
                                if (item.objectType === "FILE") {
                                    const splitFileName =
                                        objectNames[index]?.split(".");
                                    const fileExt = splitFileName?.pop() || "";
                                    Mixpanel.callFileManagementEvent(
                                        "Move",
                                        fileExt
                                    );
                                } else if (item.objectType === "FOLDER") {
                                    Mixpanel.callFolderManagementEvent("Move");
                                }
                            });
                            workSpaceFilterTable &&
                                handleClickResult(page, rowsPerPage);
                            dispatch(
                                setSnackbarAction({
                                    open: true,
                                    message: `Moved successfully!`,
                                    severity: "success",
                                })
                            );
                            // isFileOrFolderSelected(false);
                            !workSpaceFilterTable &&
                                fetchFolderData(
                                    parent?.id === itemSpace?.id
                                        ? { id: 0 }
                                        : parent,
                                    true
                                );
                            setShowLoader(false);
                        }

                        onHide();
                    })
                    .catch((err) => {
                        console.log(err);

                        dispatch(
                            setSnackbarAction({
                                open: true,
                                message: err?.error || `Operation Failed!`,
                                severity: "error",
                            })
                        );

                        setShowLoader(false);
                    });
            }
        }
    };

    const handleUpload = () => {
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });
        setShowLoader(true);
        http.sendRestRequest(
            `documentChain/instance/step/uploadFromWorkspace?instanceStepId=${instanceDetails.instanceStepId}&genericId=${genericId}&workspaceId=${dropdownSelectedWorkspace.id}&spaceId=${dropdownSelectedSpace.id}&documentId=${selectedFolder.documentId}&folderId=${selectedFolder.parentId}`,
            "PUT"
        )
            .then(() => {
                // Mixpanel.callFileManagementEvent("Upload");
                onHide();
                setShowLoader(false);
                // showAlertView("Upload Item", `Uploaded successfully!`);
                fetchInstanceList(selectedInstance);
                dispatch(
                    setSnackbarAction({
                        open: "true",
                        message: "Document Added Successfully!",
                        severity: "success",
                    })
                );
                document.getElementById("div_instanceRefresh_icon").click();
            })
            .catch((err) => {
                alertView("Operation Failed", err.error, "", "", true);
                setShowLoader(false);
                onHide();

                console.error(err);
            });
    };
    const handleMoveFile = (response, isDelete) => {
        setAlertDialogContent({
            title: "",
            message: "",
            isAlert: false,
        });
        if (workSpaceFilterTable) {
            const spaceId = parseInt(dropdownSelectedSpace.id);
            const folderId = selectedFolder.folderId;

            const isInvalidMove = selectedRowData.some((item) => {
                const objectId = parseInt(item.objectId);
                const locationArray = item.clickActionParameters?.split("/");
                const objectSpaceId = parseInt(locationArray[1]);
                let workSpaceLocation = item.objectLocation.split("/")[0];
                if (
                    ["flow", "emails", "integration"].includes(
                        workSpaceLocation.toLowerCase()
                    )
                )
                    return true;
                if (item.objectType === "FILE") {
                    let DocumentParentId =
                        locationArray.length === 3
                            ? parseInt(locationArray[1])
                            : parseInt(locationArray[locationArray.length - 2]);
                    if (DocumentParentId === spaceId && folderId === "0")
                        return true;
                    if (DocumentParentId === folderId) return true;
                } else {
                    if (folderId !== "0" && objectId === folderId) return true;

                    if (objectSpaceId === spaceId && folderId === "0")
                        return true;
                }
                return false;
            });

            if (isInvalidMove) {
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: `This Folder/File cannot be moved`,
                        severity: "error",
                    })
                );
                return;
            }
            let body = [];
            let objectNames = [];
            selectedRowData.forEach((item) => {
                let objectId = item.objectId;
                let objectType = item.objectType;
                objectNames.push(item.objectName);
                body.push({
                    objectId: objectId,
                    objectType:
                        item?.objectType?.toLowerCase() === "email"
                            ? "email"
                            : objectType,
                });
            });
            if (body[0].objectId == selectedFolder.folderId) {
                // showAlertView("Move Item", `This Folder/File cannot be moved`);
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: `This Folder/File cannot be moved`,
                        severity: "error",
                    })
                );
                return;
            }
            moveItems(body, objectNames);
        } else {
            if (
                itemSpace?.id === dropdownSelectedSpace.spaceId &&
                parentFolderId === selectedFolder.folderId
            ) {
                // showAlertView("Move Item", `This Folder/File cannot be moved`);
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: `This Folder/File cannot be moved`,
                        severity: "error",
                    })
                );
                return;
            } else if (
                parentFolderId === selectedFolder.folderId &&
                parseInt(selectedFolder.folderId)
            ) {
                // showAlertView("Move Item", `This Folder/File cannot be moved`);
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: `This Folder/File cannot be moved`,
                        severity: "error",
                    })
                );
                return;
            } else if (
                selectedRowData.find((r) => r.id == selectedFolder.folderId)
            ) {
                // showAlertView("Move Item", `This Folder/File cannot be moved`);
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: `This Folder/File cannot be moved`,
                        severity: "error",
                    })
                );
                return;
            }
            let body = [];
            let objectNames = [];
            selectedRowData.forEach((item) => {
                // let objectId =
                //     folderData[item].documentId === 0
                //         ? item
                //         : folderData[item].documentId;
                // let objectType =
                //     folderData[item].documentId === 0 ? "FOLDER" : "FILE";
                let objectId =
                    // item.hasOwnProperty("documentId") && item.documentId !== null
                    //     ? item.id
                    // :
                    item.id;
                let objectType = item.documentId ? "FILE" : "FOLDER";
                objectNames.push(item.name);
                body.push({
                    objectId: objectId,
                    objectType:
                        item?.type?.toLowerCase() === "email"
                            ? "email"
                            : objectType,
                });
            });
            if (body[0].objectId == selectedFolder.folderId) {
                // showAlertView("Move Item", `This Folder/File cannot be moved`);
                dispatch(
                    setSnackbarAction({
                        open: true,
                        message: `This Folder/File cannot be moved`,
                        severity: "error",
                    })
                );
                return;
            }
            moveItems(body, objectNames);
        }
    };

    // const handleMoveFile = () => {
    //     const MovedObject = {
    //         workspaceId: selectedWorkspace.workspaceId,
    //         spaceId: selectedSpace.spaceId,
    //         folderFileId: selectedFolder && selectedFolder.folderId,

    //         folderFileName: selectedFolder && selectedFolder.folderName,
    //     };
    //     let url = `spacePersistence/movefile?targetWorkspaceId=${
    //         workspaceId
    //     }&targetSpaceId=${spaceId}&targetFolderId=${
    //         folderFileId ? folderFileId : 0
    //     }&documentId=${documentDetails.documentId}`;
    //     http.sendRestRequest(url, "PUT")
    //         .then((resp) => {
    //             if (resp) {
    //                 showAlertView(
    //                     "",
    //                     "File Moved successfully",
    //                     "alert_folder",
    //                     "btn_folder_close"
    //                 );
    //                 onHide();
    //                 setShowLoader(false);

    //                 fetchFolderListData(spaceId);
    //             }
    //         })
    //         .catch((err) => {
    //             showAlertView(
    //                 "Operation Failed",
    //                 err && err.error
    //                     ? err.error
    //                     : "Something went wrong, please try again!",
    //                 "alert_folder",
    //                 "btn_folder_close",
    //                 true
    //             );
    //             setShowLoader(false);
    //         });
    // };
    const handleAttachment = () => {
        const fileObject = {
            workspaceId: dropdownSelectedWorkspace.id,
            workspaceName: dropdownSelectedWorkspace.text,
            spaceId: dropdownSelectedSpace.id,
            spaceName: dropdownSelectedSpace.text,
            // folderFileId: selectedView?.id ? selectedView.id : 0,
            // folderFileName: selectedView?.objectName
            //     ? selectedView?.objectName
            //     : "",
            // documentId: selectedView?.documentId
            //     ? selectedView?.documentId
            //     : "",
            folderFileId: selectedFolder.folderId || 0,
            folderFileName: selectedFolder && selectedFolder.folderName,
            documentId: selectedFolder && selectedFolder.documentId,
            isDownload: isChecked,
        };

        handleShareAttachment(
            fileObject,
            selectedFolderStructure || folderList
        );
        onHide();
    };

    const handleSubmit = () => {
        setShowLoader(true);
        let workspaceId = parseInt(dropdownSelectedWorkspace.id);
        let spaceId = parseInt(dropdownSelectedSpace.id);
        let folderId =
            selectedFolder.folderId !== "0"
                ? parseInt(selectedFolder.folderId)
                : "0";
        if (
            workspaceId !== undefined &&
            spaceId !== undefined &&
            folderId !== undefined
        ) {
            let url = "integration/filter";

            let data = {
                filterName: formOneInput.nameFilter.trim(),
                mailFrom: formOneInput.from.trim(),
                mailSubject: formOneInput.subject.trim(),
                mailBody: formOneInput.emailBody.trim(),
                //"workspaceId": workspaceId,
                spaceId: spaceId,
                folderId: folderId,
                accountType: accountType.toLowerCase().trim(),
            };
            let methodType = "POST";
            if (filterRawObj !== null && filterRawObj !== undefined) {
                data.filterId = filterRawObj.filterId;
                methodType = "PUT";
            }
            http.sendRestRequest(url, methodType, data)
                .then((data) => {
                    setShowLoader(false);
                    if (data === 200) {
                        const msg =
                            methodType === "PUT"
                                ? "Filter updated successfully"
                                : "Filter created successfully";
                        const type = methodType === "PUT" ? "update" : "create";
                        showAlertView(
                            "",
                            msg,
                            type + "_filter_alert_msg",
                            type + "_filter_alert_btn"
                        );
                        setTimeout(() => {
                            setApiStatus("success");
                            handleReset();
                            if (updateFilterData !== undefined) {
                                updateFilterData();
                            }
                        }, 1000);
                    }
                })
                .catch((err) => {
                    setShowLoader(false);
                    showAlertView("Operation Failed", err.error, "", "", true);
                    console.log(err);
                    setApiStatus("failure");
                    setErrorMsg(err.error);
                });
        } else {
            alert("Please select ids");
        }
    };
    const showAlertView = (
        title,
        body,
        messageId,
        buttonId,
        isError,
        buttonName
    ) => {
        setAlertDialogContent({
            title: title,
            message: body,
            messageId: messageId,
            buttonId: buttonId,
            buttonName: buttonName,
        });

        isError ? setAlertDialogShow(true) : setShowSnackbar(true);
    };
    const handleAddFolderClick = () => {
        Display(true);
        setAddFolderModalShow(true);
    };
    const closeAddFolderClick = () => {
        Display(false);
        setAddFolderModalShow(false);
        setError("");
        setNewFolderName("");
    };
    const renderSnackBar = () => {
        return (
            <>
                <CustomSnackbar
                    open={showSnackbar}
                    setOpen={setShowSnackbar}
                    alertMessage={alertDialogContent.message}
                ></CustomSnackbar>
                <Loader show={showLoader} />
            </>
        );
    };
    return (
        <>
            {addFolderModalShow ? (
                <AddFolderModal
                    showFolderModal={addFolderModalShow}
                    closeModal={closeAddFolderClick}
                    setText={setText}
                    addNewFolder={addNewFolder}
                    error={error}
                />
            ) : (
                <div style={{ minWidth: "300px" }}>
                    <div className={classes.root}>
                        <div
                            className="row w-100 m-0"
                            style={{ alignItems: "center" }}
                        >
                            <div className="row m-0 w-100">
                                <div
                                    className="col-11 pl-0"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {defaultStep === 1 ? (
                                        <span className="fontFamily">
                                            {heading.charAt(0).toUpperCase() +
                                                heading.slice(1).toLowerCase()}
                                        </span>
                                    ) : null}
                                </div>
                                <div className="col-1 p-0 doCenter">
                                    <button
                                        type="button"
                                        onClick={handleReset}
                                        class="close modalCloseIcon"
                                        data-dismiss="modal"
                                        id={"closeModalButton"}
                                    >
                                        &times;
                                    </button>
                                </div>
                            </div>
                        </div>
                        {defaultStep === 1 ? null : (
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>
                                                {label}
                                            </StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        )}
                        <div
                            className="formContainer"
                            style={{ paddingRight: "12px" }}
                        >
                            {activeStep === steps.length ? (
                                <div>
                                    <Typography
                                        className={classes.instructions}
                                    >
                                        <div
                                            className="marginTopTen doCenter"
                                            style={{
                                                fontWeight: "bold",
                                                margin: "50px",
                                            }}
                                        >
                                            {getUpdateStatus()}
                                        </div>
                                    </Typography>
                                </div>
                            ) : (
                                <div className="formContainerBody">
                                    <Typography
                                        className={classes.instructions}
                                    >
                                        {defaultStep
                                            ? renderDropdowns()
                                            : getStepContent(activeStep)}
                                    </Typography>
                                    {heading !== "Save SPACE" &&
                                        heading !== "Save FOLDER" &&
                                        heading !== "Save OBJECT" &&
                                        heading !== "Save FILE" &&
                                        heading !== "Move" &&
                                        heading !== "Upload File" && (
                                            <div
                                                style={{
                                                    marginTop: "-8px",
                                                    display: "flex",
                                                }}
                                                id="allow_download"
                                            >
                                                <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={
                                                        handleCheckboxChange
                                                    }
                                                />
                                                <p
                                                    style={{
                                                        margin: "5px 12px",
                                                    }}
                                                >
                                                    Allow other users to
                                                    download the documents
                                                </p>
                                            </div>
                                        )}
                                    {errorMsg !== "" ? (
                                        <span className="w-100 doCenter err_msg">
                                            {errorMsg}
                                        </span>
                                    ) : null}

                                    <div
                                        className="marginTopTen doCenter"
                                        style={{ margin: "16px" }}
                                    >
                                        {heading === "Share folder" ||
                                        heading === "Save FOLDER" ||
                                        heading === "Save FILE" ||
                                        heading === "Save OBJECT" ||
                                        (activeStep === 1 &&
                                            curruntAction === "createFilter") ||
                                        (activeStep === 1 &&
                                            curruntAction !== "createFilter" &&
                                            heading !== "Upload File" &&
                                            heading !== "Share space" &&
                                            heading === "Share file") ? (
                                            <Button
                                                id={"btn_addNewFolder_flow"}
                                                className={
                                                    dropdownSelectedWorkspace.text ===
                                                        "" &&
                                                    dropdownSelectedSpace.text ===
                                                        ""
                                                        ? "newFolderD"
                                                        : "newFolder"
                                                }
                                                disabled={
                                                    heading === "Share file" ||
                                                    dropdownSelectedWorkspace.text ===
                                                        "" ||
                                                    dropdownSelectedSpace.text ===
                                                        ""
                                                }
                                                onClick={handleAddFolderClick}

                                                // className={classes.button}
                                            >
                                                New Folder
                                            </Button>
                                        ) : null}

                                        <Button
                                            id={
                                                heading === "Move"
                                                    ? "button_modal_cancel"
                                                    : ""
                                            }
                                            disabled={
                                                heading === "Upload File"
                                                    ? null
                                                    : activeStep === 0
                                            }
                                            onClick={handleBack}
                                            // className={classes.button}
                                            variant={
                                                defaultStep
                                                    ? "outlined"
                                                    : "contained"
                                            }
                                            style={{
                                                margin: "5px",
                                                minWidth: "5rem",
                                                borderRadius: "10px",
                                                height: "2.5rem",
                                            }}
                                        >
                                            {activeStep === steps.length - 1
                                                ? "No"
                                                : defaultStep
                                                ? "Cancel"
                                                : "Back"}
                                        </Button>
                                        <Button
                                            id={
                                                heading === "Move"
                                                    ? "btn_Move_form"
                                                    : "btn_Confirm_form"
                                            }
                                            variant="contained"
                                            color="primary"
                                            onClick={
                                                activeStep === 2
                                                    ? handleSubmit
                                                    : heading === "Move"
                                                    ? handleMoveFile
                                                    : heading === "Upload File"
                                                    ? handleUpload
                                                    : defaultStep
                                                    ? handleAttachment
                                                    : handleNext
                                            }
                                            // className={classes.button}
                                            className="bw_btn"
                                            disabled={
                                                // dropdownSelectedWorkspace.id ===
                                                //     "" ||
                                                // dropdownSelectedSpace.id === ""
                                                //     ? true
                                                // :
                                                heading === "Upload File"
                                                    ? isButtonDisable()
                                                    : heading === "Move"
                                                    ? isButtonDisableMove()
                                                    : isButtonDisable()
                                            }
                                            style={{
                                                margin: "5px",
                                                minWidth: "5rem",
                                            }}
                                        >
                                            {activeStep === steps.length - 1
                                                ? "Yes"
                                                : getButtonName()}
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {renderSnackBar()}
                </div>
            )}
            <AlertDialog
                open={alertDialogShow}
                setOpen={setAlertDialogShow}
                title={alertDialogContent.title}
                message={alertDialogContent.message}
                isAlert={alertDialogContent.isAlert}
                messageId={alertDialogContent.messageId}
                buttonId={alertDialogContent.buttonId}
                buttonName={alertDialogContent.buttonName}
            ></AlertDialog>
        </>
    );
}

const mapDispatchToProps = () => {
    return {
        fetchFolderListData,
        fetchWorkspaceListData,
    };
};

const mapStateToProps = (state) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps())(FormWrapper);
