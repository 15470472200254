import http from "../../../../../bae-client/HTTP";

export const GET = async (url) => {
    try {
        // const token = sessionStorage.getItem("token");
        // const response = await fetch(URL + url, {
        //     method: "GET",
        //     headers: {
        //         "Content-Type": "application/json",
        //         "Access-Control-Allow-Origin": "*",
        //         Authorization: `${token}`,
        //     },
        // });
        // return await response.json();
        const response = await http.sendRestRequest(url, "GET");
        return response;
    } catch (error) {
        console.log("Error in fetching data", error);
        return error;
    }
};

export const POST = async (url, data) => {
    try {
        const response = await http.sendRestRequest(url, "POST", data);
        return response;
    } catch (error) {
        console.log("Error in fetching data", error);
        return error;
    }
};
