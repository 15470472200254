import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { getIcon } from "./helper";
import http from "../../../../../../bae-client/HTTP";
import { setActivePageAction } from "../../../../../../services/services/app";
import { useDispatch } from "react-redux";
import { setShowSettingWs } from "../../../../../../actions/Rippleaction";

const CustomSearch = ({
    setSeeAllBtn,
    handleActivityClick,
    setCurrentWorkspaceSearch,
    renderDocumentDisplayModal,
    setListRowData,
    setDebouncedSearch,
    debouncedSearch,
    handleClickResult,
    setRowsPerPage,
    showDropdown,
    setShowDropdown,
    setPage,
    setLoading,
    breadcrumbData,
    handleSearchClear=()=>{},
    setSearch=()=>{},
    search
}) => {
   

    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedSearch(search);
        }, 500);
        return () => {
            clearTimeout(handler);
        };
    }, [search]);
    useEffect(() => {
        if (debouncedSearch) {
            let url = "workspace/search";
            let body = {
                page: 1,
                pageSize: 8,
                keyword: debouncedSearch,
            };
            if(breadcrumbData && breadcrumbData?.length){
                body.workspaceId=breadcrumbData[0].id
                if(breadcrumbData.length === 2){
                    body.spaceId=breadcrumbData[1].id
                }
                if(breadcrumbData.length >2){
                    body.spaceId=breadcrumbData[1].id
                    body.folderId=breadcrumbData[breadcrumbData.length-1].id
    
                }
            }
            setLoading(true);
            http.sendRestRequest(url, "POST", body)
                .then((resp) => {
                    setLoading(false);
                    setFilteredData(resp.data);
                    setShowDropdown(true);
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                });
        } else {
            setShowDropdown(false);
            setSeeAllBtn(false);
            setListRowData([]);
            handleSearchClear();
        }
    }, [debouncedSearch]);
    const handleOnChange = (e) => {
        setSearch(e.target.value);
    };
    function getLastClickParameter(clickActionParameters) {
        return clickActionParameters
            ? clickActionParameters.split("/").pop()
            : null;
    }
    const handleRowClick = (rowObject) => {
        setShowDropdown(false);

        // TODO : Navigate to the selected item
    };

    useEffect(() => {
        inputRef.current.focus();
    }, []);
    // Close dropdown on outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                inputRef.current !== event.target
            ) {
                setShowDropdown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () =>
            document.removeEventListener("mousedown", handleClickOutside);
    }, []);
    return (
        <div
            className="search-container"
            ref={dropdownRef}
            style={{ display: "flex", alignItems: "center" }}
        >
            <div className="search-input-wrapper">
                <input
                    className="search-input"
                    type="text"
                    placeholder="Search files and folders"
                    ref={inputRef}
                    value={search}
                    onChange={handleOnChange}
                />
                <span className="search-icon">
                    <img src="./images/Union.svg" alt="s" title="search" />
                </span>
            </div>
            <span
                className="clear-icon"
                onClick={() => {
                    setSearch("");
                }}
            >
                <img src="./images/x.svg" alt="clear" />
            </span>

            {showDropdown && (
                <div className="search-dropdown">
                    {filteredData.length > 0 ? (
                        <>
                            {filteredData.map((item) => (
                                <div
                                    className="search-item"
                                    key={item.objectId}
                                    onClick={() => handleRowClick(item)}
                                >
                                    <div className="search-item-icon">
                                        <img
                                            style={{
                                                width: "32px",
                                                height: "32px",
                                            }}
                                            src={getIcon(item)}
                                            alt="s"
                                        />
                                    </div>
                                    <div className="search-item-data">
                                        <div
                                            title={item.objectName}
                                            className="search-item-name textEllipsis"
                                            style={{
                                                cursor: "pointer",
                                                width: "400px",
                                            }}
                                            onClick={(event) => {
                                                if (
                                                    item.objectType ===
                                                        "FILE" ||
                                                    item.objectType ===
                                                        "EMAIL" ||
                                                    item.objectType ===
                                                        "EMAIL_ATTACHMENT"
                                                ) {
                                                    renderDocumentDisplayModal(
                                                        getLastClickParameter(
                                                            item.clickActionParameters
                                                        )
                                                    );
                                                    setShowDropdown(false);
                                                } else if (
                                                    item.objectType ===
                                                    "WORKSPACE"
                                                ) {
                                                    dispatch(
                                                        setShowSettingWs(false)
                                                    );
                                                    dispatch(
                                                        setActivePageAction(
                                                            "Workspace"
                                                        )
                                                    );
                                                    setCurrentWorkspaceSearch({
                                                        ...item,
                                                        workspaceId:
                                                            item.objectId,
                                                        workspaceName:
                                                            item.objectName,
                                                    });
                                                } else {
                                                    handleActivityClick(item);
                                                    dispatch(
                                                        setShowSettingWs(false)
                                                    );
                                                }
                                            }}
                                        >
                                            {item.objectName}
                                        </div>
                                        <div
                                            title={item.objectLocation
                                                .split("/")
                                                .join(" > ")}
                                            className="search-item-path textEllipsis"
                                            style={{
                                                width: "400px",
                                                fontFamily: "Montserrat",
                                                fontWeight: "bolder",
                                                lineHeight: "12px",
                                                letterSpacing: "-0.02em",
                                            }}
                                        >
                                            {item.objectLocation
                                                .split("/")
                                                .join(" > ")}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div
                                className="search-all-item"
                                onClick={() => {
                                    handleRowClick(null);
                                    setSeeAllBtn(true);
                                    handleClickResult();
                                    setRowsPerPage(10);
                                    setPage(0);
                                }}
                                title="See All Results"
                            >
                                <span>See All Results</span>
                            </div>
                        </>
                    ) : (
                        <div className="search-item">No results found</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default CustomSearch;
