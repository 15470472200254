import React, { useEffect, useState } from "react";
import CustomTable from "./CustomTable";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import "./Workspaces.css";
import LeftActions from "./LeftActions";
import Breadcrumb from "./Breadcrumb";
import http from "../../../../../bae-client/HTTP";
import {
    setRefreshLocation,
    fetchDirectoriesAction,
    fetchDirectoryUsersAction,
    fetchSpaceListAction,
    setActiveWorkspaceAction,
    setEmailRecievedAction,
    setSnackbarAction,
    setSpaceListAction,
    setActivePageAction,
    setWorkspaceRedirectionAction,
} from "../../../../../services/services/app";
import Loader from "../../../../stylized/Loader";
import { columns, stubData, stubData2 } from "./constant";
import ModalView from "../../../../stylized/DocumentViewerModal";
import DocViwer from "../../../../stylized/DocViwer";
import RightActions from "./RightActions";
import ComposerModal from "../WorkspaceContainer/ComposerModal";
import moment from "moment";
import { useUsersQuery } from "../../../../../services/services/usersAPI";
import EmptyTable from "./EmptyTable";
import { selectVideoPlaylistVideoTrackByPeerID } from "@100mslive/react-sdk";
import { type } from "jquery";
import CustomSearch from "./searchbar/CustomSearch";
import WorkSpaceFilterTable from "./WorkspaceFilterTable/WorkspaceFilterTable";
import { setActivePage } from "../../../../../actions";
import AlertDialog from "../../../../stylized/AlertDialog";

export const convertUTCToLocal = (date) => {
    if (date) {
        const temp = date.split(".");
        const dateToConvert = temp[0];
        const localDate = moment
            .utc(dateToConvert)
            .local()
            .format("MM/DD/YYYY hh:mm A");
        return localDate;
    }
};
const getOwnerName = (data, sharedBy) => {
    if (data && data.length) {
        const sharedByUser = data.find((user) => user.userId === sharedBy);
        if (sharedByUser) {
            return sharedByUser?.firstName + " " + sharedByUser?.lastName;
        }
        return "";
    }
};

const Workspaces = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");
    const spaces = useSelector((state) => state.spaceListData);
    const refreshLocation = useSelector((state) => state.refreshLocation);
    const activeWorkspace = useSelector((state) => state.activeWorkspace);
    // const directories = useSelector((state) => state.directories);
    const redirectionObject = useSelector((state) => state.redirectionObject);
    const emailRecieved = useSelector((state) => state.emailRecieved);
    const [selectedFileData, setSelectedFileData] = useState(null);
    const { data: users } = useUsersQuery();
    const [isCheckComposer, setIsCheckComposer] = useState(false);
    const [noteText, setNoteText] = useState("");
    const [checkType, setCheckType] = useState("");
    const [loading, setLoading] = useState(false);
    const [activeSpace, setActiveSpace] = useState(null);
    const [rowsSelected, setRowsSelected] = useState([]);
    const [selectedValues, setSelectedValues] = useState([]);
    const [breadcrumbData, setBreadcrumbData] = useState([]);
    const [openSpace, setOpenSpace] = useState(false);
    const [spaceTableData, setSpaceTableData] = useState(null);
    const [filteredRow, setFilteredRow] = useState(null);
    const [showDocumentModal, setShowDocumentModal] = useState(false);
    const [showAlertDialog, setShowAlertDialog] = useState(false);
    const [alertDialogMessage, setAlertDialogMessage] = useState("");
    const [fileName, setFileName] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [fileLocation, setFileLocation] = useState(null);
    const [ComposerModalShow, setComposerModalShow] = useState(false);
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("");
    const [page, setPage] = React.useState(0);
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [search, setSearch] = useState("");
    const [listRowData, setListRowData] = useState([]);
    const [seeAllBtn, setSeeAllBtn] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [searchResultPage, setSearchResultPage] = React.useState(0);
    const [searchResultRowsPerPage, setSearchResultRowsPerPage] =
        React.useState(10);
    const workspaceList = useSelector((state) => state.workspaceList);

    useEffect(() => {
        if (emailRecieved) {
            const activeFolder = breadcrumbData[breadcrumbData.length - 1];
            const targetFolders = emailRecieved
                .split(",")
                .map((folderId) => parseInt(folderId));
            if (targetFolders.includes(parseInt(activeFolder?.id))) {
                fetchFolderData(activeFolder, true);
                dispatch(setEmailRecievedAction(null));
            }
        }
    }, [emailRecieved]);
    useEffect(() => {
        setDebouncedSearch("");
        setSearch("");
    }, [activeWorkspace]);
    useEffect(() => {
        if (refreshLocation) {
            let activeLocation = breadcrumbData[breadcrumbData.length - 1];
            if (
                activeLocation.type === "workspace" &&
                activeLocation.id === refreshLocation.workspaceId
            ) {
                dispatch(fetchSpaceListAction(refreshLocation.workspaceId));
            } else if (
                activeLocation.type === "space" &&
                activeLocation.id === refreshLocation.spaceId
            ) {
                let data = {};
                data.spaceId = refreshLocation.spaceId;
                data.id = refreshLocation.folderId
                    ? refreshLocation.folderId
                    : 0;
                fetchFolderData(data, true, true);
            } else {
                if (activeLocation.id === refreshLocation.folderId) {
                    let data = {};
                    data.spaceId = refreshLocation.spaceId;
                    data.id = refreshLocation.folderId;
                    fetchFolderData(data, true, true);
                }
            }
        }
        dispatch(setRefreshLocation(null));
    }, [refreshLocation]);

    useEffect(() => {
        dispatch(fetchDirectoriesAction());
        return () => {
            dispatch(setActiveWorkspaceAction(null));
            dispatch(setSpaceListAction([]));
            localStorage.removeItem("redirectionObject");
        };
    }, []);
    useEffect(() => {
        setActiveSpace(null);
        setRowsSelected([]);
        setSelectedValues([]);
        setBreadcrumbData([]);
        setOpenSpace(false);
        setSpaceTableData(null);
        // if (spaces && spaces.length) {
        if (activeWorkspace) {
            if (!redirectionObject) {
                createSpaceTableRows(spaces);
                setBreadcrumbData([
                    {
                        id: activeWorkspace.workspaceId,
                        name: activeWorkspace.workspaceName,
                        type: "workspace",
                        parentId: 0,
                        sharedBy: activeWorkspace?.sharedBy,
                    },
                ]);
            }
        }
    }, [spaces]);
    useEffect(() => {
        if (openSpace) setSpaceTableData(null);
    }, [openSpace]);
    // useEffect(() => {
    //     if (directories && directories.length)
    //         dispatch(fetchDirectoryUsersAction(directories[0].directoryId));
    // }, [directories]);

    const handleDocumentModalClose = () => {
        setShowDocumentModal(false);
        setFileLocation(null);
        setFileName("");
        setIsChecked(false);
    };
    const fetchFolderData = (row, isBreadcrumbClick, isRedirection, type) => {
        if (row?.type === "email" || type === "email") {
            const url = `emailFiles/attachments?spaceId=${
                row?.spaceId ? row?.spaceId : activeSpace?.id
            }&emailFileId=${row?.id}`;
            http.sendRestRequest(url)
                .then((response) => {
                    if (!isBreadcrumbClick && !redirectionObject)
                        setBreadcrumbData((state) => [
                            ...state,
                            {
                                id: row.id,
                                name: row.name,
                                type: row.type,
                                parentId: activeWorkspace.workspaceId,
                                sharedBy: row?.sharedBy,
                            },
                        ]);
                    createFolderTableRows(response, row);
                    setLoading(false);
                    dispatch(setWorkspaceRedirectionAction(null));
                })
                .catch((error) => {
                    setLoading(false);
                    handleBreadcrumbClick();
                });
        } else {
            const url = isRedirection
                ? `folders/folderData?spaceId=${row.spaceId}&folderId=${row.id}`
                : `folders/folderData?spaceId=${
                      activeSpace ? activeSpace.id : row.id
                  }&folderId=${row.type === "space" ? 0 : row.id}`;
            setSpaceTableData(null);
            setLoading(true);
            http.sendRestRequest(url)
                .then((response) => {
                    if (!isBreadcrumbClick && !redirectionObject)
                        setBreadcrumbData((state) => [
                            ...state,
                            {
                                id: row.id,
                                name: row.name,
                                type: row.type,
                                parentId: activeWorkspace.workspaceId,
                                sharedBy: row?.sharedBy,
                            },
                        ]);
                    createFolderTableRows(response);
                    setLoading(false);
                    dispatch(setWorkspaceRedirectionAction(null));
                })
                .catch((error) => {
                    setLoading(false);
                    handleBreadcrumbClick(
                        null,
                        breadcrumbData[breadcrumbData.length - 1]
                    );
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            severity: "error",
                            message:
                                error?.error ||
                                "Error while fetching folder data",
                        })
                    );
                });
        }
    };

    const fetchFileData = (row) => {
        const activeFolder = breadcrumbData[breadcrumbData.length - 1];
        setSelectedFileData({
            workSpaceId: activeWorkspace.workspaceId,
            spaceId: activeSpace?.id,
            documentId: row.documentId,
            folderId: activeFolder.type === "folder" ? activeFolder.id : 0,
        });
        setLoading(true);
        http.sendRestRequest(
            `document/documentDetail?documentId=${row.documentId}`
        )
            .then((response) => {
                setLoading(false);
                setFileName(row.name);
                setIsChecked(row.allowDownload);
                if (response && response.documentStream) {
                    const fileType = response.documentName.split(".").pop();

                    if (row.isEditable && fileType === "html") {
                        setIsCheckComposer(true);
                        const value = decodeBase64(response.documentStream);
                        setNoteText(value);
                    } else {
                        setShowDocumentModal(true);
                        if (fileType === "pdf") {
                            setTimeout(() => {
                                setFileLocation(response);
                            }, 1);
                        } else {
                            setFileLocation(response);
                        }
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                dispatch(
                    setSnackbarAction({
                        open: true,
                        severity: "error",
                        message:
                            error?.error || "Error while fetching file data",
                    })
                );
            });
    };
    const recentViews = (id, name, type) => {
        // setShowLoader(true);
        let url = "userInteractions/workspace/recentlyViewed";
        let viewdata = {
            objectId: id,
            objectName: name,
            objectType: type,
            spaceId: activeSpace ? activeSpace.id : id,
        };
        let data = {
            workspaceId: activeWorkspace.workspaceId,
            description: JSON.stringify(viewdata),
        };
        http.sendRestRequest(url, "POST", data)
            .then((resp) => {
                // setShowLoader(false);
                // fetchFolderListData(selectedSpace.id);
            })
            .catch((err) => {
                // setShowLoader(false);
            });
    };
    const decodeBase64 = (base64String) => {
        try {
            return atob(base64String);
        } catch (e) {
            console.error("Failed to decode Base64 string", e);
            return "";
        }
    };

    const cellClickAction = (e, row, isStarr) => {
        e.stopPropagation();
        if (row.type.toLowerCase() === "email" && isStarr) {
            return;
        }
        if (isStarr) {
            let url = `${row.type === "space" ? "space" : "folders"}/starred`;
            let data = null;
            if (row.type === "space")
                data = {
                    spaceId: parseInt(row.id),
                    isStarred: !row.isStarred,
                };
            else
                data = {
                    folderRegistryId: parseInt(row.id),
                    isStarred: !row.isStarred,
                };
            setLoading(true);
            http.sendRestRequest(url, "PUT", data)
                .then((resp) => {
                    setLoading(false);
                    const b_len = breadcrumbData.length;
                    if (b_len > 1) {
                        fetchFolderData(breadcrumbData[b_len - 1], true);
                    } else
                        dispatch(
                            fetchSpaceListAction(activeWorkspace.workspaceId)
                        );
                })
                .catch((err) => {
                    setLoading(false);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            severity: "error",
                            message:
                                err?.error ||
                                "Error while adding starred items",
                        })
                    );
                });
        } else if (row.type) {
            if (breadcrumbData.length === 1) {
                setOpenSpace(true);
                setActiveSpace(row);
            }
            if (row.type !== "file" && row.type !== "email_attachment") {
                setPage(0);
                setSpaceTableData(null);
                recentViews(row.id, row.name, row.type);
                fetchFolderData(row);
            } else fetchFileData(row);
        }
    };
    const createFolderTableRows = (folders, preFillData) => {
        let rows = preFillData ? [{ ...preFillData, type: "file" }] : [];
        folders?.forEach((element) => {
            rows.push({
                id: element.objectId,
                name: element.objectName,
                createdOn: element.createdOn,
                updatedOn: element.updatedOn,
                isShared: element.isShared ? "Shared" : "Personal",
                owner: getOwnerName(users, element.sharedBy),
                isStarred: element.isStarred,
                type: element.objectType.toLowerCase(),
                documentId: element.documentId > 0 ? element.documentId : null,
                isEditable: element.isEditable,
                allowDownload: element.allowDownload,
                sharedBy: element.sharedBy,
            });
        });
        setSpaceTableData({ columns, rows });
    };
    const createSpaceTableRows = (spaces) => {
        let rows = [];
        spaces.forEach((space) => {
            rows.push({
                id: space.spaceId,
                name: space.spaceName,
                // updatedOn: convertUTCToLocal(element.updatedOn),
                // createdOn: convertUTCToLocal(element.createdOn),
                createdOn: space.createdOn,
                updatedOn: space.updatedOn,
                isShared: space.isShared ? "Shared" : "Personal",
                owner: getOwnerName(users, space.sharedBy),
                isStarred: space.isStarred,
                type: "space",
                documentId: null,
                sharedBy: space.sharedBy,
            });
        });
        setSpaceTableData({ columns, rows });
    };
    const setCurrentWorkspace = (workspace) => {
        localStorage.setItem("workspaceId", workspace.workspaceId);
        http.sendRestRequest(
            `workspace/spaceList?workspaceId=${workspace.workspaceId}`
        )
            .then((response) => {
                dispatch(setActiveWorkspaceAction(workspace));
                dispatch(setSpaceListAction(response));
                dispatch(setActivePageAction("Workspace"));
            })
            .catch((err) => console.log(err));
    };
    const handleBreadcrumbClick = (event, item) => {
        event?.preventDefault();
        if (!item) return;
        setRowsSelected([]);
        setSelectedValues([]);
        const indexOfClickedItem = breadcrumbData.indexOf(item);
        setBreadcrumbData((breadcrumb) =>
            breadcrumb.slice(0, indexOfClickedItem + 1)
        );
        if (indexOfClickedItem === 0) {
            setCurrentWorkspace(activeWorkspace);
            setOpenSpace(false);
            setPage(0);
            createSpaceTableRows(spaces);
            setActiveSpace(null);
        } else if (indexOfClickedItem === 1) {
            fetchFolderData({ id: 0, name: item.name }, true);
        } else {
            fetchFolderData(item, true);
        }
        setSeeAllBtn(false);
    };
    const handleSearchClear = () => {
        handleBreadcrumbClick(null, breadcrumbData[breadcrumbData.length - 1]);
    };
    const handleRedirection = (redirectionObject, type) => {
        setRowsSelected([]);
        setSelectedValues([]);
        setBreadcrumbData(redirectionObject.breadCrumbItems);
        if (redirectionObject.type === "email") {
            fetchFolderData(
                {
                    id: redirectionObject.folderId,
                    name: redirectionObject.folderName,
                    spaceId: redirectionObject.activeSpace.id,
                    allowDownload: redirectionObject.allowDownload,
                    createdOn: redirectionObject.createdOn,
                    documentId: redirectionObject.documentId,
                    //   id: folderFoundData,
                    isEditable: redirectionObject.isEditable,
                    isShared: redirectionObject.isShared,
                    isStarred: redirectionObject.isStarred,
                    owner: redirectionObject.owner,
                    sharedBy: redirectionObject.sharedBy,
                    type: "email",
                    updatedOn: redirectionObject.updatedOn,
                },
                true,
                true,
                redirectionObject.type
            );
        } else {
            fetchFolderData(
                {
                    id: redirectionObject.folderId,
                    name: redirectionObject.folderName,
                    spaceId: redirectionObject.activeSpace.id,
                },
                true,
                true
            );
        }

        setActiveSpace(redirectionObject.activeSpace);
    };

    const onSearchHandler = (eventOrValue) => {
        const searchText =
            typeof eventOrValue === "string"
                ? eventOrValue // Direct string input
                : eventOrValue?.target?.value?.trim().toLowerCase() || ""; // Handle undefined event

        setSearchText(searchText);

        if (spaceTableData?.rows?.length) {
            const filteredRows = searchText
                ? spaceTableData.rows.filter((row) =>
                      row.name.toLowerCase().includes(searchText)
                  )
                : spaceTableData.rows; // Restore full list when search is cleared

            setFilteredRow({ columns, rows: filteredRows });
        }
    };

    const resetSearch = () => {
        setSearchText("");
        setFilteredRow({ columns, rows: spaceTableData?.rows || [] });
    };

    useEffect(() => {
        if (redirectionObject) {
            let basePathName = window.runtimeConfig.instanceName
                ? "/" + window.runtimeConfig.instanceName
                : "";
            localStorage.setItem("redirectionObject", true);
            http.sendRestRequest(
                `workspace/spaceList?workspaceId=${redirectionObject?.activeWorkspace?.workspaceId}`
            )
                .then((resp) => {
                    dispatch(setSpaceListAction(resp));
                    navigate(`${basePathName}/workspace`);
                    dispatch(
                        setActiveWorkspaceAction(
                            redirectionObject.activeWorkspace
                        )
                    );
                    setActiveSpace(redirectionObject.activeSpace);
                    handleRedirection(redirectionObject);
                })
                .catch((err) => {
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            severity: "error",
                            message: err?.error || "Error while redirecting",
                        })
                    );
                });
        }
    }, [redirectionObject]);
    const renderWorkSpaceContent = () => {
        return (
            <>
                {breadcrumbData.some((item) => item.name === "Flow") ? (
                    <div className="workspace-icons">
                        <RightActions
                            resetSearch={resetSearch}
                            searchText={searchText}
                            onSearchHandler={onSearchHandler}
                            handleRedirection={handleRedirection}
                            breadcrumb={breadcrumbData.some(
                                (item) => item.name === "Flow"
                            )}
                            breadcrumbData={breadcrumbData}
                            rowsSelected={rowsSelected}
                            currentRows={
                                spaceTableData ? spaceTableData.rows : []
                            }
                        />
                    </div>
                ) : breadcrumbData.some((item) => item.name === "Emails") ? (
                    <div className="workspace-icons">
                        {breadcrumbData.length > 1 &&
                            rowsSelected.length > 0 && (
                                <LeftActions
                                    checkType={checkType}
                                    isEmail={
                                        breadcrumbData.some(
                                            (item) => item.name === "Emails"
                                        )
                                            ? true
                                            : false
                                    }
                                    isSelection={rowsSelected.length > 0}
                                    isSpace={breadcrumbData.length > 1}
                                    isFolder={openSpace}
                                    isFile={false}
                                    setNoteText={setNoteText}
                                    setFileName={setFileName}
                                    currentRows={
                                        spaceTableData
                                            ? spaceTableData.rows
                                            : []
                                    }
                                    activeSpace={activeSpace}
                                    selectedValues={selectedValues}
                                    parent={
                                        breadcrumbData.length === 1
                                            ? breadcrumbData[0]
                                            : breadcrumbData.length > 2
                                            ? breadcrumbData[
                                                  breadcrumbData.length - 1
                                              ]
                                            : breadcrumbData[1]
                                    }
                                    fetchFolderData={fetchFolderData}
                                    selectedRows={rowsSelected}
                                    setComposerModalShow={setComposerModalShow}
                                />
                            )}
                        {
                            <RightActions
                                resetSearch={resetSearch}
                                searchText={searchText}
                                onSearchHandler={onSearchHandler}
                                handleRedirection={handleRedirection}
                                breadcrumb={breadcrumbData.some(
                                    (item) => item.name === "Flow"
                                )}
                                breadcrumbData={breadcrumbData}
                                rowsSelected={rowsSelected}
                                currentRows={
                                    spaceTableData ? spaceTableData.rows : []
                                }
                            />
                        }
                    </div>
                ) : (
                    <div className="workspace-icons">
                        <LeftActions
                            checkType={checkType}
                            isSelection={rowsSelected.length > 0}
                            isSpace={breadcrumbData.length > 1}
                            isFolder={openSpace}
                            isFile={false}
                            setNoteText={setNoteText}
                            setFileName={setFileName}
                            currentRows={
                                spaceTableData ? spaceTableData.rows : []
                            }
                            activeSpace={activeSpace}
                            selectedValues={selectedValues}
                            parent={
                                breadcrumbData.length === 1
                                    ? breadcrumbData[0]
                                    : breadcrumbData.length > 2
                                    ? breadcrumbData[breadcrumbData.length - 1]
                                    : breadcrumbData[1]
                            }
                            fetchFolderData={fetchFolderData}
                            selectedRows={rowsSelected}
                            setComposerModalShow={setComposerModalShow}
                        />
                        <RightActions
                            resetSearch={resetSearch}
                            handleRedirection={handleRedirection}
                            breadcrumbData={breadcrumbData}
                            rowsSelected={rowsSelected}
                            currentRows={
                                spaceTableData ? spaceTableData.rows : []
                            }
                            activeSpace={activeSpace}
                        />
                    </div>
                )}
                <div className="workspace-content">
                    {!spaceTableData || !spaceTableData.rows ? (
                        <Box sx={{ width: "100%", height: "100%" }}>
                            <Paper
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                                elevation={3}
                            ></Paper>
                        </Box>
                    ) : spaceTableData.rows.length === 0 ? (
                        <EmptyTable
                            isWorkspace={breadcrumbData.length === 1}
                            fetchFolderData={fetchFolderData}
                            activeSpace={activeSpace}
                            breadcrumbData={breadcrumbData}
                            parent={
                                breadcrumbData.length === 1
                                    ? breadcrumbData[0]
                                    : breadcrumbData.length > 2
                                    ? breadcrumbData[breadcrumbData.length - 1]
                                    : breadcrumbData[1]
                            }
                        />
                    ) : (
                        <CustomTable
                            order={order}
                            page={page}
                            setPage={setPage}
                            setOrder={setOrder}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            setCheckType={setCheckType}
                            breadcrumbData={breadcrumbData}
                            rowsSelected={rowsSelected}
                            setRowsSelected={setRowsSelected}
                            setSelectedValues={setSelectedValues}
                            onSearchHandler={onSearchHandler}
                            spaceTableData={spaceTableData}
                            searchText={searchText}
                            selectedValues={selectedValues}
                            filteredRow={filteredRow}
                            setSearchText={setSearchText}
                            cellClickAction={cellClickAction}
                        />
                    )}
                </div>
            </>
        );
    };
    const handleChangePage = (event, newPage) => {
        setSearchResultPage(newPage);
        handleClickResult(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setSearchResultRowsPerPage(+event.target.value);
        setSearchResultPage(0);
        handleClickResult(0, +event.target.value);
    };

    const handleActivityClick = (item, isRedirect) => {
        if (item?.objectType === "SPACE") {
            const spaceId = item?.objectId;
            const idArray = item?.clickActionParameters?.split("/") || [];
            const workspaceId = idArray.length > 0 ? idArray[0] : null;
            const resWorkspace = workspaceList.find(
                (workspace) =>
                    parseInt(workspace.workspaceId) === parseInt(workspaceId)
            );

            http.sendRestRequest(
                `workspace/spaceList?workspaceId=${workspaceId}`
            )
                .then((spaces) => {
                    const space = spaces.find(
                        (space) => space.spaceId === spaceId
                    );
                    if (!space) {
                        dispatch(
                            setSnackbarAction({
                                open: true,
                                message: `Space Got Archived/Deleted!`,
                                severity: "error",
                            })
                        );
                        return;
                    } else {
                        const breadCrumbItems = [
                            {
                                id: resWorkspace?.workspaceId,
                                name: resWorkspace?.workspaceName,
                                type: "workspace",
                                parentId: 0,
                            },
                        ];
                        breadCrumbItems.push({
                            id: space?.spaceId,
                            name: space?.spaceName,
                            type: "space",
                            parentId: resWorkspace?.workspaceId,
                        });
                        let activeSpace = {
                            id: space?.spaceId,
                            name: space?.spaceName,
                            updatedOn: space?.updatedOn,
                            createdOn: space?.createdOn,
                            isShared: space?.isShared ? "Shared" : "Personal",
                            isStarred: space?.isStarred,
                            type: "space",
                            documentId: null,
                        };
                        dispatch(
                            setWorkspaceRedirectionAction(
                                {
                                    activeSpace: activeSpace,
                                    breadCrumbItems: breadCrumbItems,
                                    folderId: 0,
                                    folderName: activeSpace?.name,
                                    activeWorkspace: {
                                        workspaceId: resWorkspace?.workspaceId,
                                        workspaceName:
                                            resWorkspace?.workspaceName,
                                    },
                                },
                                true
                            )
                        );
                        dispatch(setActivePage("Workspace"));
                        setSeeAllBtn(false);
                    }
                })
                .catch((error) => {
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: error.error,
                            severity: "error",
                        })
                    );
                });
        } else if (item?.objectType === "FOLDER") {
            const folderId = item?.objectId;
            const idArray = item?.clickActionParameters?.split("/") || [];
            let folderName = "";
            setLoading(true);
            http.sendRestRequest(`folders/folderDetails?folderId=${folderId}`)
                .then((resp) => {
                    setLoading(false);
                    const folderIdArray = resp.folderIdPath.split("/");
                    let parentFolderId = 0;
                    if (folderIdArray.length !== 1) {
                        parentFolderId =
                            folderIdArray[folderIdArray.length - 2];
                    }
                    const folderNameArray = resp.folderNamePath.split("/");
                    const spaceId = resp.spaceId;
                    const workspaceId = idArray[0];
                    const resWorkspace = workspaceList.find(
                        (workspace) =>
                            parseInt(workspace.workspaceId) ===
                            parseInt(workspaceId)
                    );
                    http.sendRestRequest(
                        `folders/folderData?spaceId=${spaceId}&folderId=${parentFolderId}`
                    )
                        .then((res) => {
                            let isFolderPresent = res.find(
                                (item) => item.objectId === folderId
                            );
                            if (!isFolderPresent) {
                                dispatch(
                                    setSnackbarAction({
                                        open: true,
                                        message: `Folder Got Archived!`,
                                        severity: "error",
                                    })
                                );
                                setLoading(false);
                                return;
                            } else {
                                http.sendRestRequest(
                                    `workspace/spaceList?workspaceId=${workspaceId}`
                                )
                                    .then((spaces) => {
                                        const space = spaces.find(
                                            (space) => space.spaceId === spaceId
                                        );
                                        if (!space) {
                                            dispatch(
                                                setSnackbarAction({
                                                    open: true,
                                                    message: `Space Got Deleted!`,
                                                    severity: "error",
                                                })
                                            );
                                            return;
                                        } else {
                                            const breadCrumbItems = [
                                                {
                                                    id: resWorkspace?.workspaceId,
                                                    name: resWorkspace?.workspaceName,
                                                    type: "workspace",
                                                    parentId: 0,
                                                },
                                            ];

                                            breadCrumbItems.push({
                                                id: space?.spaceId,
                                                name: space?.spaceName,
                                                type: "space",
                                                parentId:
                                                    resWorkspace?.workspaceId,
                                            });
                                            let activeSpace = {
                                                id: space?.spaceId,
                                                name: space?.spaceName,
                                                updatedOn: space?.updatedOn,
                                                createdOn: space?.createdOn,
                                                isShared: space?.isShared
                                                    ? "Shared"
                                                    : "Personal",
                                                isStarred: space?.isStarred,
                                                type: "space",
                                                documentId: null,
                                            };
                                            folderIdArray.forEach(
                                                (element, index) => {
                                                    if (
                                                        parseInt(folderId) ===
                                                        parseInt(element)
                                                    ) {
                                                        folderName =
                                                            folderNameArray[
                                                                index
                                                            ];
                                                    }
                                                    breadCrumbItems.push({
                                                        id: parseInt(element),
                                                        name: folderNameArray[
                                                            index
                                                        ],
                                                        type: "folder",
                                                        parentId:
                                                            index === 0
                                                                ? 0
                                                                : parseInt(
                                                                      folderIdArray[
                                                                          index -
                                                                              1
                                                                      ]
                                                                  ),
                                                    });
                                                }
                                            );
                                            const redirectionObject = {
                                                breadCrumbItems:
                                                    breadCrumbItems,
                                                folderId: parseInt(folderId),
                                                folderName,
                                                type: "folder",
                                                activeSpace: activeSpace,
                                                activeWorkspace: {
                                                    workspaceId:
                                                        resWorkspace?.workspaceId,
                                                    workspaceName:
                                                        resWorkspace?.workspaceName,
                                                },
                                            };
                                            dispatch(
                                                setWorkspaceRedirectionAction(
                                                    redirectionObject,
                                                    true
                                                )
                                            );
                                            dispatch(
                                                setActivePage("Workspace")
                                            );
                                            setSeeAllBtn(false);
                                        }
                                        setLoading(false);
                                    })
                                    .catch((err) => {
                                        setLoading(false);
                                        console.log(err);
                                        dispatch(
                                            setSnackbarAction({
                                                open: true,
                                                message: `Space Got Deleted!`,
                                                severity: "error",
                                            })
                                        );
                                        return;
                                    });
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                            setLoading(false);
                        });
                })
                .catch((err) => {
                    setLoading(false);
                    console.log(err);
                    dispatch(
                        setSnackbarAction({
                            open: true,
                            message: `Folder Is not present in the given location!`,
                            severity: "error",
                        })
                    );
                    return;
                });
        }
    };
    const renderDocumentDisplayModal = (documentId) => {
        setLoading(true);
        http.sendRestRequest(
            `document/documentDetail?documentId=${parseInt(documentId)}`
        )
            .then((response) => {
                setLoading(false);
                setFileName(response.documentName);
                setFileLocation(response);
                setShowDocumentModal(true);
            })
            .catch((error) => {
                setLoading(false);
                setShowAlertDialog(true);
                setAlertDialogMessage(error.error);
                console.log(error);
            });
    };
    const handleClickResult = (pageNumber, rowsPerPageNumber) => {
        let url = "workspace/search";
        let body = {
            page: pageNumber || pageNumber === 0 ? pageNumber + 1 : 1,
            pageSize: rowsPerPageNumber ? rowsPerPageNumber : 10,
            keyword: debouncedSearch,
            searchInDocumentContents: true,
        };
        if (breadcrumbData.length) {
            body.workspaceId = breadcrumbData[0].id;
            if (breadcrumbData.length === 2) {
                body.spaceId = breadcrumbData[1].id;
            }
            if (breadcrumbData.length > 2) {
                body.spaceId = breadcrumbData[1].id;
                body.folderId = breadcrumbData[breadcrumbData.length - 1].id;
            }
        }
        setLoading(true);
        http.sendRestRequest(url, "POST", body)
            .then((resp) => {
                setListRowData(resp);
                // setFilteredData(resp.data);
                // setShowDropdown(true);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };
    return (
        <div className="main-container">
            {breadcrumbData.some((item) => item.name === "Emails") ? null : (
                <div style={{ width: "50%", marginLeft: "-17px" }}>
                    <CustomSearch
                        handleSearchClear={handleSearchClear}
                        setLoading={setLoading}
                        handleActivityClick={handleActivityClick}
                        setCurrentWorkspaceSearch={setCurrentWorkspace}
                        renderDocumentDisplayModal={renderDocumentDisplayModal}
                        setShowDropdown={setShowDropdown}
                        showDropdown={showDropdown}
                        breadcrumbData={breadcrumbData}
                        setSeeAllBtn={setSeeAllBtn}
                        setDebouncedSearch={setDebouncedSearch}
                        setSearch={setSearch}
                        search={search}
                        debouncedSearch={debouncedSearch}
                        setListRowData={setListRowData}
                        handleClickResult={handleClickResult}
                        setPage={setSearchResultPage}
                        setRowsPerPage={setSearchResultRowsPerPage}
                    ></CustomSearch>
                </div>
            )}
            <div className="workspace-breadcrumb">
                <Breadcrumb
                    email={breadcrumbData.some(
                        (item) => item.name === "Emails"
                    )}
                    flow={breadcrumbData.some((item) => item.name === "Flow")}
                    breadcrumbData={breadcrumbData}
                    handleBreadcrumbClick={handleBreadcrumbClick}
                ></Breadcrumb>
            </div>
            {seeAllBtn ? (
                <WorkSpaceFilterTable
                    handleActivityClick={handleActivityClick}
                    setCurrentWorkspaceSearch={setCurrentWorkspace}
                    setShowDropdown={setShowDropdown}
                    renderDocumentDisplayModal={renderDocumentDisplayModal}
                    debouncedSearch={debouncedSearch}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    listRowData={listRowData}
                    setListRowData={setListRowData}
                    rowsPerPage={searchResultRowsPerPage}
                    page={searchResultPage}
                    handleClickResult={handleClickResult}
                    tableHeight={"60vh"}
                />
            ) : (
                renderWorkSpaceContent()
            )}
            {showDocumentModal ? (
                <ModalView
                    showHeader={true}
                    footer="false"
                    show={showDocumentModal}
                    size="lg"
                    documentId={fileLocation?.documentId}
                    onHide={handleDocumentModalClose}
                    title={fileName}
                    isChecked={isChecked}
                    modalBodyClass="documentViewModal"
                    onclick={() => {}}
                    submitButtonText="Close"
                    headerTitle={fileName}
                    module="workSpace"
                    selectedFileData={selectedFileData}
                >
                    {showDocumentModal ? (
                        <DocViwer
                            docName={fileName}
                            fileLocation={fileLocation}
                        ></DocViwer>
                    ) : null}
                </ModalView>
            ) : null}

            {ComposerModalShow || isCheckComposer ? (
                <ComposerModal
                    setShowDocumentModal={setShowDocumentModal}
                    setComposerModalShow={setComposerModalShow}
                    selectedSpace={activeSpace}
                    isCheckComposer={isCheckComposer}
                    setIsCheckComposer={setIsCheckComposer}
                    parentFolderId={
                        breadcrumbData.length === 2
                            ? 0
                            : breadcrumbData[breadcrumbData.length - 1]?.id
                    }
                    noteString={noteText}
                    title={fileName}
                    parent={
                        breadcrumbData.length === 1
                            ? breadcrumbData[0]
                            : breadcrumbData.length > 2
                            ? breadcrumbData[breadcrumbData.length - 1]
                            : breadcrumbData[1]
                    }
                    show={ComposerModalShow || isCheckComposer}
                    onHide={() => {
                        setIsCheckComposer(false);
                        setComposerModalShow(false);
                    }}
                    fetchFolderData={fetchFolderData}
                ></ComposerModal>
            ) : null}
            <Loader show={loading} />
            <AlertDialog
                open={showAlertDialog}
                message={alertDialogMessage}
                title={""}
                isAlert={false}
                setOpen={() => setShowAlertDialog(!showAlertDialog)}
            ></AlertDialog>
        </div>
    );
};

export default Workspaces;
